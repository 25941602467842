<template>
  <div class="input-field">
    <ButtonCustomized :label="label" @click="triggerInputEvent" />
    <input
      type="file"
      v-bind="{ multiple, accept }"
      ref="inputRef"
      @input="handleSelectionChange"
    />
  </div>

</template>

<script lang="ts" setup>
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import { ref } from "vue";

defineProps(["label", "multiple", "accept"])
const emit = defineEmits(["change"]);

const inputRef = ref<HTMLInputElement | null>(null);

function handleSelectionChange() {
  emit("change", inputRef.value?.files);
}

function triggerInputEvent() {
  if(inputRef.value) {
    inputRef.value.value = '';
    inputRef.value.click();
  }
}
</script>

<style lang="scss" scoped>
.input-field {
  input {
    display: none;
  }
}
</style>