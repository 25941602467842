import { getSpriteIdByShapeNode } from '@/views/MediaEditorView/SlideEditor/utils/spriteGetters';

import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';

import { 
  removeSpriteFromStageSelection,
} from '@/views/MediaEditorView/SlideEditor/utils/spritesSelection';

export function removeSprite(spriteShapeIdOrNode) {
  removeSpriteFromStageSelection(spriteShapeIdOrNode)
  removeSpriteFromStore(spriteShapeIdOrNode);
}

function removeSpriteFromStore(spriteShapeIdOrNode) {
  const { sprites, spritesReversed } = useSlide();
  
  // prepare spriteId
  let spriteId: string;
  if (typeof spriteShapeIdOrNode === 'string') {
    spriteId = spriteShapeIdOrNode;
  } else {
    const spriteShapeNode = spriteShapeIdOrNode;
    spriteId = getSpriteIdByShapeNode(spriteShapeNode);
  }

  const spriteToRemoveIndex = sprites.findIndex(sprite => sprite.id === spriteId);

  sprites.splice(spriteToRemoveIndex,1);

  const spriteToRemoveReversedIndex = spritesReversed.length - spriteToRemoveIndex - 1;
  spritesReversed.splice(spriteToRemoveReversedIndex,1);
}