<template>
  <ul class="numbers">
    <template v-if="currentPageNumber >= 6 && currentPageNumber < totalPages - 4">
      <li v-for="i in 5" :key="i" @click="setCurrentPageNumber(previousNumber(i))">
        <!-- TODO (Optional): to think later about using key that matches actual page number for more optimization and Vue practices adhering
            May be we can use named more named states for more readability
        -->
        {{ previousNumber(i) }}
      </li>
      <li class="active">{{ currentPageNumber }}</li>
      <li v-for="i in 4" :key="i" @click="setCurrentPageNumber(nextNumber(i))">
        {{ nextNumber(i) }}
      </li>
    </template>
    <template v-else-if="currentPageNumber < 6">
      <li v-for="i in totalPages > 10 ? 10 : totalPages" :key="i" :class="{ active: i == currentPageNumber }"
        @click="setCurrentPageNumber(i)">
        <!-- TODO (Optional): 
            May be there's a room for using named computed state in v-for for more readability
        -->
        {{ i }}
      </li>
    </template>
    <template v-else>
      <template v-for="i in 10">
        <li
          v-if="(totalPages - 10 + i) > 0"
          :key="i"
          :class="{ active: totalPages - 10 + i == currentPageNumber }"
          @click="setCurrentPageNumber(totalPages - 10 + i)"
        >
          <!-- TODO (Optional): 
              May be there's a room for using named computed state in v-for for more readability
          -->
          {{ totalPages - 10 + i }}
        </li>
      </template>
    </template>
  </ul>
</template>
<script lang="ts" setup>
import { inject } from "vue";
const itemsListHelpers = inject('itemsListHelpers');

const { setCurrentPageNumber, totalPages, currentPageNumber } = itemsListHelpers;
// const totalPages = ref(100);
// const currentPageNumber = ref(50);
// TODO (Optional): May be we can think about using named computed states for more readability
function previousNumber(i: number) {
  return currentPageNumber.value - 6 + i;
}
function nextNumber(i: number) {
  return currentPageNumber.value + i;
}
</script>
<style lang="scss" scoped>
.numbers {
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
  gap: 15px;
  margin: 0 10px;

  li {
    cursor: pointer;
  }

  .active {
    color: #ddd;
  }
}
</style>