import { transformerNode } from '@/views/MediaEditorView/SlideEditor/Stage/baseElementsRefs';
import { getSpriteShapeNodeById, getSpriteIdByShapeNode } from './spriteGetters';

import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';

type SpriteIdOrShapeNode = string | ShapeNode;
type ShapeNode = any;
  // TODO (low): add type for ShapeNode (Konva.Shape)

export function addSpriteToSelection(spriteIdOrShapeNode: SpriteIdOrShapeNode, {clearPrevious = false} = {}) {
  addSpriteToStageSelection(spriteIdOrShapeNode, {clearPrevious});
  addSpriteToStoreSelection(spriteIdOrShapeNode, {clearPrevious});
}

export function addSpritesToSelection(spritesShapeNodes: Array<ShapeNode>, {clearPrevious = false} = {}) {
  addSpritesToStageSelection(spritesShapeNodes, {clearPrevious});
  addSpritesToStoreSelection(spritesShapeNodes, {clearPrevious});
}

function addSpriteToStageSelection(spriteShapeNodeOrId: SpriteIdOrShapeNode, {clearPrevious = false} = {}) {    // quickly added to think if this is the best place for it
  // prepare spriteShapeNode
  let spriteShapeNode;
  if (typeof spriteShapeNodeOrId === 'string') {
    const spriteId = spriteShapeNodeOrId;
    spriteShapeNode = getSpriteShapeNodeById(spriteId);
  } else {
    spriteShapeNode = spriteShapeNodeOrId;
  }
  
  // prepare nodes
  let nodes;
  if(clearPrevious) {
    nodes = [spriteShapeNode];
  } else {
    nodes = transformerNode.value.nodes().concat([spriteShapeNode]);
  }
  
  transformerNode.value.nodes(nodes);
}

function addSpritesToStageSelection(spritesShapeNodes: Array<ShapeNode>, {clearPrevious = false} = {}) {
  // TODO (low): think about adding ability to pass spriteIds also (not only shapeNodes)
    // (maybe not needed if there's no use case)
  
  // prepare nodes
  let nodes;
  if(clearPrevious) {
    nodes = spritesShapeNodes
  } else {
    nodes = transformerNode.value.nodes().concat(spritesShapeNodes)
  }

  transformerNode.value.nodes(nodes);
}

function addSpriteToStoreSelection(spriteIdOrShapeNode: SpriteIdOrShapeNode, {clearPrevious = false} = {}) {    // quickly added to think if this is the best place for it
  const { selectedSpritesIds } = useSlide();

  if(clearPrevious) {
    clearSpritesStoreSelection();
  }
  
  let spriteId;
  if (typeof spriteIdOrShapeNode === 'string') {
    spriteId = spriteIdOrShapeNode;
  } else {
    const spriteShapeNode = spriteIdOrShapeNode;
    spriteId = getSpriteIdByShapeNode(spriteShapeNode);
  }

  selectedSpritesIds.push(spriteId);
}

function addSpritesToStoreSelection(spritesShapeNodes: Array<ShapeNode>, {clearPrevious = false} = {}) {
  // TODO (low): think about adding ability to pass spriteIds also (not only shapeNodes)
    // (maybe not needed if there's no use case)
  
  if(clearPrevious) {
    clearSpritesStoreSelection();
  }

  spritesShapeNodes.forEach(spriteShapeNode => {
    addSpriteToStoreSelection(spriteShapeNode, {clearPrevious: false})
  });
}


export function removeSpriteFromSelection(spriteShapeNodeOrId: SpriteIdOrShapeNode) {
  removeSpriteFromStageSelection(spriteShapeNodeOrId);
  removeSpriteFromStoreSelection(spriteShapeNodeOrId);
}

export function removeSpriteFromStageSelection(spriteShapeNodeOrId: SpriteIdOrShapeNode) {
  // prepare spriteShapeNode
  let spriteShapeNode;
  if (typeof spriteShapeNodeOrId === 'string') {
    const spriteId = spriteShapeNodeOrId;
    spriteShapeNode = getSpriteShapeNodeById(spriteId);
  } else {
    spriteShapeNode = spriteShapeNodeOrId;
  }

  const nodes = transformerNode.value.nodes().slice(); // use slice to have new copy of array
  // remove node from array
  nodes.splice(nodes.indexOf(spriteShapeNode), 1);
  transformerNode.value.nodes(nodes);
}

function removeSpriteFromStoreSelection(spriteIdOrShapeNode: SpriteIdOrShapeNode) {
  const { selectedSpritesIds } = useSlide();
  let spriteId;
  if (typeof spriteIdOrShapeNode === 'string') {
    spriteId = spriteIdOrShapeNode;
  } else {
    const spriteShapeNode = spriteIdOrShapeNode;
    spriteId = getSpriteIdByShapeNode(spriteShapeNode);
  }

  selectedSpritesIds.splice(selectedSpritesIds.indexOf(spriteId), 1);
}


export function clearSpritesSelection() {    // quickly added to think if this is the best place for it
  clearSpritesStoreSelection();
  clearSpritesStageSelection();
}

function clearSpritesStoreSelection() {
  const { sprites } = useSlide();
  const { selectedSpritesIds } = useSlide();
  // TODO: think if using "sprites.length" instead of "selectedSpritesIds.length" is correct bec. it seems wrong — N2DE4NCL
  selectedSpritesIds.splice(0,sprites.length);
}


function clearSpritesStageSelection() {    // quickly added to think if this is the best place for it
  transformerNode.value.nodes([]);
}

export function isSpriteSelected(id: string) {    // quickly added to think if this is the best place for it
  const { selectedSpritesIds } = useSlide();
  const isSpriteSelected = selectedSpritesIds.indexOf(id) >= 0;
  return isSpriteSelected;
}

export function refreshSpritesStageSelection() {
  const { selectedSpritesIds } = useSlide();
  clearSpritesStageSelection();
  selectedSpritesIds.forEach(selectedSpriteId => addSpriteToStageSelection(selectedSpriteId));
}