<template>
  <div class="create-slide-form">
    <div class="status-bar-container">
      <StatusBar
        :errorMessage="errorMessage"
        :isLoading="/* true ||  */isLoading"
        loadingMessage="Saving..."
        :isSuccess="isSuccess"
        successMessage="Saved!"
      />
    </div>
    <div class="form-fields">
      <!-- TODO: think about adding below-field error message & error state indication (e.g. red border) [DSP-N26CxI4P] -->
      <div class="formGroup">
        <label for="name">Name &#9733;</label>
        <TextField
          v-model="values.name"
          width="100%"
        />
      </div>
      <div class="formGroup">
        <label for="Start Date">Start Date</label>
        <DateField
          v-model="values.startDate"
          width="100%"
        />
      </div>

      <div class="formGroup">
        <label for="End Date">End Date </label>
        <DateField
          v-model="values.endDate"
          width="100%"
        />
      </div>

      <div class="formGroup">
        <label for="Active">Active?</label>
        <input class="form-check-input" type="checkbox"   v-model="values.isEnabled" id="Active">
      </div>


    </div>
    <div class="form-buttons">
      <ButtonCustomized label="Cancel" @click="onCancel" :secondary="true" />
      <ButtonCustomized label="Create" @click="onSubmit" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import CheckboxField from "@/components/form-fields/CheckboxField.vue";
import DateField from "@/components/form-fields/DateField.vue";
import TextField from "@/components/form-fields/TextField.vue";
import useCreateCampaignForm from "./CreateCampaignHelpers";
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
import useNotifications from "@/components/Notifications/composables/notifications";
import router from '@/router';

const emit = defineEmits(['submit', 'cancel', 'success', 'fail'])

const notification = useNotifications();

const { values, submitForm, isLoading, isSuccess, errorMessage, newCampaignId } = useCreateCampaignForm();

async function onSubmit() {
  await submitForm();
  if(isSuccess.value === true) {
    notification.success('Campaign created successfully!');
    emit('success');
    router.push({name: 'campaign-editor', params: {id: newCampaignId.value}});
  } else {    // on failure
    emit('fail');
  }
}

function onCancel() {
  emit('cancel');
}
</script>

<style lang="scss" scoped>

.status-bar-container {
  margin-bottom: 1rem;
}

.create-slide-form {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
}
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.formGroup {
  display: flex;
  align-items: center;
  .select {
    width: 60%;
    margin: 0;
  }
  label {
    display: inline-block;
    width: 110px;
    color: #262626;
    font-weight: bold;
  }
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>
