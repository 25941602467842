// Defines filters display order and labels in UI

const filtersDisplayConfig = [
  // {
  //   "name": "FILTER_CREATED",
  //   "label": "Date Created",
  //   "type": "collapsibleSelect",
  //   "isSeparatedAbove": true
  // },
  // {
  //   "name": "FILTER_MODIFIED",
  //   "label": "Date Modified",
  //   "type": "collapsibleSelect"
  // },
  // {
  //   "name": "FILTER_METADATA_1-1-1-1-1-1-1-1-1",
  //   "label": "1-1-1-1-1-1-1-1-1",
  //   "type": "collapsibleSelect"
  // },
  // {
  //   "name": "FILTER_METADATA_flagshipstore",
  //   "label": "Flagshipstore",
  //   "type": "collapsibleSelect"
  // },
  // {
  //   "name": "FILTER_METADATA_justins desk",
  //   "label": "Justins Desk",
  //   "type": "collapsibleSelect"
  // },
  // {
  //   "name": "FILTER_METADATA_metadatatest",
  //   "label": "Metadatatest",
  //   "type": "collapsibleSelect"
  // },
  // {
  //   "name": "FILTER_METADATA_region",
  //   "label": "Region",
  //   "type": "collapsibleSelect"
  // },
  // {
  //   "name": "FILTER_METADATA_storetype",
  //   "label": "Storetype",
  //   "type": "collapsibleSelect"
  // },
  // {
  //   "name": "FILTER_METADATA_test",
  //   "label": "Test",
  //   "type": "collapsibleSelect"
  // },
  // {
  //   "name": "FILTER_METADATA_ulauiegf4389q98yveil",
  //   "label": "Ulauiegf4389q98yveil",
  //   "type": "collapsibleSelect"
  // }
]

export default filtersDisplayConfig;