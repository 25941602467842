<template>
<div class="search-icon">
</div>
</template>

<script lang="ts" setup>
import { useImportedPathsAsCssUrls } from '@/composables/utils/importedPaths';
import searchImageUrl from './images/search.png';

const importedCssUrls = useImportedPathsAsCssUrls({
  "search": searchImageUrl,
});

</script>

<style lang="scss" scoped>
.search-icon {
  background-image: v-bind("importedCssUrls.search");
  color: #262626;
  height: 16px;
  line-height: 16px;
  text-align: center;
  width: 16px;
}
</style>