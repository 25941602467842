<template>
  <div class="field">
    <label
      v-if="label"
      :for="name"
      :class='[labelClassName]'
    >
      {{ label }}
      <template v-if="required"> &#9733;</template>
    </label>
    <div :class='["select styled-select", fieldClassName]'>
      <select
        :value="modelValue || fixedValue || (setValueToOptionObject ? options[0] : options[0][valueKeyPrepared] )"
        @input="onInput"
        :disabled="disabled"
        ref="selectElRef"
        :name="name"
        :id="id || name"
      >
        <template v-for="option, index in options">
          <template v-if="(typeof option === 'object')">
            <option 
              v-if="(option.hasOwnProperty(valueKeyPrepared) || setValueToOptionObject) && !option.hasOwnProperty('items')"
              :value="(setValueToOptionObject ? index : option[valueKeyPrepared] )"
            >
              {{ option[labelKeyPrepared] }}
            </option>
            <optgroup
              v-else-if="option.hasOwnProperty('items')"
              :label="option[labelKeyPrepared]"
            >
              <option
                v-for="item in option.items"
                :value="item[valueKeyPrepared]"
              >
                {{ item[labelKeyPrepared] }}
              </option>
            </optgroup>
          </template>
          <template v-else>
            <option :value="option">
              {{ option }}
            </option>
          </template>
        </template>
      </select>
      <OldThemeIcon v-if="!disabled" name="bullet-down" class="icon"/>
    </div>
    <div v-if="suffixLabel" class="ml-2">
      {{ suffixLabel }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';

export interface Props {
  modelValue?: any;
  options: any[];
  disabled?: boolean;
  labelKey?: string;
  valueKey?: string;
  width?: string;
  label?: string;
  required?: boolean;
  name?: string;
  id?: string;
  labelClassName?: string;
  fieldClassName?: string;
  suffixLabel?: string;
  setValueToOptionObject?: boolean;
  fixedValue?: string;
}

const props = withDefaults(defineProps<Props>(), {
  width: "fit-content"
})

const emit = defineEmits(['update:modelValue', 'input']);
const selectElRef = ref();

const labelKeyPrepared = props.labelKey ? props.labelKey : 'label';
const valueKeyPrepared = props.valueKey ? props.valueKey : 'value';

function onInput(event: Event) {
  let valueToEmit;
  if ( props.setValueToOptionObject ) {
    valueToEmit = event.target.value;
  } else {
    const optionValue = event.target.value;
    valueToEmit = optionValue;
  };
  emit('update:modelValue', valueToEmit)
  emit('input', event);
  if(props?.fixedValue) {
    selectElRef.value.value = props.fixedValue;
  }
}

const { width } = props;
</script>

<style lang="scss" scoped>
$width: v-bind(width);

.field {
  display: flex;
  align-items: center;
  label {
    display: inline-block;
    color: #262626;
    font-weight: bold;
    margin-right: 0.375rem;
  }
}

.select {
  margin: 0;
}
.styled-select {
  /* TODO: to separate templates related from generic later as it was quickly copied  */
  /* TODO: to think about the className it was quickly copied  */

  border: 1px solid #abadb3;
  /* Old disabled styling */
  /* line-height: 0; */
  /* NEW STYLING */
  line-height: 1em;
  display: flex;
  justify-content: center;
  height: 34px;
  overflow: hidden;
  padding: 0;
  position: relative;
  /* margin-right: 6px; */
  /* width: 46px; */
  width: $width;

  :deep(select) {
    background: transparent;
    border: 0;
    left: 0;
    font-size: 14px;
    top: 0;
    appearance: none;
    z-index: 1;
    cursor: pointer;
    padding: 9px;
    margin: 0;
    
    width: 100%;
    padding: 0 1.2em 0 0.6em;
    outline: none;
    color: #666666;
  }
}

.icon {
  position: absolute;
  top: 8px;
  right: 2px;
  z-index: 1;
  pointer-events: none;
}
</style>