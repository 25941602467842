<template>
<div>
  <div
    class="font-bold mb-4 text-2xl"
  >
    POS items of <span class="underline italic font-bold">Store 1</span> <i>POS Source</i>
    <!-- POS items of <b>"POS SOURCE NAME HERE"</b> <i>POS Source</i> -->
  </div>
</div>
<!-- POS Source Content -->
<template v-for="item of posSourceData.items">  <!-- Loop through POS Items of a POS source -->
  <div class="mb-4">
    <!-- <Collapsible
      :title="`POS item ${item.ItemId} (${item.propertiesItems[0].propValue}) properties`"
    > -->
    <Collapsible content-class-name="!px-4 mb-2" title-class-name="p-2 bg-blue-600/90 hover:bg-blue-600 rounded-md text-white mb-2">
      <!-- POS Item Title -->
      <template #title>
        <span><span class="underline italic font-bold text-lg">{{ item.propertiesItems[0].propValue }}</span><span class="font-normal text-white/70">&nbsp; POS item properties</span></span>
      </template>
      <div
        v-for="(property, index) in item.propertiesItems"
        class="mt-3 mb-4 bg-white rounded-md px-2 pt-4"
      >
        <!-- POS Property Title & Value -->
        <span class="leading-8 break-all">
          <span class="p-2 bg-slate-400 rounded-md text-white">
            <span class="font-bold">{{ property.propName}}</span>
            <span class="text-white/90">:</span>
          </span>&nbsp;
          <span>
            <span :class='["font-bold text-blue-700", (property.isOverrideCurrent && "line-through text-grey")]'>
              {{property.propValue}}
            </span>
            <span v-if="property.isOverrideCurrent">
              <span class="text-gray-400"> → </span>
              <span :class='["font-bold text-blue-700"]'>
                {{property.overrideValue}}
              </span>
            </span>
          </span>
        </span>

        <!-- POS Property content -->
        <div
          class="bg-white rounded-md p-2"
        >
          <div class="text-gray-500">
            <div class="flex flex-col sm:flex-row h-[auto] sm:h-10">
              <CheckBoxField
                label="Override current value"
                :name="`isOverrideCurrent-${item.id}-${property.id}`"
                v-model="property.isOverrideCurrent"
                required
                class="field"
              />
              <TextField
                v-if="property.isOverrideCurrent"
                :name="`overrideValue-${item.id}-${property.id}`"
                v-model="property.overrideValue"
                required
                class="field bg-white"
              />
            </div>
          </div>
        </div>

      </div>
    </Collapsible>
  </div>
</template>
</template>

<script lang="ts" setup>
import Collapsible from '@/components/Collapsible/Collapsible.vue';
import postItems_mockData from '@/data/mock-data/pos/store1/posItems';
import { reactive } from 'vue';
import CheckBoxField from "@/components/form-fields/CheckboxField.vue";
import TextField from '@/components/form-fields/TextField.vue';
import usePOSSource from '../composables/posSource';

const {
  data: posSourceData,
} = usePOSSource();

</script>

<style lang="scss" scoped>

</style>