<template>

<div class="container">
  <div class="tabs-selection-menu">
    <div
      class="tab"
      v-for="(tab, index) in tabs"
      :key="index"
      @click="() => setActiveTab(index)"
      :class="{ active: index === activeTabIndex }"
    >
      {{tab.label}}
    </div>
  </div>
  <div v-if="tabs[activeTabIndex].headerComponent">
    <component :is="tabs[activeTabIndex].headerComponent" />
  </div>
  <div
    :class='["content", {"scrollable": tabs[activeTabIndex].scrollable}]'
  >
    <component :is="tabs[activeTabIndex].component"  />
  </div>
</div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

defineProps(['tabs', 'scrollable'])
const activeTabIndex = ref(0);
function setActiveTab(index: number) {
  activeTabIndex.value = index;
}
</script>

<style lang="scss" scoped>
.container {
  height: 21.4em;
  /* new default theme styling */
  box-shadow: #00000047 1px 1px 10px;
  border-radius: 0.375rem;
  @include theme-old {
    box-shadow: none;
    border: 1px solid #7b7b7b;
    border-radius: initial;
  }
  .content {
    &.scrollable {
      overflow-y: auto;
      height: 81%;
      @include x-large-devices-down {
        height: 81%;
      }
      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #25294A;
        cursor: pointer;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: rgb(37 41 74 / 50%);
      }
    }
  }
  
}


.tabs-selection-menu {
  display: flex;
  align-items: center;
  
  /* new default theme styling */
  @include theme-new {
    border-radius: 0.375rem 0.375rem 0 0;
    background: linear-gradient(45deg, #1b4187, #05635e);
  }
  
  
  @include theme-old {
    background-color: #7b7b7b;
  }
  
}
.tab {
  height: 2.1em;
  line-height: 2.1em;
  padding: 0 0.7em;
  cursor: pointer;
  color: white;
  @include theme-new {
    background-color: #7b7b7b4a;
  }
  @include theme-old {
    background-color: #7b7b7b;
  }

  &.active {
    cursor: default;
    @include theme-new {
      background-color: rgb(255 255 255 / 63%);
      color: #0b314e;
    }
    @include theme-old {
      background-color: white;
      color: #7b7b7b;
    }
  }
}
</style>