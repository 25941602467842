const AccessLevelsMapping = Object.freeze({
  'None': 0,
  'Read': 1,
  'Write': 2,
  'Create': 4,
  'Delete': 8,
  get ReadWrite() { return this.Read | this.Write },
  get WriteDelete() { return this.Write | this.Delete },
  get ReadWriteCreate() { return this.Read | this.Write | this.Create },
  get All() { return this.Read | this.Write | this.Create | this.Delete }
});

export default AccessLevelsMapping;