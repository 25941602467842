<template>
  <!-- <MediaListForPlaylistEditor /> -->
  <content-with-loading-skeleton
    :isLoading="isLoading"
    loadingMessage="Loading Player Data..."
    :isWithMessage="true"
  >
    <template #loaded>
      <TopBar />
      <PageSection>
        <MediaBrowserButtonWithExpandable />
      </PageSection>
      <PageSection>
        <PlaylistEditPane />
      </PageSection>
      <FloatingFooterButtons>
        <div class="flex flex-row-reverse gap-2">
          <SavePlaylistButton ref="savePlaylistButtonRef" />
          <ExitButton
            v-bind="{hasUnsavedChanges, unsetHasUnsavedChanges}"
            @exit="onExit"
            @saveAndExit="onSaveAndExit"
          />
        </div>
      </FloatingFooterButtons>
    </template>
  </content-with-loading-skeleton>
</template>


<script setup lang="ts">
// import MediaListForPlaylistEditor from './MediaListForPlaylistEditorTEMP.vue';
// import MediaLister from './MediaBrowser/MediaBrowser.vue';
import MediaBrowserButtonWithExpandable from './MediaBrowser/MediaBrowserButtonWithExpandable.vue';
import PlaylistEditPane from './PlaylistEditPane/PlaylistEditPane.vue';
import PageSection from '@/components/layouts/PageSection.vue';
import TopBar from './TopBar/TopBar.vue';
import {onBeforeMount, ref}  from 'vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import usePlaylist from './composables/playlist';
import FloatingFooterButtons from '@/components/layouts/FloatingFooterButtons.vue';
import SavePlaylistButton from './SavePlaylistButton/SavePlaylistButton.vue';
import ExitButton from '@/components/Dialog/ButtonsWithDialog/ExitButton/ExitButton.vue';
import sharedRefs from './sharedRefs';
import router from '@/router';

const { loadPlaylist, initializeHasUnsavedChangesWatcher, hasUnsavedChanges, unsetHasUnsavedChanges, isLoading } = usePlaylist();
initializeHasUnsavedChangesWatcher();

const route = useRoute()

const currentPlaylistId = route.params.id;
const { savePlaylistButtonRef } = sharedRefs;

function onExit() {
  router.push({ path: '/playlists' })
}

async function onSaveAndExit() {
  await savePlaylistButtonRef.value.savePlaylist();
  router.push({ path: '/playlists' })
}

onBeforeMount(async () => {
  await loadPlaylist(currentPlaylistId);
});

onBeforeRouteUpdate(async (newRoute) => {
  if(newRoute.name === "playlist-editor") {
    const newPlaylistId = newRoute.params.id;
    await loadPlaylist(newPlaylistId);
  }
})
</script>



<style lang="scss" scoped>
</style>
