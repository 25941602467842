<template>
<MediaLister v-bind="config" />
</template>

<script lang="ts" setup>
import MediaLister from '@/views/MediaListView/MediaLister/MediaLister.vue'
import PlaylistItemPickDrag from './PlaylistItemPickDrag.vue';

const config = {
  itemPickDragMode: true,
  itemPickDragCloneCallback: (item) => {
    const {
      id,
      previewUri,
      sourceUri,
      assetType,
      sourceMimeType,
      name,
      versions
    } = item;
    
    const playlistItemPrepared = {
      duration: 0,
      itemKey: `${id-(new Date()).getTime()}`,
        // generate an "item key" composed of "asset-id" and "time" the item was picked.
        // this helpes to prevent re-rendering of playlist items every time an item is moved.
      asset: {
        id,
        previewUri,
        sourceUri,
        assetType,
        sourceMimeType,
        name,
        versions
      }
    };

    return playlistItemPrepared;
  },
  itemDragDropPlaceholderComponent: PlaylistItemPickDrag
}
</script>

<style lang="scss" scoped>

</style>