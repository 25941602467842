import { transformerNode } from '@/views/MediaEditorView/SlideEditor/Stage/baseElementsRefs';
import { addSpriteToSelection } from '@/views/MediaEditorView/SlideEditor/utils/spritesSelection';
import { onMultiStepChangeStartHistoryHandler } from '@/views/MediaEditorView/SlideEditor/composables/helpers/history';

export const selectionShapeHandlers = {
  onDragStart(e: any) {
    onMultiStepChangeStartHistoryHandler();
    const isAlreadySelected = transformerNode.value.nodes().indexOf(e.target) >= 0;
    if (!isAlreadySelected) {
      addSpriteToSelection(e.target, {clearPrevious: true})
    }
  }
}

export default selectionShapeHandlers;