// TODO: think if it's better to combine "video", "images" and "slides" saving into one helper function — N2L7k476
  // (for now seems not best)

import type { Slide_ForAPISubmission } from "@/helpers/types/media/slide/Slide";

import useApi from "../api";
import { getKeyByValue, log } from '@/helpers/utils';
import AssetTypesMapping from '@/data/mappings/AssetTypes.map';
  // TODO: think if it's better to use map for "AssetTypesMapping" and other mappings instead of using "getKeyByValue" function — N2L849De
    // may be you just need to refactor the map to make the value be the key and the key be the value, but wait because this current way is useful in mapping in the other direction when you retrieve media from backend
import SpriteTypesMapping from '@/data/mappings/SpriteTypes.map';

export default async function submitUpdateSlide(data: Slide_ForAPISubmission) {
  const { put } = useApi();
  const slideId = data.id;
  const dataPrepared = [data];
  log({dataPrepared}, {ns: 'submitUpdateSlide', color: 'green'})
  const { responseBody } = await put('api/assets/'+slideId, dataPrepared, {isLogoutOnInvalidAuth: true});

  log(responseBody, {ns: 'submitUpdateSlide', color: 'green'});
  
  const responseData = responseBody;
  return responseData;
}