<template>
  <Dialog v-if="!listerConfig.itemPickDragMode">
    <template #expander="{openModal}">
      <!-- TODO (cleaner code): think about moving the margin styling (e.g. "tailwind margin styling below") to the parent component in order to make this component more reusbale (also you may do the same for similar components, e.g. "DeleteItemButtonWithDialog") [DSP-N26F7b8N] -->
      <SelectField
        :options="actions"
        :disabled="isBulkActionsDisabled"
        @input="bulkActionSelectHandler($event, openModal)"
        :fixed-value="actions[0].value"
      />
    </template>
    <template #dialog.title>Warning</template>
    <template #dialog.content="{closeModal, closeModalAfter}">
      <CopyItemConfirmationForm
        v-if="(currentBulkAction == 'copy' && isAllSelectedItemsAreSlides && isUserHasWriteAccessToAllSelectedItems) /* TODO: think about passing this condition as a configuration (callback function) for the list (not needed for now as 'media' list is the only one that uses this.) */"
        @cancel="closeModal"
        @success="closeModalAfter(1000)"
        v-bind="{itemsIds: selectedItemsIds}"
      />
      <!-- TODO: think about passing this "CopyItemsWarning" component as a configuration (callback function) for the list (not needed for now as 'media' list is the only one that uses this.) */ -->
      <CopyItemsWarning
        v-else-if="(currentBulkAction == 'copy' && (!isAllSelectedItemsAreSlides || !isUserHasCreateAccessToAllSelectedItems))"
        v-bind="{itemsIds: selectedItemsIds}"
        @ok="closeModal"
        :isPermissionWarning="!isUserHasCreateAccessToAllSelectedItems && isAllSelectedItemsAreSlides"
      />
      <DeleteItemConfirmationForm
        v-else-if="currentBulkAction == 'delete' && isUserHasDeleteAccessToAllSelectedItems"
        @cancel="closeModal"
        @success="closeModalAfter(1000)"
        v-bind="{itemsIds: selectedItemsIds}"
      />
      <DeleteItemsWarning
        v-else-if="(currentBulkAction == 'delete' && !isUserHasDeleteAccessToAllSelectedItems)"
        v-bind="{itemsIds: selectedItemsIds}"
        @ok="closeModal"
      />
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, inject, reactive, ref } from 'vue';
import router from '@/router';
import useListSelection from '../../ItemsList/composables/listSelection';
import SelectField from '@/components/form-fields/SelectField/SelectField.vue';
import Dialog from '@/components/Dialog/Dialog.vue';
// TODO: think if it's better to move "DeleteItemConfirmationForm" and "CopyItemConfirmationForm" to a more accessible / readable path — N2FBZcFt
import DeleteItemConfirmationForm from '../../ItemsList/ItemActionsButtons/DeleteItem/DeleteItemConfirmationForm/DeleteItemConfirmationForm.vue';
import DeleteItemsWarning from '../../ItemsList/ItemActionsButtons/DeleteItem/DeleteItemsWarning/DeleteItemsWarning.vue';
import CopyItemConfirmationForm from '../../ItemsList/ItemActionsButtons/CopyItem/CopyItemConfirmationForm/CopyItemConfirmationForm.vue';
import CopyItemsWarning from '../../ItemsList/ItemActionsButtons/CopyItem/CopyItemsWarning/CopyItemsWarning.vue';
import useMediaListItemSelectionHelpers from '../../ItemsList/composables/mediaListItemSelectionHelpers/mediaListItemSelectionHelpers';

const itemsListHelpers = inject('itemsListHelpers');
const { filteredListItems } = itemsListHelpers;

const listerConfig = inject('listerConfig', {});
const {
  edit: isEditActionEnabledForList = true,
  delete: isDeleteActionEnabledForList = true,
  copy: isCopyActionEnabledForList = false
} = listerConfig?.availableActionsConfig

const actions = reactive([
  {
    value: 'default',
    label: 'Select Bulk Action',
  },
  ...( isEditActionEnabledForList ?    // conditionally add action to actions array based on config using the shorthand spread operator
    [{
      value: 'edit',
      label: 'Edit',
    }] : []
  ),
  ...( isCopyActionEnabledForList ?    // conditionally add action to actions array based on config using the shorthand spread operator
    [{
      value: 'copy',
      label: 'Copy',
    }] : []
  ),
  ...( isDeleteActionEnabledForList ?    // conditionally add action to actions array based on config using the shorthand spread operator
    [{
      value: 'delete',
      label: 'Delete',
    }] : []
  )
]);


// TODO: think if it's good to combine this "useListSelection" call into "useMediaListItemSelectionHelpers" — N2GKGk3P
const {
  selectedItemsIds,
  clearItemsSelection,
  isUserHasCreateAccessToAllSelectedItems,
  isUserHasDeleteAccessToAllSelectedItems,
  isUserHasWriteAccessToAllSelectedItems,
} = useListSelection({
  clearSelectionTriggerElement: document.body
});

const {
  isAllSelectedItemsAreSlides
} = useMediaListItemSelectionHelpers( itemsListHelpers );

const currentBulkAction = ref();

const isBulkActionsDisabled = computed(() => {
  return (selectedItemsIds.length === 0);
});

function bulkActionSelectHandler(e: any, openModalCallback: Function) {
  const value = e.target.value;
  currentBulkAction.value = value;
  switch (value) {
    case "edit":      
      router.push({name: 'media-editor', params: {id: selectedItemsIds.join(',')}});
      clearItemsSelection();
      break;
    case "copy":
      openModalCallback();
      break;
    case "delete":
      openModalCallback();
      break;
  } 
}
</script>

<style lang="scss" scoped>

</style>