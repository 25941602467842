<template>
    <div class="edit-campaign">
      <h1 class="title">
        ORGANIZATION EDITOR
        <img src="../../../components/icons/OldThemeIconRed/images/refresh-button.png" @click="loadOrganization()">
      </h1>
      <StatusBar :errorMessage="errorMessage" />
      <div class="form-fields separetor py-4 ">
  
        <!-- TODO: think about adding below-field error message & error state indication (e.g. red border) [DSP-N26CxI4P] -->
        <div class="formGroup col-md-4 col-12">
          <label for="name" class="mr-2">Name &#9733;</label>
          <TextField v-model="values.name" width="100%" />
        </div>
       
        <div class="formGroup col-md-4 col-12">
          <label for="name" class="mr-2"> Business Unit #</label>
          <TextField v-model="values.businessUnitNumber" width="100%" />
        </div>

      </div>
      <div class=" mb-5 separetor pt-3">
        <div class="top-header mt-5 ">
          <h5 class="sub-title mr-2 " :class="{ 'd-inline': organizationModel.locationSearch.results.length > 0 }"> Locations
          </h5>
          <div class="d-flex mt-1" v-if="organizationModel.locationSearch.results.length > 0">
            <CreateLocation :title="values.name" :timeZone = "organizationModel.timezoneOptions"
             @save="addNewLocation($event)" :btnName="'CREATE NEW'"> </CreateLocation>
  
          </div>
        </div>
        <div class="mt-3 add-info" v-if="organizationModel.locationSearch.results.length == 0"> 
            Locations are the physical locations used for media players  -
          <a class="add-link">
            <CreateLocation  :title="values.name" :btnName="'create a new Location'" @save="addNewLocation($event)" :timeZone = "organizationModel.timezoneOptions"> </CreateLocation>
          </a>
        </div>
        <div class="overflow-x-auto" v-if="organizationModel.locationSearch.results.length > 0">
          <table>
            <thead>
              <tr class="table-separetor">
                <th>Name</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Country</th>
                    <th>Region</th>
                <th>
  
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="location of organizationModel.locationSearch.results">
                <td >
                    
                    <a class="link-title cursor-pointer" @click="editLocation(location.id)" >   {{location.name}} </a>
                   
                        
                    </td>
                    <td >
                       {{location.city}}
                    </td>
                    <td >
                        {{location.state}}
                    </td>
                    <td >
                        {{location.country}}
                    </td>
                    <td >
                       {{location.region}}
                    </td>
                    <td>
                <div class="d-flex">
                   <OldThemeIcon @click="editLocation(location.id)" name="edit" class="icon cursor-pointer" /> 
                  <DeleteDialog :showError="deleteError" :errorMsg="deleteErrorMsg" @close="closeDelete()"  @save="deleteLocation(location.id)"> </DeleteDialog>
                </div>
              </td> 
              </tr>
            </tbody>
          </table>
        </div>
  
  
      </div>
   <div class="mt-5">
        <div class="top-header mt-5">
          <h5 class="sub-title mr-2" :class="{ 'd-inline': organizationModel.subGroupSearch.results.length > 0 }"> Child Organizations</h5>
          <div class="d-flex mt-1" v-if="organizationModel.subGroupSearch.results.length > 0">
            <CreateChildOrganization  :btnName="'CREATE NEW'" :title="values.name" @save="addNewChild($event)"> </CreateChildOrganization>
          </div>
        </div>
  
        <div class="mt-3 add-info" v-if="organizationModel.subGroupSearch.results.length == 0"> 
            Organizations may contain child organizations they manage  -
          <a class="add-link">
            <CreateChildOrganization  :title="values.name" :btnName="'create a Child Organization.'" @save="addNewChild($event)"> </CreateChildOrganization>
  
          </a>
        </div>
  
  
      </div>
      <div v-if="organizationModel.subGroupSearch.results.length > 0">
        <table>
          <thead>
            <tr class="table-separetor">
            <th>Name</th>
             <th>Locations</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="organization of organizationModel.subGroupSearch.results">
              <td> <a class="link-title cursor-pointer" @click="editOrganization(organization.id)" >  {{ organization.name }}  </a> </td>
              <td> <div class="items-count">
                {{ organization.locations.length }}
              </div> </td>
              <td>
                <div class="d-flex">
                   <OldThemeIcon @click="editOrganization(organization.id)" name="edit" class="icon cursor-pointer" /> 
                  <DeleteDialog  @save="deleteChild(organization.id)" ref="deleteDialog"> </DeleteDialog>
                </div>
              </td> 
            </tr>
          </tbody>
        </table>
      </div>  
  
    </div>
  
  
    <div class="edit-footer editor-btn content">
  
      <div class="form-buttons">
        <ButtonCustomized label="Exit" v-if="!hasChanges" @click="onExit" secondary />
        <BeforeExitDialogue v-if="hasChanges" @save="submitAndExit" @exit="onExit"> </BeforeExitDialogue>
        <ButtonCustomized label="Save" @click="onSubmit" :disabled="!hasChanges" />
        <Saving ref="savingDialog"></Saving>
  
      </div>
    </div>
  </template>
  <script lang="ts" setup>
  import { onBeforeMount, onMounted, onUnmounted, ref, watchEffect } from 'vue'
  import TextField from "@/components/form-fields/TextField.vue";
  import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
  import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
  import useNotifications from "@/components/Notifications/composables/notifications";
  import router from '@/router';
  import { onBeforeRouteLeave, useRoute } from 'vue-router'
  import useOrganizationEditor from './OrganizationEditorHelper'
  import DeleteDialog from '@/components/Dialog/DeleteDialog/DeleteDialog.vue'
 import OldThemeIcon from '@/components/icons/OldThemeIcon.vue'
 import CreateLocation from './CreateLocation/CreateLocation.vue'
import { addChildOrganization, addLocation, submitDeleteOrganizationItem } from '@/helpers/api/organization/organization';
import  CreateChildOrganization from './CreateChildOrganization/CreateChildOrganization.vue';
import { submitDeleteLocationItem } from '@/helpers/api/location/location';
import BeforeExitDialogue from '@/components/Dialog/BeforeExitDialog/BeforeExitDialogue.vue'
import Saving from '@/components/Dialog/SavingDialog/Saving.vue'
  const savingDialog = ref()
  const route = useRoute()
  const notification = useNotifications();
   const deleteError = ref(false);
   const deleteErrorMsg = ref ('')
  const {  values, submitForm, isSuccess, loadOrganization,
    hasChanges, errorMessage, removeBeforeLoad, isLoading, organizationId,organizationModel
  } = useOrganizationEditor();
  onBeforeMount(() => {
    organizationId.value = route.params.id as string
    loadOrganization()
  })
  onUnmounted(() => {
    removeBeforeLoad()
  })
  onBeforeRouteLeave((to, from) => {
    if (hasChanges.value) {
      const answer = window.confirm(
        'Changes you made may not be saved.'
      )
      if (!answer) return false
    }
  })
  
  async function submitAndExit() {
    savingDialog.value.open()
    await submitForm();
    savingDialog.value.close()
    if (isSuccess.value === true) {
      notification.success('Organization updated successfully!');
      removeBeforeLoad();
      router.push({ path: '/organizations' })
    }
  }
  async function onSubmit() {
   savingDialog.value.open()
    await submitForm();
    savingDialog.value.close()
    if (isSuccess.value === true) {
      removeBeforeLoad();
      notification.success('Organization updated successfully!');
    }
  }
  function onExit() {
    removeBeforeLoad()
    router.push({ path: '/organizations' })
  }
async function addNewLocation (location:any) {
 await addLocation(organizationId.value , location);
 loadOrganization();
}
async function addNewChild (child :any){
    await addChildOrganization(organizationId.value , child); 
    loadOrganization();
}
async function deleteChild(itemId:string ){
    await submitDeleteOrganizationItem(itemId); 
    loadOrganization();
}
async function deleteLocation (itemId:string){
    await submitDeleteLocationItem(itemId).then(res => 
    {
        if (res.isSuccessful == false){
             deleteError.value = true ; 
             deleteErrorMsg.value = res.messages[0]
        }else {
            loadOrganization();

        }
    })
}
function closeDelete (){
    deleteError.value = false ; 
             deleteErrorMsg.value = ""
}
function editOrganization (itemId:string){
    organizationId.value =itemId
    loadOrganization()
   // router.push({ name: 'organization-editor', params: { id: itemId } });
    //location.reload()
}
function editLocation (itemId:string){
    router.push({ name: 'location-editor', params: { id: itemId } });

}
  </script>
  <style lang="scss" scoped>
  .link-title {
    font-weight: bold;
  }
.link-title:hover {
   
    text-decoration: underline;
    color: #93A5AE;

}
.editor-btn-exit {
    position: fixed;
    bottom: 0px;
    right: 70px;
  }
  
  .editor-btn {
    position: fixed;
    bottom: 34px;
    width: fit-content;
    right: 0px;
    max-width: 1200px;
    text-align: right;
    z-index: 5;
    padding-bottom: 10px;
    padding-top: 7px;
  }
  
  .status-bar-container {
    margin-bottom: 1rem;
  }
  
  .create-slide-form {
    display: flex;
    flex-direction: column;
    max-width: 20rem;
  }
  
  .separetor {
    border-top: 1px solid #e6e6e6;
  }
  
  .form-fields {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
  
    @include medium-devices-down {
      flex-direction: column;
    }
  }
  
  .formGroup {
    display: flex;
    align-items: center;
  
    .select {
      width: 60%;
      margin: 0;
    }
  
    label {
      display: inline-block;
    //  width: 70px;
      color: #262626;
      font-weight: bold;
    }
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  
  h1.title {
    font-size: 2em;
    font-weight: bold;
    text-transform: uppercase;
  
    @include theme-old {
      color: #252949;
    }
  
    @include theme-new {
      color: #252949;
    }
  
    letter-spacing: 2px;
  }
  
  .sub-title {
    font-size: 1.5em;
    font-weight: bold;
  
    @include theme-old {
      color: #252949;
    }
  
    @include theme-new {
      color: #252949;
    }
  
    letter-spacing: 2px;
  }
  
  .add-link {
    font-weight: bold;
    color: #252949;
    display: inline-block;
  }
  .add-link:hover{
    text-decoration: underline;
  }
  .add-info {
    font-family: Arial;
    color: #666666;
    font-size: 16px;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  
  th,
  td {
    text-align: left;
    padding: 8px;
  }
  
  .top-header {
    display: flex;
    align-items: center;
  //  justify-content: space-between;
  }
  
  .table-separetor {
    border-bottom: 1px solid #e6e6e6;
  
  }
  
  .tb-link {
    cursor: pointer
  }
  
  .tb-link :hover {
    text-decoration: underline;
    color: #93A5AE;
  }
  
  .edit-campaign {
    margin-bottom: 80px;
  }
  
  .playlist-asset {
    display: none;
    position: absolute;
    right: 0px;
    max-width: 400px;
    overflow-x: auto;
    z-index: 4;
  
  }
  
  .view-Icon {
    position: relative;
  }
  .items-count{
    border-radius: 0.375rem;
    border-color: rgba(37, 41, 74, 0.2);
    border-style: solid;
    border-width: 1px;
    background-color: #DFE9F2;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    height: 25px;
    line-height: 25px;
    text-align: center;
    min-width: 25px;
    padding-left: 2px;
    padding-right: 2px;
    vertical-align: middle;
  }
  .view-Icon:hover .playlist-asset {
    display: flex
  }</style>
  