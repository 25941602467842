import { computed, reactive, ref } from "vue";

interface SelectedItemTypeIndicator {
  type: 'slide'|'video'|'image';
  id: string;
}

const selectedItemsTypeIndicators = reactive([] as SelectedItemTypeIndicator[]);


// TODO: think if there is a more memory size friendly solution to handle "is all Selected Items Are Slided checking" purpose — N2GJEsCb
export default function useIsAllSelectedItemsAreSlidesHelpers( itemsListHelpers ) {
  const {
    filteredListItems,
  } = itemsListHelpers;

  function afterAddToSelectionCallback({type, id}) {
    selectedItemsTypeIndicators.push({type, id})
  }
  function afterRemoveFromSelectionCallback({type, id: idOfItemToRemove}) {
    const itemToRemoveIndex = selectedItemsTypeIndicators.findIndex(({id}) => id === idOfItemToRemove);
    selectedItemsTypeIndicators.splice(itemToRemoveIndex, 1);
  }

  function afterClearSelectionCallback() {
    clearSelectedItemsTypeIndicators()
  }

  // TODO: think if current "isAllSelectedItemsAreSlides" implementation has any performance slowdown issues and if there's a more performant alternative if so — N2GIf58o
  const isAllSelectedItemsAreSlides = computed(() => {
    return selectedItemsTypeIndicators.every(({type}) => (type === 'slide'));
  })

  function afterAddAllToSelectionCallback() {
    clearSelectedItemsTypeIndicators();
    filteredListItems.value.forEach((item) => {
      selectedItemsTypeIndicators.push({type: item.assetType, id: item.id})
    })
  }

  function clearSelectedItemsTypeIndicators() {
    selectedItemsTypeIndicators.splice(0,selectedItemsTypeIndicators.length);
  }

  return {
    afterAddToSelectionCallback,
    afterRemoveFromSelectionCallback,
    afterClearSelectionCallback,
    afterAddAllToSelectionCallback,
    isAllSelectedItemsAreSlides
  }
}
