import useApi from '../api';
import { joinPaths } from '@/helpers/utils';

interface SubmitDeleteListItemOptions {
  deleteApiPath: string
}

export async function submitDeleteListItem(
  itemId: string,
  {
    deleteApiPath
  }: SubmitDeleteListItemOptions
) {
  const { deleteRequest } = useApi();
  const deleteApiPathPrepared = joinPaths(deleteApiPath,itemId);
  const { responseBody } = await deleteRequest(deleteApiPathPrepared, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}