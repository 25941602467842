<template>
<div class="tools-container">
  <ToolButton
    label="Add Text"
    @click="toggleActivateDrawNewTextSprite"
    :iconImgUrl="imgsUrls['add-text']"
    :active="activeDrawType==='text'"
  />

  <AddImageDialog>
    <template #expander>
      <ToolButton
        label="Add Image"
        :iconImgUrl="imgsUrls['add-image']"
      />
    </template>
  </AddImageDialog>

  <ToolButton
    label="Draw Image"
    @click="toggleActivateDrawNewImageSprite"
    :iconImgUrl="imgsUrls['draw-image']"
    :active="activeDrawType==='image'"
  />

  <AddSlideDialog>
    <template #expander>
      <ToolButton
        label="Add Slide"
        :iconImgUrl="imgsUrls['add-slide']"
      />
    </template>
  </AddSlideDialog>
</div>
</template>

<script lang="ts" setup>
import ToolButton from './ToolButton.vue';
import AddImageDialog from './AddImageDialog/AddImageDialog.vue';
import AddSlideDialog from './AddSlideDialog/AddSlideDialog.vue';


import { computed, reactive, ref } from 'vue';
import { useImportedPathsAsCssUrls } from '@/composables/utils/importedPaths';

import addTextImgUrl from './images/add-text.png';
import addImageImgUrl from './images/add-image.png';
import drawImgUrl from './images/draw-image.png';
import addSlideUrl from './images/add-slide.png';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';

import { log } from '@/helpers/utils';
const { toggleActivateDrawNewTextSprite, toggleActivateDrawNewImageSprite, activeDrawType } = useSlide();

const imgsUrls = useImportedPathsAsCssUrls({
  "add-text": addTextImgUrl,
  "add-image": addImageImgUrl,
  "draw-image": drawImgUrl,
  "add-slide": addSlideUrl
});

</script>

<style lang="scss" scoped>
.tools-container {
  display: flex;
  justify-content: space-between;
  flex-grow: 0.4;
}

</style>