<!-- TODO: think if we need to have a more-generic component for similar "Create Item Form components" or "Form components in general" (e.g. "CreatePlaylistForm", "CreateSlideForm", "CreateCampaignForm") — N2M1DR80 -->

<template>
  <div class="form">
    <div class="status-bar-container">
      <StatusBar
        :errorMessage="errorMessage"
        :isLoading="/* true ||  */isLoading"
        loadingMessage="Saving..."
        :isSuccess="isSuccess"
        successMessage="Saved!"
      />
    </div>
    <div class="form-fields">
      <!-- TODO: think about adding below-field error message & error state indication (e.g. red border) [DSP-N26CxI4P] -->
      <TextField
        label="Name"
        name="name"
        v-model="values.name"
        required
        class="field"
      />
      <TextField
        label="POS Source Url"
        name="name"
        v-model="values.name"
        required
        class="field"
      />
      <CheckBoxField
        label="Active"
        name="active"
        v-model="values.name"
        required
        class="field"
      />
      <!-- <NumberField
        label="Default Duration"
        name="defaultDuration"
        v-model="values.defaultItemDuration"
        required
        class="field"
      /> -->
    </div>
    <div class="form-buttons">
      <ButtonCustomized label="Cancel" @click="onCancel" :secondary="true" />
      <ButtonCustomized label="Create" @click="onSubmit" />
    </div>
  </div>
</template>

<script lang="ts" setup>
// import NumberField from "@/components/form-fields/NumberField.vue";
import TextField from "@/components/form-fields/TextField.vue";
import CheckBoxField from "@/components/form-fields/CheckboxField.vue";
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";

import createPOSSourceFormHelpers from "./composables/createPOSSourceFormHelpers";
import useNotifications from "@/components/Notifications/composables/notifications";

import router from '@/router';


const emit = defineEmits(['submit', 'cancel', 'success', 'fail'])

const notification = useNotifications();

const { values, submitForm, isLoading, isSuccess, errorMessage, newPOSSourceId } = createPOSSourceFormHelpers();

async function onSubmit() {
  await submitForm();
  if(isSuccess.value === true) {
    notification.success('POS Source created successfully!');
    emit('success');
    router.push({name: 'pos-source-editor', params: {id: newPOSSourceId.value}});
  } else {    // on failure
    emit('fail');
  }
}

function onCancel() {
  emit('cancel');
}
</script>

<style lang="scss" scoped>

.status-bar-container {
  margin-bottom: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
}
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  :deep(label) {
    width: 8rem !important;
  }
}

.field {
  :deep(label) {
    width: 110px;
  }
  :deep(.select) {
    width: 60%;
  }
}
.formGroup {
  display: flex;
  align-items: center;
  .select {
    width: 60%;  // 
    margin: 0;
  }
  label {
    display: inline-block;
    width: 110px; //
    color: #262626;
    font-weight: bold;
  }
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>
