import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import { ref } from 'vue';
// TODO: think if it's better to move "slide compbosable" to a more general place — N2LBJXAa
  // for now it seems better to keep it as is.

export default function useSaveCurrentSlide() {
  const slide = useSlide();

  // TODO: think about cleaning potential redundancy in this and similar code in other places (e.g. createSlideFormHelpers) — N2LBfoBa
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  
  async function submitSaveCurrentSlide() {
    isLoading.value = true;
    await slide.save();
    // TODO: to return to finalize code for success checking and error message showing — N2LBiK6o
    isSuccess.value = true;
    isLoading.value = false;
  }
  return {
    submitSaveCurrentSlide,
    isSuccess,
    errorMessage,
    isLoading
  }
}