<template>
<div class="file-upload-status-item">
  <div class="progress-bar" v-if='file.status === "uploading"'>
    <div class="progress-bar-inner" :style="{ width: file.progressPercentage + '%' }"></div>
  </div>
  <div class="flex flex-row justify-between gap-2 items-center">
    <div class="file-upload-info">
      <div class="file-name">{{ file.name }}</div>
      <div class="progress-text">
        <span v-if='file.status === "uploading"'>{{ file.progressPercentage }}% of </span> {{ file.sizeHumanReadable }}</div>
    </div>
    <div v-if='file.status === "uploading"' class="cancel-upload-button" @click="handleCancel">Cancel</div>
    <div v-else-if='file.status === "processing"' class="processing-status-text">Processing ...</div>
    <div v-else class="final-status-icon">
      <SuccessIcon v-if='file.status === "success"' class="icon success" />
      <WarningIcon v-else-if='file.status === "failed"' class="icon failed" />
    </div>
  </div>
  
</div>

</template>

<script lang="ts" setup>
import WarningIcon from '@/components/icons/WarningIcon.vue';
import SuccessIcon from '@/components/icons/SuccessIcon.vue';

const props = defineProps(['file']);
const emit = defineEmits(['cancel'])

function handleCancel() {
  props.file.cancel();
  emit('cancel');
}

</script>

<style lang="scss" scoped>

.progress-bar {
  height: 0.5rem;
  background-color: #ffffff;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  width: 100%;

  .progress-bar-inner {
    height: 100%;
    background-color: #25294A;
    border-radius: 0.375rem;
    transition: 0.5s all ease-out;
  }
}

.processing-status-text {
  display: flex;
  align-items: center;
}

.file-upload-status-item {
  padding: 12px 12px;
}
.file-upload-status-item {
  background-color: #e6e6e6;
  border-radius: 0.375rem;
}

.file-upload-info {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.file-name {
  width: 200px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  word-break: break-word;
}

.cancel-upload-button {
  background-color: white;
  border-radius: 0.375rem;
  padding: 0.375rem;
  box-shadow: 0px 3px 9px rgb(0 0 0 / 16%);
  color: #383434;
  cursor: pointer;
  transition: 0.5s all ease-out;
  margin-right: 1rem;
  opacity: 0.7;
  height: fit-content;
  &:hover {
    background-color: #f5f5f5;
    opacity: 1;
  }
}

.final-status-icon {
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
  .success {
    color: #5e942dc2
  }
  .failed {
    color: #ce5a5a;
  }
}
</style>