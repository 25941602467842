const baseApiPaths = {
  'initialize': 'media',
  'search': 'assetsearch',
}

const actionsApiPaths = {
  'export': 'media/export',
  'copy': 'api/assets',   // TODO: think about passing something like "api/assets/:id/copy" (may not be needed for this project) — N31IAdB9
  'delete': 'api/assets',
  'edit': 'media/edit'
}

const apiPathsConfig = {
  ...baseApiPaths,
  ...actionsApiPaths
}

export default apiPathsConfig;