<template>
<v-slider
  v-model="zoomPercentage"
  class="slider"
  :max="zoomPercentageMax"
  :min="zoomPercentageMin"
  :thumb-size="1"
  :step="zoomPercentageStep"
  hide-details
  track-color="rgb(123, 123, 123)"
  color="rgb(85 85 85)"
>
  <template v-slot:append>
    <NumberField
      v-model="zoomPercentage"
      name="spriteX"
      maxlength="4"
    />
  </template>
</v-slider>
<div :class='["flex flex-row", "zoom-to-options"]'>
  <div>
    Zoom to:&nbsp;
  </div>
  <div
    class="option"
    @click="zoomToFit"
  >
    Fit
  </div>&nbsp;
  <div
    class="option"
    @click="zoomPercentage = 100"
  >
    100%
  </div>
  <!-- <SelectExpandedField :options='[
    {
      label: "Fit",
      value: "fit"
    },
    {
      label: "100%",
      value: "100%"
    }
  ]' />
  <SelectExpandedField /> -->
</div>
</template>

<script lang="ts" setup>
import { ref, watch, watchEffect } from 'vue';
import { stageScale, stagePosition, stageWidth, stageHeight } from '@/views/MediaEditorView/SlideEditor/Stage/baseConfigsRefs';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import SelectExpandedField from '@/components/form-fields/SelectExpandedField/SelectExpandedField.vue';
import NumberField from '@/components/form-fields/NumberField.vue';
import { repositionStage } from '../../../Stage/stageHelpers/panStageHelpers_tempTest';
const zoomPercentageMin = ref(25);
const zoomPercentageMax = ref(200);
// TODO: to finalize zoom preferences for more easier zoom switching — N2LEvmE0
const zoomPercentage = ref(32);
const zoomPercentageStep = ref(0.01);
// TODO: think about "zoomPercentageStep" value animated stepping for smoother UX / feeling while zooming in / out — N2JHUA5C

const isLastStageScaleChangeFromSlider = ref(false);
watchEffect(() => {
  stageScale.value = zoomPercentage.value/100;
})

watch(stageScale, (newVal) => {
  zoomPercentage.value = (newVal*100).toFixed(1);
// TODO: think about a more clean / readable logic for binding zoomPercentage & stageScale values
  repositionStage();
})


const { basicInfo } = useSlide();
// Zoom to fit
function zoomToFit() {
  const PADDING = 50;
  const slideWidthWithPadding = basicInfo.width + (PADDING*2);
  const slideHeightWithPadding = basicInfo.height + (PADDING*2);
  const ratioToChangeZoom = Math.min(stageWidth.value/slideWidthWithPadding, stageHeight.value/slideHeightWithPadding);
  stagePosition.value = {
    x: 0 - PADDING,
    y: 0 - PADDING
  }
  if(ratioToChangeZoom > 1) {
    zoomPercentage.value = 100;
    return;
  }
  repositionStage();
  zoomPercentage.value = ratioToChangeZoom*100;
}
</script>

<style lang="scss" scoped>
.slider {
  width: 100%;
  max-height: 1.6rem;

  &.v-slider.v-input--horizontal {
    margin-inline-start: 0;
  }
}

.zoom-to-options {
  font-weight: bold;
  cursor: default;
  .option {
    cursor: pointer;
  }
}
</style>