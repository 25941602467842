<template>
  <div class="container">
    <v-tooltip text="Jump to first page">
      <template v-slot:activator="{ props }">
        <OldThemeIcon name="bullet-start nav-icon" :class="{ disabled: currentPageNumber === 1 }"
          :disabled="currentPageNumber === 1" v-bind="props" @click="setCurrentPageNumber(1)" />
      </template>
    </v-tooltip>
    <v-tooltip text="Jump to previous page">
      <template v-slot:activator="{ props }">
        <OldThemeIcon name="bullet-left nav-icon" :class="{ disabled: currentPageNumber === 1 }"
          :disabled="currentPageNumber === 1" v-bind="props" @click="setCurrentPageNumber(currentPageNumber - 1)" />
      </template>
    </v-tooltip>
    <NumbersList class="on-desktop numbers-list" />
    <div class="on-mobile text-page-indicator">
      {{ currentPageNumber }} / &nbsp;
      <content-with-loading-inline-dots :isLoading="!Boolean(lastPageNumber)">
        {{ lastPageNumber }}
      </content-with-loading-inline-dots>
    </div>

    <v-tooltip text="Jump to next page">
      <template v-slot:activator="{ props }">
        <OldThemeIcon name="bullet-right nav-icon" :class="{ disabled: currentPageNumber === lastPageNumber }"
          :disabled="currentPageNumber === lastPageNumber" v-bind="props"
          @click="setCurrentPageNumber(currentPageNumber + 1)" />
      </template>
    </v-tooltip>
    <v-tooltip text="Jump to last page">
      <template v-slot:activator="{ props }">
        <OldThemeIcon name="bullet-end nav-icon" :class="{ disabled: currentPageNumber === lastPageNumber }"
          :disabled="currentPageNumber === lastPageNumber" v-bind="props"
          @click="setCurrentPageNumber(lastPageNumber)" />
      </template>
    </v-tooltip>
  </div>
</template>
<script lang="ts" setup>
import { ref, inject } from "vue";
import OldThemeIcon from "@/components/icons/OldThemeIcon.vue";
import NumbersList from "./NumbersList.vue";

const itemsListHelpers = inject('itemsListHelpers');
const {
  currentPageNumber,
  lastPageNumber,
  setCurrentPageNumber,
} = itemsListHelpers;

const pagesListVisibleLimit = ref(10);
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  margin: 0 0.375rem;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.nav-icon {
  cursor: pointer;
}


.on-desktop {
  @include medium-devices-down {
    display: none;
  }
}

.on-mobile {
  @include medium-devices-up {
    display: none;
  }
}

.text-page-indicator {
  /* min-width: 4em; */
}

.numbers-list {
  margin: 0 0.375rem;
}
</style>






