import useApi from '../api';
import { getKeyByValue } from '@/helpers/utils';
import AssetTypesMapping from '@/data/mappings/AssetTypes.map';

interface PlaylistCreationOptions {
  name: string,
  defaultItemDuration: number,
  sharingLevel: number,
  items: []
}

export async function submitCreatePlaylistItem(playlistCreationOptions: PlaylistCreationOptions) {
  const { post } = useApi();
  const playlistCreationOptionsPrepared = [playlistCreationOptions];
  
  const { responseBody } = await post('api/playlists', playlistCreationOptionsPrepared, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}