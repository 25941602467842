<template>
    <div class="edit-campaign">
      <h1 class="title separetor">
        LOCATION EDITOR
        <img src="../../../components/icons/OldThemeIconRed/images/refresh-button.png" @click="loadLocation()">
      </h1>
     <div class="form-fields mt-3">
        <div class="md:basis-1/2">
            <h2 class="mb-2">Location Details</h2>
            <div>
                <StatusBar :errorMessage="errorMessage" />

                <TextField
                  label="Name"
                  required
                  v-model="values.name"
                  name="name"
                  label-class-name="basis-1/2"
                  field-class-name="basis-1/2"
                  class="mb-2"
                />

                <TextField
                  label="Business Unit #"
                  v-model="form.businessUnitNumber"
                  name="businessUnitNumber"
                  label-class-name="basis-1/2"
                  field-class-name="basis-1/2"
                  class="mb-2"
                />

                <TextField
                  label="Address 1"
                  v-model="form.address1"
                  name="address1"
                  label-class-name="basis-1/2"
                  field-class-name="basis-1/2"
                  class="mb-2"
                />
                
                <TextField
                  label="Address 2"
                  v-model="form.address2"
                  name="address2"
                  label-class-name="basis-1/2"
                  field-class-name="basis-1/2"
                  class="mb-2"
                />

                <TextField
                  label="City"
                  v-model="values.city"
                  required
                  name="city"
                  label-class-name="basis-1/2"
                  field-class-name="basis-1/2"
                  class="mb-2"
                />

                <TextField
                  label="Postal Code"
                  v-model="form.zip"
                  name="zip"
                  label-class-name="basis-1/2"
                  field-class-name="basis-1/2"
                  class="mb-2"
                />


                <div class="d-flex mb-2 items-center">
                    <label for="name" class="basis-1/2 text-sm font-bold mr-1">Region  &#9733;</label>
                    <div class="styled-select basis-1/2">
                        <select name="region" id="region" v-model="values.region">
                        <option :value="region" v-for="region of  regions">
                            {{ region.name }}
                        </option>
                    </select>
                    <OldThemeIcon name="bullet-down" class="icon"/>

                    </div>
                   

                </div>
                <div class="d-flex mb-2 items-center">
                    <label for="name" class="basis-1/2 text-sm font-bold mr-1">Country  &#9733;</label>
                    <div class="styled-select basis-1/2"> 
                        <select name="country" id="country"  v-model="values.country"
                        :disabled="values?.region?.countries?.length > 0 ? false : true">
                        <option :value="country" v-for="country of  values?.region?.countries ">
                            {{ country.name }}
                        </option>
                    </select>
                    <OldThemeIcon name="bullet-down" class="icon"/>

                    </div>
                   

                </div>
                <div class="d-flex mb-2 items-center">
                    <label for="name" class="basis-1/2 text-sm font-bold mr-1">State</label>
                    <div class="styled-select basis-1/2">  

                        <select name="state" id="state" v-model="form.state"
                        :disabled="values?.country?.states?.length > 0 ? false : true">
                        <option :value="state" v-for="state of  values?.country?.states">
                            {{ state.name }}
                        </option>
                    </select>
                    <OldThemeIcon name="bullet-down" class="icon"/>

                    </div>
                   

                </div>
                <!--
                  TODO: think about how we can make the below work instead of using the above
                    There's a problem making the below work because the options are not being updated correctly
                    when the region or country is changed.  This is because an option's value is an option object.
                -->
                <!-- <SelectField
                  label="Country"
                  :options="values?.region?.countries"
                  :disabled="values?.region?.countries?.length > 0 ? false : true"
                  v-model="values.country"
                  label-key="name"
                  set-value-to-option-object
                  name="country"
                  label-class-name="basis-1/2"
                  field-class-name="basis-1/2"
                  class="mb-2"
                />

                <SelectField
                  label="State"
                  :options="values?.country?.states"
                  :disabled="values?.country?.states?.length > 0 ? false : true"
                  v-model="form.state"
                  label-key="name"
                  set-value-to-option-object
                  name="state"
                  label-class-name="basis-1/2"
                  field-class-name="basis-1/2"
                  class="mb-2"
                /> -->
                <div class="d-flex mb-2 items-center">
                    <label for="name" class="basis-1/2 text-sm font-bold mr-1">Time Zone &#9733;</label>
                    <div class="styled-select basis-1/2">

                        <select name="timezone" id="timezone" v-model="values.timezone">
                            <option :value="zone.Id" v-for="zone of  locationModel.timezoneOptions ">
                                {{ zone.DisplayName }}
                            </option>
                        </select>
                        <OldThemeIcon name="bullet-down" class="icon" />

                    </div>


                </div>
                <TextField
                  label="Contact Name"
                  v-model="form.contactName"
                  name="name"
                  label-class-name="basis-1/2"
                  field-class-name="basis-1/2"
                  class="mb-2"
                />

                <TextField
                  label="Phone"
                  v-model="form.phoneNumber"
                  name="phone"
                  label-class-name="basis-1/2"
                  field-class-name="basis-1/2"
                  class="mb-2"
                />

                <TextField
                  label="Hours"
                  v-model="form.hours"
                  name="hours"
                  label-class-name="basis-1/2"
                  field-class-name="basis-1/2"
                  class="mb-2"
                />
            </div>

        </div>
        <div class="md:basis-1/2 ">
            <h2 class="mb-2">Organization Information</h2>
            <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm font-bold"> Organization Name </label>
                    <label for="name" class="basis-1/2 text-sm"> {{ organizationDetails.name }} </label>                </div>
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm font-bold">Business Unit # </label>
                    <label for="name" class="basis-1/2 text-sm">{{ organizationDetails.number }} </label>                </div>
          
            </div>

     </div>
     <div>
        <h2 class="mb-2">Players</h2>
         
        <div v-if="locationModel?.entities[0]?.players?.length > 0">
        <table>
          <thead>
            <tr class="table-separetor">
            <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="player of locationModel?.entities[0]?.players">
              <td> <a class="link-title cursor-pointer" @click="editPlayer(player.id)" >  {{ player.name }}  </a> </td>
             
              <td>
                <div class="d-flex">
                   <OldThemeIcon @click="editPlayer(player.id)" name="edit" class="icon cursor-pointer" /> 
                </div>
              </td> 
            </tr>
          </tbody>
        </table>
      </div> 
     </div>
  
    </div>
  
  
    <div class="edit-footer editor-btn content">
  
      <div class="form-buttons">
        <ButtonCustomized label="Exit" v-if="!hasChanges" @click="onExit" secondary />
        <BeforeExitDialogue v-if="hasChanges" @save="submitAndExit" @exit="onExit"> </BeforeExitDialogue>
        <ButtonCustomized label="Save" @click="onSubmit" :disabled="!hasChanges" />
        <Saving ref="savingDialog"></Saving>
  
      </div>
    </div>
  </template>
  <script lang="ts" setup>
  import { onBeforeMount, onMounted, onUnmounted, ref, watchEffect } from 'vue'
  import TextField from "@/components/form-fields/TextField.vue";
  import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
  import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
  import useNotifications from "@/components/Notifications/composables/notifications";
  import router from '@/router';
  import { onBeforeRouteLeave, useRoute } from 'vue-router'
  import useLocationEditor from './LocationEditorHelper'
import  OldThemeIcon  from '@/components/icons/OldThemeIcon.vue';
import BeforeExitDialogue from '@/components/Dialog/BeforeExitDialog/BeforeExitDialogue.vue'
import Saving from '@/components/Dialog/SavingDialog/Saving.vue';
import SelectField from '@/components/form-fields/SelectField/SelectField.vue';

  const savingDialog = ref()
  const route = useRoute()
  const notification = useNotifications();
  const {  values, submitForm, isSuccess, loadLocation,form,regions,
    hasChanges, errorMessage, removeBeforeLoad, isLoading, locationId,locationModel,organizationDetails
  } = useLocationEditor();
  onBeforeMount(() => {
    locationId.value = route.params.id as string
    loadLocation()
  })
  onUnmounted(() => {
    removeBeforeLoad()
  })
  onBeforeRouteLeave((to, from) => {
    if (hasChanges.value) {
      const answer = window.confirm(
        'Changes you made may not be saved.'
      )
      if (!answer) return false
    }
  })
  
  async function submitAndExit() {
    savingDialog.value.open()
    await submitForm();
    savingDialog.value.close()
    if (isSuccess.value === true) {
      notification.success('Location updated successfully!');
      removeBeforeLoad();
      router.push({ path: '/locations' })
    }
  }
  async function onSubmit() {
   savingDialog.value.open()
    await submitForm();
    savingDialog.value.close()
    if (isSuccess.value === true) {
      removeBeforeLoad();
      notification.success('Location updated successfully!');
    }
  }
  function onExit() {
    removeBeforeLoad()
    router.push({ path: '/locations' })
  }
  function editPlayer (itemId:string){
    router.push({ name: 'player-editor', params: { id: itemId } });

}
  </script>
  <style lang="scss" scoped>
  .link-title {
    font-weight: bold;
  }
.link-title:hover {
   
    text-decoration: underline;
    color: #93A5AE;

}
.editor-btn-exit {
    position: fixed;
    bottom: 0px;
    right: 70px;
  }
  
  .editor-btn {
    position: fixed;
    bottom: 34px;
    width: fit-content;
    right: 0px;
    max-width: 1200px;
    text-align: right;
    z-index: 5;
    padding-bottom: 10px;
    padding-top: 7px;
  }
  
  .status-bar-container {
    margin-bottom: 1rem;
  }
  
  .create-slide-form {
    display: flex;
    flex-direction: column;
    max-width: 20rem;
  }
  
  .separetor {
    border-bottom: 1px solid #e6e6e6;
  }
  
  .form-fields {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
  
    @include medium-devices-down {
      flex-direction: column;
    }
  }
  
  .formGroup {
    display: flex;
    align-items: center;
  
    .select {
      width: 60%;
      margin: 0;
    }
  
    label {
      display: inline-block;
    //  width: 70px;
      color: #262626;
      font-weight: bold;
    }
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  
  h1.title {
    font-size: 2em;
    font-weight: bold;
    text-transform: uppercase;
  
    @include theme-old {
      color: #252949;
    }
  
    @include theme-new {
      color: #252949;
    }
  
    letter-spacing: 2px;
  }
  
  .sub-title {
    font-size: 1.5em;
    font-weight: bold;
  
    @include theme-old {
      color: #252949;
    }
  
    @include theme-new {
      color: #252949;
    }
  
    letter-spacing: 2px;
  }
  
  .add-link {
    font-weight: bold;
    color: #252949;
    display: inline-block;
  }
  .add-link:hover{
    text-decoration: underline;
  }
  .add-info {
    font-family: Arial;
    color: #666666;
    font-size: 16px;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  
  th,
  td {
    text-align: left;
    padding: 8px;
  }
  
  .top-header {
    display: flex;
    align-items: center;
  //  justify-content: space-between;
  }
  
  .table-separetor {
    border-bottom: 1px solid #e6e6e6;
  
  }
  
  .tb-link {
    cursor: pointer
  }
  
  .tb-link :hover {
    text-decoration: underline;
    color: #93A5AE;
  }
  
  .edit-campaign {
    margin-bottom: 80px;
  }
  
  

  .icon {
  position: absolute;
  top: 8px;
  right: 2px;
  z-index: 1;
  pointer-events: none;
}

  .styled-select {
  /* TODO: to separate templates related from generic later as it was quickly copied  */
  /* TODO: to think about the className it was quickly copied  */

  border: 1px solid #abadb3;
  /* Old disabled styling */
  /* line-height: 0; */
  /* NEW STYLING */
  line-height: 1em;
  display: flex;
  justify-content: center;
  height: 34px;
  overflow: hidden;
  padding: 0;
  position: relative;

  /* width: 46px; */
  width: 100%;

  :deep(select) {
    background: transparent;
    border: 0;
    left: 0;
    font-size: 14px;
    top: 0;
    appearance: none;
    z-index: 1;
    cursor: pointer;
    padding: 9px;
    margin: 0;

    width: 100%;
    padding: 0 1.2em 0 0.6em;
    outline: none;
    color: #666666;
  }
}
  </style>
  