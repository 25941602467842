import {
  sprites,
} from '../slide';
import { useImportedPaths } from '@/composables/utils/importedPaths';
import loadingSpinnerGifImgUrl from '@/assets/images/preloader.gif';

import {
  pauseHistory,
  resumeHistory,
} from "./history";

const importedImgsUrls = useImportedPaths({'loadingSpinner': loadingSpinnerGifImgUrl});

export function loadImages() {    // quick temporary to think about
  sprites.forEach((spriteItem) => loadImageForImageSprite(spriteItem));
}

export function loadImageForImageSprite(spriteItem) {
  pauseHistory();
  if(spriteItem.type != 'image') {
    return;
  }
  
  // prepare loading spinner image
  // TODO: find out a solution for making loading spinner gif animate on Konva — N3G0eG6l
    // There's already a suggestion here (https://konvajs.org/docs/sandbox/GIF_On_Canvas.html) but needs more investigation
  if(!spriteItem.src) return;
  const loadingSpinnerImgSrc = importedImgsUrls.value.loadingSpinner;
  const loadingSpinnerImg = new window.Image();
  loadingSpinnerImg.src = loadingSpinnerImgSrc;
  spriteItem.image = loadingSpinnerImg;

  const image = new window.Image();
  image.src = spriteItem.src as string;
  console.log({heyyy: image})
  resumeHistory();
  image.onload = () => {
    pauseHistory();
    // set image only when it is loaded
    spriteItem.image = image as any;
    resumeHistory();
  };
}