import filtersDisplayConfig from "./filtersDisplay.config";
import apiPathsConfig from './apiPaths.config';
import PlayersTableListItem from "../OrganizationsListItem/OrganizationsTableListItem.vue";
import availableActionsConfig from "./availableActions.config";
import availableListViewsConfig from "./availableListViews.config";

const itemsListerConfig = {
  TableViewItem: PlayersTableListItem,
  apiPathsConfig,
  filtersDisplayConfig,
  availableActionsConfig,
  availableListViewsConfig,
}

export default itemsListerConfig;