import { nextTick } from "vue";
import {
  sprites,
  spritesReversed,
  basicInfo,
} from '../slide';

import { loadImageForImageSprite } from "./spritesImageLoading";
import { addSpriteToSelection } from '@/views/MediaEditorView/SlideEditor/utils/spritesSelection';
import { applyImageFitmentForSprite } from '../../Stage/stageEvents/handlers/imageFitmentHelpers';

import { log } from '@/helpers/utils';

// Recursive function to collect all sprite and asset ids from a media item (deep search)
function getSpriteAssetIds(sprite) {
  if (!sprite.asset) {
    return [];
  }

  const spriteAssetIds = [sprite.asset.id];

  if (sprite.asset.sprites && sprite.asset.sprites.length > 0) {
    const nestedAssetIds = sprite.asset.sprites
      .map(nestedSprite => getSpriteAssetIds(nestedSprite)) 
      .flat(); 
    spriteAssetIds.push(...nestedAssetIds)
  }
  return spriteAssetIds;
}

function validateSlideReferences(basicInfo, mediaItem) {
  const originalSlideId = basicInfo.id;
  const addedMediaItemId = mediaItem.id;

  const spriteAssetIds: any[] = mediaItem.sprites
    .map(sprite => getSpriteAssetIds(sprite)) 
    .flat(); 
  if (
    originalSlideId === addedMediaItemId || 
    spriteAssetIds.some((id) => id === originalSlideId) 
  ) {
    throw new Error(
      "The selected image either contains or references the current slide, and cannot be added. Please choose a different image."
    );
  }
}

export async function addNewImageSpriteFromMediaItem(mediaItem) {
  validateSlideReferences(basicInfo, mediaItem)
  // Notes
    // for adding new image sprite (not existing before "not retrieved from slide's sprites data on server but newly added")
    // "MediaItem" can be an "image" or "slide"
  // prepare height and width of image to be fitting the slide width and height if it's larger than the slide
  const slideHeight = basicInfo.height;
  const slideWidth = basicInfo.width;
  const imageWidth = mediaItem.sourceWidth;
  const imageHeight = mediaItem.sourceHeight;
  let newSpriteWidth, newSpriteHeight;
  const isImageNotFittingSlide = imageWidth > slideWidth || imageHeight > slideHeight;
  if(isImageNotFittingSlide) {
    const bestFitScalingRatio = Math.min(slideWidth / imageWidth, slideHeight / imageHeight);
    newSpriteWidth = imageWidth * bestFitScalingRatio;
    newSpriteHeight = imageHeight * bestFitScalingRatio;
  } else {
    newSpriteWidth = imageWidth;
    newSpriteHeight = imageHeight;
  }
  
  log({newSpriteWidth, newSpriteHeight, imageWidth, imageHeight, slideWidth, slideHeight}, {ns: "from addImage"})
  // set the position of the image to be in the center of the slide
  const newSpritePositionX = (slideWidth - newSpriteWidth) / 2;
  const newSpritePositionY = (slideHeight - newSpriteHeight) / 2;
  
  const newImageSprite = {
    type: "image",
    image: null,
    src: mediaItem.sourceUri,
    x: newSpritePositionX,
    y: newSpritePositionY,
    width: newSpriteWidth,
    height: newSpriteHeight,
    rotation: 0,
    imageWidth: mediaItem.sourceWidth,
    imageHeight: mediaItem.sourceHeight,
    sourceAssetWidth: mediaItem.sourceWidth,
    sourceAssetHeight: mediaItem.sourceHeight,
    imageX: 0,
    imageY: 0,
    name: mediaItem.name,
    sourceAssetId: mediaItem.id.toString(),
    id: (sprites.length + 1).toString(),    // this is a temporary generated sprite id for newly added sprites
    fitment: "fill"
  }
  applyImageFitmentForSprite(newImageSprite.fitment, newImageSprite);
  spritesReversed.unshift(newImageSprite);    // TODO: make sure that unshift usage here doesn't cause performance degredation in common usage scenarios.
  const newlyAddedImageSprite = spritesReversed[0];
  loadImageForImageSprite(newlyAddedImageSprite);
  await nextTick();
  addSpriteToSelection(newlyAddedImageSprite.id, {clearPrevious: true});
}

export function changeImageOfImageSprite(imageSprite, mediaItem) {
  validateSlideReferences(basicInfo, mediaItem)
  imageSprite.src = mediaItem.sourceUri;
  imageSprite.sourceAssetWidth = mediaItem.sourceWidth;
  imageSprite.sourceAssetHeight = mediaItem.sourceHeight;
  imageSprite.name = mediaItem.name;
  imageSprite.sourceAssetId = mediaItem.id.toString();
  applyImageFitmentForSprite(imageSprite.fitment, imageSprite)
  loadImageForImageSprite(imageSprite);
}

export function removeImageFromImageSprite(sprite: any) {
  sprite.image = null;
  sprite.src = null;
}