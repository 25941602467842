<template>
  <template
    v-for="fitmentButton in fitmentButtons"
    :key="fitmentButton.iconName"
  >
    <v-tooltip :text="fitmentButton.label">
      <template v-slot:activator="{ props }">
        <div :class="['icon', {'active': fitmentButton.name === selectedSprite.fitment}]">
          <OldThemeIcon
            :name="fitmentButton.iconName"
            @click="applyImageFitmentForSprite(fitmentButton.name, selectedSprite)"
            v-bind="props"
          />
        </div>
      </template>
    </v-tooltip>
  </template>
</template>

<script lang="ts" setup>
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import type { ImageSprite } from '@/helpers/types/media/slide/Sprite';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import type { ComputedRef } from 'vue';
import { applyImageFitmentForSprite } from '@/views/MediaEditorView/SlideEditor/Stage/stageEvents/handlers/imageFitmentHelpers';

const { selectedSprite } = useSlide() as { selectedSprite: ComputedRef<ImageSprite> };

const fitmentButtons = [
  {
    iconName: "fitment-best-fit",
    label: "Best Fit",
    name: "best-fit"
  }, 
  {
    iconName: "fitment-fitment-fill",
    label: "Fill",
    name: "fill"
  },
  {
    iconName: "fitment-original",
    label: "Original",
    name: "original"
  },
]


</script>

<style lang="scss" scoped>
.icon {
  cursor: pointer;
  padding: 0.175rem;
  border-radius: 0.375rem;
  &.active, &:hover {
    background-color: rgb(209, 213, 219);
  }
}
</style>