import { submitUpdateImageOrVideo } from "@/helpers/api/media/updateImageOrVideo";
import { computed, reactive, ref, watch, type ComputedRef } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import useImageOrVideo, {type ImageOrVideoBaseEditableDataToSubmit} from "./imageOrVideo";
import _omit from "lodash/omit";
import cloneDeep from "lodash/cloneDeep";
import { onMounted } from "vue";
  // TODO: to think about: moving "data" out of here into the parent caller and make it passed via "submitForm" function

export default function saveImageOrVideoFormHelpers() {
  const { data } = useImageOrVideo();
  const dataToSubmit: ComputedRef<ImageOrVideoBaseEditableDataToSubmit> = computed(() => {
    const dataClone = cloneDeep(data);
    const dataPrepared = _omit(dataClone, [
      'versions',
      'dateLastModified',
    ]);
    if(dataPrepared.expirationTime) {
      dataPrepared.expirationTime = new Date(dataPrepared.expirationTime+"Z").getTime();
    }
    return dataPrepared;
  });

  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const newSlideId = ref("");
  
  const validationSchema = yup.object({
    name: yup.string().label('Name').required()
  });

  const initialValues = {
    name: "",
    // defaultDuration: 10,  // to remove
  };

  onMounted(updateFormValues);
  watch(dataToSubmit, () => {
    // update form values on data change;
    updateFormValues()
    // values.defaultDuration = data.defaultItemDuration;  // to remove
  })
  function updateFormValues() {
    values.name = dataToSubmit.value.name;
  }
  const { errors, handleSubmit, values, setValues, isSubmitting } = useForm({ validationSchema, initialValues });
  
  const submitForm = handleSubmit(handleValidFormSubmit, handleInvalidFormSubmit);

  async function handleValidFormSubmit() {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      const submissionResponse = await submitUpdateImageOrVideo({
        ...dataToSubmit.value,
      });
      isLoading.value = false;
      if (typeof submissionResponse[0].name === "string") {
        // if is successful
        isSuccess.value = true;
      }
    } catch (error) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
  }

  async function handleInvalidFormSubmit() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }

  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    newSlideId
  };
}