<template>
  <td>
    <Dialog size="full">
      <template #expander="{openModal}">
        <component
          :is="renderers.ThumbnailPreview"
          :previewUri="`${item.previewUri}?lastModified=${item.dateLastModified}`"
          :date-last-modified="item.dateLastModified"
          @click="openModal"
          :alerts="item.alerts"
          :wrapperLink="item?.versions?.[0]?.resource?.publicUrl || item?.sourceUri"
        />
      </template>
      <template #dialog.title>Preview</template>
      <template #dialog.content>
        <component :is="renderers.MediaItemPreview" v-bind="{filteredListItems, startIndex: itemIndex}" />
      </template>
    </Dialog>
  </td>
  <td class="name-and-type-cell">
    <template v-if="!listerConfig?.itemPickDragMode">
      <RouterLink
        v-if="
          !selectMode &&
          !singleSelect &&
          areaAccess?.hasWriteAccess &&
          user.hasWriteAccessToItem(item) &&
          user.hasWriteAccessToAssetType(item?.assetType)
        "
        :to="'/media/edit/'+item.id"
        tag="div"
        :class="['font-bold', 'title']"
        component="a"
        @click.stop
      >
        {{item.name}}
      </RouterLink>
      <div v-else>
        {{item.name}}
      </div>
    </template>
    <template v-else> <!-- if listerConfig.itemPickDragMode -->
      {{item.name}}
    </template>
    <div>
      {{ (item.assetType ? item.assetType[0].toUpperCase() + item.assetType.substring(1) : "" ) }}
    </div>
  </td>
  <td class="min-w-[7rem]">
    <component :is="renderers.TimeFormatDateOnly" :epochTime="item.dateLastModified" />
  </td>
  <td  class="min-w-[7rem]">
    <component :is="renderers.TimeFormatDateOnly" :epochTime="item.dateCreated" />
  </td>
  <td>
    {{ _get(item, 'createdBy.group.name', 'None') }}
  </td>
  <td>
    <!-- TODO: think about parsing the sharing instead of a number code to a string value [DSP-N23LeqE8]
          - It may have issues with memory however the list won't be too big
    -->
    {{ SharingLevelsMapping[item.sharingLevel] }}
  </td>
</template>

<script lang="ts" setup>
import _get from 'lodash/get';
import Dialog from '@/components/Dialog/Dialog.vue';
import SharingLevelsMapping from '@/data/mappings/SharingLevels.map';
import useUser from '@/composables/user';
const user = useUser();

const props = defineProps<{
  item: any;
  itemIndex: any;
  ItemActionsButtons?: any;
  ItemTableSelectBox?: any;
  columnsConfig: any;
  renderers: any;
  filteredListItems: any;
  apiPathsConfig?: any;
  listerConfig?: any;
  selectMode?: boolean;
  singleSelect?:boolean;
}>();

const {
  areaAccess
} = props.listerConfig;

</script>

<style lang="scss" scoped>
.title {
  color: #25294A;
  text-decoration: none;
  cursor: pointer;
  max-width: 200px;
  display: inline-block;
}
.title:hover {
  text-decoration: underline;
}

.name-and-type-cell {
  word-break: break-word;
  min-width: 10rem;
}
</style>