import usePosSourcesListHelpers from '../../POSSourcesLIster/posSourcesListHelpers';
import { computed, ref } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import { submitCreatePlaylistItem } from "@/helpers/api/playlist/create";
import { log } from "@/helpers/utils";

export default function useCreatePlaylistFormHelpers() {
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]
  const { mutateList, listHelperBasicInfo } = usePosSourcesListHelpers();
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const newPlaylistId = ref("");

  const validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    defaultItemDuration: yup.number().label('Default Item Duration').required(),
    // items: yup.array().required(),
    sharingLevel: yup.number().label('Sharing Level').required(),
  });

  const initialValues = {
    name: "",
    // items: [], //
    defaultItemDuration: 5,
    sharingLevel: 1,
    backgroundColor: "#FFFFFF",
    // id: 0 // 
  };

  const { errors, handleSubmit, values, setValues, isSubmitting } = useForm({ validationSchema, initialValues });

  const fieldsToSubmit = computed(() => {
    const { name, /* items, */ defaultItemDuration, sharingLevel, /* id, */ backgroundColor } = values;
    const fitmentId = listHelperBasicInfo.creator.fitments[0].id;
    // const fitmentId = listResponseRef.value.creator.fitments[0].id;
    return {
      name,
      // items,
      defaultItemDuration,
      sharingLevel,
      // id,
      backgroundColor,
      fitment: {
        id: fitmentId,
      },
    };
  });
  log({fieldsToSubmit}, {ns: "fieldsToSubmit", color: "blue"});
  
  const submitForm = handleSubmit(handleValidFormSubmit, handleInvalidFormSubmit);

  async function handleValidFormSubmit() {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      const submissionResponse = await submitCreatePlaylistItem(
        fieldsToSubmit.value
      );
      isLoading.value = false;
      if (typeof submissionResponse[0].name === "string") {
        // if is successful
        isSuccess.value = true;
        newPlaylistId.value = submissionResponse[0].id;
        mutateList();
      }
    } catch (error) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
  }

  async function handleInvalidFormSubmit() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }

  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    newPlaylistId
  };
}
