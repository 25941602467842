<!--
  Notes
    First inspired from: @\components\Collapsible\Collapsible.vue
-->

<template>
<ButtonCustomized
  :label="(isCollapsed ? label : expandedLabel)"
  @click="toggleCollapse"
  class="collapsible-button"
/>
<div :class="['collapsible-content', { expanded: !isCollapsed }]">
  <slot v-if="!isCollapsed"></slot>
</div>

</template>

<script lang="ts" setup>
import ButtonCustomized from '@/components/form-fields/ButtonCustomized.vue';
import { ref } from 'vue';

export interface Props {
  label: string,
  expandedLabel: string,
  maxHeight: number | string    // max height for the expanded collapsible content
}

const props = withDefaults(defineProps<Props>(), {
  maxHeight: "300px"
})

const isCollapsed = ref(true);
function toggleCollapse() {
  isCollapsed.value = !isCollapsed.value;
}
const expandedMaxHeight = props.maxHeight;
</script>

<style lang="scss" scoped>
$transitionTime: 0.3s;

.collapsible-content {
  overflow: hidden;
  transition: all $transitionTime ease-out;
  max-height: 0;
  /* margin-left: 1.35rem; */
  /* background-color: rgb(128 128 128 / 8%); */
  /* border-radius: 0.375rem; */
  /* margin-right: 1rem; */
  padding: 0;
  /* opacity: 0; */
  &.expanded {
      max-height: v-bind(expandedMaxHeight);
      padding-top: 0.750rem;
      /* opacity: 1; */
      /* padding: 0.375rem; */
  }   
}
.collapsible-button {
  width: fit-content;
}
</style>