// TODO: think about renaming file name to listSelectAllItemsHelpers.ts instead of mediaListSelectAllItemsHelpers.ts because these helpers are generally for any list

import useListSelection from '../listSelection';
import useIsAllSelectedItemsAreSlidesHelpers from './isAllSelectedItemsAreSlidesHelpers';
import { computed } from 'vue';

export default function useMediaListSelectAllItemsHelpers( itemsListHelpers ) {
  const {
    afterAddAllToSelectionCallback,
    afterClearSelectionCallback
  } = useIsAllSelectedItemsAreSlidesHelpers( itemsListHelpers );

  const {
    addMultipleItemsToSelection,
    isSelectedItemsCountEquals,
    selectedItemsIds,
    clearItemsSelection
  } = useListSelection();
  
  const {
    filteredListItems,
  } = itemsListHelpers;

  function addAllItemsToSelection() {
    addMultipleItemsToSelection(
      filteredListItems.value,
      {
        itemIdGetterCallback: (item: {id: string | number}) => item.id,
        clearPrevious: true,
        callback: afterAddAllToSelectionCallback,
        afterClearCallback: afterClearSelectionCallback
      }
    );
  }

  function clearMediaItemsSelection() {
    clearItemsSelection({callback: afterClearSelectionCallback});
  }
    

  const isAllItemsSelected = computed(() => isSelectedItemsCountEquals(filteredListItems.value.length) && filteredListItems.value.length !== 0);
  const isNoItemSelected = computed(() => selectedItemsIds.length === 0);
  return {
    isAllItemsSelected,
    isNoItemSelected,
    addAllItemsToSelection,
    clearMediaItemsSelection
  }
}