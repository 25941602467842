<template>
{{ formattedTime }}
</template>

<script lang="ts" setup>
import { computed } from 'vue';
const props = defineProps<{epochTime: number}>();

const formattedTime = computed(() =>{
const date = new Date(props.epochTime);
return  date.toLocaleDateString('en-US', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  timeZone: 'UTC'
});
  });
</script>

<style lang="scss" scoped>

</style>