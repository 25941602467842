<template>
    <Dialog :expanderClass="'d-inline'" ref="organization">
      <template #expander="{ openModal }">
    <ButtonCustomized :label="btnTitle" @click="openModal" v-if="showBtn" /> 
        <a @click="openModal" v-if="!showBtn" class="change-link" >{{ btnTitle }}</a>
  
      </template>
      <template #dialog.title>
        Select Organization
      </template>
      <template #dialog.content="{ closeModal, closeModalAfter }">
  
  
        <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
        <div>

            <SimpleEntityListingPage
            entityItemsNamePlural=""
    entityItemName="''"
    areaAccessName="Administration"
    :ItemsLister="OrganizationsLister"
    :singleSelect = true 
  />

          <div class="form-buttons">
            <ButtonCustomized label="Add" @click="onSave" />
          </div>
        </div>
  
      </template>
    </Dialog>
  </template>
      
  <script lang="ts" setup>
  import Dialog from '@/components/Dialog/Dialog.vue';
  import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
  import SimpleEntityListingPage from '@/components/ListingPages/SimpleEntityListingPage/SimpleEntityListingPage.vue';
  import OrganizationsLister from '../../OrganizationsListView/OrganizationsLister/OrganizationsLister.vue';
  import useListSelection from '@/components/ListingPages/ItemsLister/ItemsList/composables/listSelection';
  import { ref } from 'vue';
  const organization = ref()
  const emit = defineEmits(['cancel', 'save', 'exit', 'fail'])
  defineProps({
    'showBtn':{
      type: Boolean,
      default: true
    },
    'btnTitle':{
        type: String , 
        default :'Select Group'
    }
  
  })
  const {
    selectedItemsIds,
    selectedItems,
    clearItemsSelection
  } = useListSelection({
    clearSelectionTriggerElement: document.body
  });
  
  function onExit() {
    emit('exit')
  }
  async function onSave() {
    emit('save', selectedItems[0]);
    clearItemsSelection () ;
    organization.value.closeModal()
  }
  
  </script>
      
  <style lang="scss" scoped>
  .button {
    cursor: pointer;
  }
  
  .delete-item-form {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  .change-link {
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 6px;
    text-transform: uppercase;
    color: #25294A;
    font-weight: bold;
  }
  .change-link:hover{
    text-decoration: underline;
}
  
  </style>