<template>
<TabsPane :tabs="tabs" class="middle-tabs-pane" />
</template>

<script lang="ts" setup>
import { markRaw } from 'vue';
import TabsPane from '@/views/MediaEditorView/SlideEditor/PropertiesPanesRight/TabsPane.vue';
import LayersPane from './LayersPane/LayersPane.vue';
import ShowEditableObjectsToggle from './LayersPane/ShowEditableObjectsToggle.vue';
import TagsPane from './TagsPane/TagsPane.vue';
import AuditingPane from './AuditingPane/AuditingPane.vue';

const tabs = markRaw([
  {
    "label": "Layers",
    "component": LayersPane,
    "headerComponent": ShowEditableObjectsToggle,
    "scrollable": true
  },
  {
    "label": "Tags",
    "component": TagsPane
  },
  {
    "label": "Auditing",
    "component": AuditingPane
  }
])


</script>

<style lang="scss" scoped>
.middle-tabs-pane {
  @include x-large-devices-down {
    height: auto;
  }
  min-height: 15.4em;
  max-height: 21.4em;
  position: relative;
}

</style>