export const loggerNamespaces = {
  'warning': {
    color: 'orange',
    prefix: '⚠️',
    disabled: true
  },
  'info': {
    disabled: true
  },
  'debug': {
    disabled: true
  },
  'debug:selectorRectangleStageHandlers': {
    disabled: true
  },
  'debug:spritesVisibility': {
    disabled: true
  },
  'debug:updateStageSizeBasedOnContainer': {
    disabled: true
  },  
  'debug problem with keyboardKeysStageHandlers': {
    disabled: false
  }
} as any;

export const loggerConfig = {
  // TODO: think about implementing "showElapsedTimeSinceLastLogCall", "showElapsedTimeSinceLastNamespaceLogCall" as global options — N2K3a2Ax
  showCurrentTime: false,
}