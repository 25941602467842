import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '@/views/DashboardView/DashboardView.vue'
import LoginView from '@/views/LoginView.vue'

// Lists pages
import MediaListView from '@/views/MediaListView/MediaListView.vue'
import PlaylistsListView from '@/views/PlaylistsListView/PlaylistsListView.vue'
import CampaignsListView from '@/views/CampaignsListView/CampaignsListView.vue'
import PlayersListView from '@/views/PlayersListView/PlayersListView.vue'
import POSSourcesListView from '@/views/POSSourcesListView/POSSourcesListView.vue'

import OrganizationsListView from '@/views/SettingsViews/OrganizationsListView/OrganizationsListView.vue'
import UsersListView from '@/views/SettingsViews/UsersListView/UsersListView.vue'
import LocationsListView from '@/views/SettingsViews/LocationsListView/LocationsListView.vue'
import RolesListView from '@/views/SettingsViews/RolesListView/RolesListView.vue'
import AuditingListView from '@/views/SettingsViews/AuditingListView/AuditingListView.vue'
import UserCreator from '@/views/SettingsViews/UsersListView/CreateUserForm/CreateUserForm.vue'
import RoleCreator from '@/views/SettingsViews/RolesListView/CreateRoleForm/CreateRoleForm.vue'
// Editor pages
import MediaEditorView from '@/views/MediaEditorView/MediaEditorView.vue'
import PlaylistEditorView from '@/views/PlaylistEditorView/PlaylistEditorView.vue'
import AccountEditorView from '@/views/AccountEditorView/AccountEditorView.vue'
import CampaignEditorView from '@/views/CampaignEditorView/CampaignEditorView.vue'
import PlayerEditorView from '@/views/PlayerEditorView/PlayerEditorView.vue'
import POSSourceEditorView from '@/views/POSSourceEditorView/POSSourceEditorView.vue'

import OrganizationEditorView from '@/views/SettingsViews/OrganizationEditorView/OrganizationEditorView.vue'
import UserEditorView from '@/views/SettingsViews/UsersEditorView/UserEditorForm.vue'
import LocationEditorView from '@/views/SettingsViews/LocationEditorView/LocationEditorView.vue'
import RoleEditorView from '@/views/SettingsViews/RoleEditorView/RoleEditorView.vue'
// TODO: think about making a generic component "SimpleEntityEditor" that will be used for all simple entities — N2MErZC3

import useUserStore from '@/stores/user';
import ResetPasswordViewVue from '@/views/ResetPasswordView/ResetPasswordView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: DashboardView,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: { requiresAuth: false, transition: 'slide-right' }
    },
    // {
    //   path: '/users/edit/:id',
    //   name: 'edit-user',
    //   component: MediaEditorView,
    //   meta: { requiresAuth: true }
    // },
    
    // Lists pages
    {
      path: '/media',
      name: 'media',
      component: MediaListView,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    {
      path: '/playlists',
      name: 'playlists',
      component: PlaylistsListView,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    {
      path: '/campaigns',
      name: 'campaigns',
      component: CampaignsListView,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    {
      path: '/players',
      name: 'players',
      component: PlayersListView,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    {
      path: '/pos-sources',
      name: 'pos-sources',
      component: POSSourcesListView,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    {
      path: '/organizations',
      name: 'organizations',
      component: OrganizationsListView,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    {
      path: '/locations',
      name: 'locations',
      component: LocationsListView,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    {
      path: '/users',
      name: 'users',
      component: UsersListView,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    {
      path: '/users/create',
      name: 'users-create',
      component: UserCreator,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    
    {
      path: '/roles',
      name: 'roles',
      component: RolesListView,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    {
      path: '/roles/create',
      name: 'roles-create',
      component: RoleCreator,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },
    {
      path: '/auditing',
      name: 'auditing',
      component: AuditingListView,
      meta: { requiresAuth: true, transition: 'slide-right' }
    },

    // Editors pages
    {
      path: '/media/edit/:id',
      name: 'media-editor',
      component: MediaEditorView,
      meta: { requiresAuth: true }
    },
    {
      path: '/playlists/edit/:id',
      name: 'playlist-editor',
      component: PlaylistEditorView,
      meta: { requiresAuth: true }
    },
    {
      path: '/campaigns/edit/:id',
      name: 'campaign-editor',
      component: CampaignEditorView,
      meta: { requiresAuth: true }
    },
    {
      path: '/players/edit/:id',
      name: 'player-editor',
      component: PlayerEditorView,
      meta: { requiresAuth: true }
    },
    {
      path: '/pos-sources/edit/:id',
      name: 'pos-source-editor',
      component: POSSourceEditorView,
      meta: { requiresAuth: true }
    },
    {
      path: '/organizations/edit/:id',
      name: 'organization-editor',
      component: OrganizationEditorView,
      meta: { requiresAuth: true }
    },
    {
      path: '/locations/edit/:id',
      name: 'location-editor',
      component: LocationEditorView,
      meta: { requiresAuth: true }
    },
    {
      path: '/users/edit/:id',
      name: 'user-editor',
      component: UserEditorView,
      meta: { requiresAuth: true }
    },
    {
      path: '/roles/edit/:id',
      name: 'role-editor',
      component: RoleEditorView,
      meta: { requiresAuth: true }
    },
    {
      path: '/account',
      name: 'account',
      component: AccountEditorView,
      meta: { requiresAuth: true }
    },
    {
      path: "/Account/ChangePassword",
      name: "reset-password",
      component: ResetPasswordViewVue,
      meta: { requiresAuth: false },
    },
  ]
})

router.beforeEach((to) => {
  const user = useUserStore()

  if (to.meta.requiresAuth && !user.isLoggedIn) return '/login'
})

export default router
