import { submitUpdatePlaylist } from "@/helpers/api/playlist/update";
import { ref, watch } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import usePlaylist from "./playlist";
  // TODO: to think about: moving "data" out of here into the parent caller and make it passed via "submitForm" function

export default function savePlaylistFormHelpers() {
  const { data } = usePlaylist();

  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const newSlideId = ref("");

  const validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    defaultDuration: yup.number().label('Default Duration').required(),
  });

  const initialValues = {
    name: "",
    defaultDuration: 10,
  };

  watch(data, () => {
    // update form values on data change;
    values.name = data.name;
    values.defaultDuration = data.defaultItemDuration;
  })
  const { errors, handleSubmit, values, setValues, isSubmitting } = useForm({ validationSchema, initialValues });
  
  const submitForm = handleSubmit(handleValidFormSubmit, handleInvalidFormSubmit);

  async function handleValidFormSubmit() {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      const dataToSubmit = JSON.parse(JSON.stringify(data));
      dataToSubmit.approvalState = 1;
      // TODO (May-2023): to think about removing this "approvalState" forced value to be 1.
        // This is added because of the old buggy state where "Playlists" and "Campaigns" were saved with "approvalState" and "sharingLevel" of 0.
        // So, to avoid fix the issue, resaving the playlist / campaign with these forced values solves the problem.
      // TODO (May-2023): to clean any more unnecessary data from the "dataToSubmit" object and also on the state "data" object
      delete dataToSubmit.campaigns;
      delete dataToSubmit.size;
      dataToSubmit.items.forEach(item => {
        delete item.asset.versions;
      });

      const submissionResponse = await submitUpdatePlaylist(dataToSubmit);
      isLoading.value = false;
      if (typeof submissionResponse[0].name === "string") {
        // if is successful
        isSuccess.value = true;
      }
    } catch (error) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
  }

  async function handleInvalidFormSubmit() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }

  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
  };
}