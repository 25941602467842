import { isDevModeOn } from './devStageHandlers'
import { stageNode, developmentStatsTextShape } from '@/views/MediaEditorView/SlideEditor/Stage/baseElementsRefs'
import { getSlideShapesIntersectingWithPoint } from '@/views/MediaEditorView/SlideEditor/utils/spritesGetters';

export function updateDevelopmentStats(e: any) {
  if(!isDevModeOn.value) {
    return
  }
  const eventType = e.type;
  const mousePos = stageNode.value.getPointerPosition();
  const x = mousePos.x.toFixed(0);
  const y = mousePos.y.toFixed(0);
  // hovered over shapes
  // selected shapes
  const hoveredOverShapes = getSlideShapesIntersectingWithPoint(x,y);
  // console.log({hoveredOverShapes})
  const hoveredOverShapesNames = hoveredOverShapes.map((shape) => {
    const shapeType = shape.getAttr('type');
    const shapeName = `${shape.name()}`;
    return `${shapeName} <${shapeType}>`;
  })
  writeMessageToDevelopmentStats('x: ' + x + ', y: ' + y+'\neventType: '+eventType+'\nhoveredOverShapes:'+JSON.stringify(hoveredOverShapesNames)+'}');

  function writeMessageToDevelopmentStats(message: string) {

    // console.log({testC: isDevModeOn.value})
    if(!isDevModeOn.value) {
      return;
    }
    const developmentStatsTextNode = developmentStatsTextShape.value.getNode();
    
    developmentStatsTextNode.text(message);
  }
}