import useApi from '../api';

export async function submitCopyListItem(
  itemId: string,
  {
    copyApiPath
  }
) {
  const { post } = useApi();
  const copyApiPathPrepared = copyApiPath+'/'+itemId+'/copy';
    // TODO: think about passing something like "api/assets/:id/copy" (may not be needed for this project) — N31IAdB9
  const { responseBody } = await post(copyApiPathPrepared, null, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}