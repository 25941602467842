<template>
  <div v-if="user.isLoggedIn">
    <div class="welcome-message">
      Welcome back. Where would you like to start?
    </div>
    <div class="basic-dashboard-cards">
      <template v-for="item in basicDashboardCards">
      <RouterLink
        class="basic-dashboard-card"
        :to="user.hasReadAccessToArea(item.areaAccessName) ? item.path : ''"
        v-if="user.hasReadAccessToArea(item.areaAccessName)"
        tag="div"
      >
        <div class="title">
          {{ item.name }}
        </div>
        <img :src="item.imageSrc" />
        <div class="description">
          {{ item.description }}
        </div>
        <div class="button" v-if="user.hasReadAccessToArea(item.areaAccessName)">
          {{ item.buttonText }}
        </div>
      </RouterLink>
    </template>
    </div>
    <div class="admin-dashboard-cards" v-if="user.hasReadAccessToArea('Administration')">
      <template v-for="item in adminDashboardCards">
        <RouterLink
          v-if="!item.checkIsVisible || (item.checkIsVisible && item.checkIsVisible(user))"
          :key="item.name"
          class="admin-dashboard-card"
          :to="item.path"
          tag="div"
        >
          <img v-if="item?.imageSrc" :src="item.imageSrc" />
          <div v-if="item?.counter" class="counter">
            <div class="name">{{ item.counter.name }}</div>
            <div class="count">{{ item.counter.count }}</div>
          </div>
          <div class="title">
            {{ item.name }}
          </div>
          <div class="button">
            {{ item.buttonText }} <OldThemeIcon name='arrow-right' />
          </div>
        </RouterLink>
      </template>
    </div>
  </div>
</template>


<script lang="ts" setup>
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import mediaImageUrl from "@/assets/images/dashboard/media.png";
import playlistsImageUrl from "@/assets/images/dashboard/playlists.png";
import campaignsImageUrl from "@/assets/images/dashboard/campaigns.png";
import playersImageUrl from "@/assets/images/dashboard/players.png";

import organizationsImageUrl from "@/assets/images/dashboard/organizations.png";
import locationsImageUrl from "@/assets/images/dashboard/locations.png";
import usersImageUrl from "@/assets/images/dashboard/users.png";
import rolesImageUrl from "@/assets/images/dashboard/roles.png";

import useUser from '@/composables/user';
const user = useUser();

onMounted(()=> {
  user.checkLogin();
})

import { useImportedPaths } from '@/composables/utils/importedPaths';
import { onMounted } from 'vue';

const assetsDashboardUrls = useImportedPaths({
    media: mediaImageUrl,
    playlists: playlistsImageUrl,
    campaigns: campaignsImageUrl,
    players: playersImageUrl,
    organizations: organizationsImageUrl,
    locations: locationsImageUrl,
    users: usersImageUrl,
    roles: rolesImageUrl
});

const basicDashboardCards = [
  {
    name: "Media",
    areaAccessName: "MediaLibrary",
    description: "Upload images and videos.",
    path: "/media",
    imageSrc: assetsDashboardUrls.value.media,
    buttonText: "Upload & Manage",
  },
  {
    name: "Playlists",
    areaAccessName: "Playlists",
    description: "Media is organized into Playlists - then assigned to Campaigns.",
    path: "/playlists",
    imageSrc: assetsDashboardUrls.value.playlists,
    buttonText: "Create & Manage",
  },
  {
    name: "Campaigns",
    areaAccessName: "Campaigns",
    description: "Campaigns are collections of Playlists, scheduled to run on specific dates and Players.",
    path: "/campaigns",
    imageSrc: assetsDashboardUrls.value.campaigns,
    buttonText: "Create & Manage",
  },
  {
    name: "Players",
    areaAccessName: "Players",
    description: "It’s showtime! Setup and manage your physical Players.",
    path: "/players",
    imageSrc: assetsDashboardUrls.value.players,
    buttonText: "Add & Manage",
  },
  // {
  //   name: "POS Sources",
  //   description: "It’s showtime! Setup and manage your POS live integrations.",
  //   path: "/pos-sources",
  //   imageSrc: assetsDashboardUrls.value.players,
  //   buttonText: "Add & Manage",
  //   areaAccessName: "Playlists"
  // },
];

const adminDashboardCards = [
  {
    name: "Auditing",    //
    counter: {
      name: "Pending Approval",
      count: 3    // TODO: make this count gotten from the backend api
    },
    path: "/auditing",
    buttonText: "Manage",
    checkIsVisible: (user: any) => user.isAuditor()
  },
  {
    name: "Organizations",
    path: "/organizations",
    imageSrc: assetsDashboardUrls.value.organizations,
    buttonText: "Manage"
  },
  {
    name: "Locations",
    path: "/locations",
    imageSrc: assetsDashboardUrls.value.locations,
    buttonText: "Manage"
  },
  {
    name: "Users",
    path: "/users",
    imageSrc: assetsDashboardUrls.value.users,
    buttonText: "Manage"
  },
  {
    name: "Roles",
    path: "/roles",
    imageSrc: assetsDashboardUrls.value.roles,
    buttonText: "Manage"
  },
];

</script>



<style lang="scss" scoped>

$large-break-width: 1300px;

.welcome-message {
  font-size: 28px;

  border: 0;
  margin: 0;
  padding: 0;
  color: #25294A;
  font-weight: bold;
  letter-spacing: 2px;
  display: inline-block;
  @include screen-width-down($large-break-width) {
    margin-left: 1em;
  }
  
  vertical-align: top;
  margin-top: 20px;
  margin-bottom: 1.575rem;
  text-transform: none;
  font-size: 28px;
  font-family: inherit;
}

.basic-dashboard-cards {
  display: flex;
  justify-content: space-between;
  @include screen-width-down($large-break-width) {
    flex-wrap: wrap;
    justify-content: center;
    /* max-width: 90vw; */
  }
}

.basic-dashboard-card {
  color: #25294A;
  display: block;
  list-style: none;
  list-style-type: none;
  border: 0;
  font: inherit;
  display: inline-block;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  margin: 20px 0 0 0;
  text-align: center;
  /* margin: 20px 0 0 0; */
  text-align: center;
  /* width: 17.5%; */
  width: 18.2vw;
  min-width: 280px;
  background: #FFFFFF 0% 0% no-repeat padding-box;

  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0px 3px 17px rgb(0 0 0 / 16%);
  &:hover {
    box-shadow: 0px 3px 17px rgba(0, 17, 32, 0.25);
  }
  
  border-radius: 18px;
  padding: 2% 3%;
  /* margin-right: 1%; */
  /* margin-left: 0%; */
  margin: 1em 1em;
  cursor: pointer;
  text-decoration: none;
  @include screen-width-down(630px) {
    width: 80%;
  }

  .title {
    list-style: none;
    list-style-type: none;
    text-align: center;
    cursor: pointer;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    color: #16356b;
    @include theme-old {
      color: #25294A;
    }
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 1em;
    letter-spacing: 2px;
  }

  .description {
    list-style: none;
    list-style-type: none;
    text-align: center;
    cursor: pointer;
    border: 0;
    font: inherit;
    line-height: 1.1em;
    /* vertical-align: baseline; */
    margin: 0;
    padding: 0;
    color: #25294A;
    display: block;
    height: 70px;
    font-size: 16px;
    font-weight: normal;
  }

  img {
    max-width: 120px;
    max-height: 100px;
    margin-bottom: 20px;
  }

  .button {
    font-size: 16px;
    padding: 10px 20px;
    background: linear-gradient(45deg, #114f75, #1a4285);
    transition: all 0.2s ease-in-out;
    @include theme-old {
      background: initial;
      background-color: #25294A;
      &:hover {
        background-color: #B1C4CF;
      }
    }

    margin-bottom: 3px;
    box-shadow: 0px 3px 9px rgb(0 0 0 / 16%);
    border-radius: 6px;
    text-transform: uppercase;
    font-weight: normal;
    border: 0;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    margin-top: 0.6em;
    justify-self: flex-end;

    &:hover {
      background-color: #B1C4CF;
    }
  }
}

.admin-dashboard-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include large-devices-down {
    gap: 1.4rem;
  }
}

.admin-dashboard-card {
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: top;
  width: 130px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 18px;
  padding: 2% 1%;
  margin-left: 1%;
  margin-right: 1%;
  width: 10rem;
  
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0px 3px 17px rgb(0 0 0 / 16%);
  &:hover {
    box-shadow: 0px 3px 17px rgba(0, 17, 32, 0.25);
  }

  & {
    &:link { text-decoration: none; }
    &:visited { text-decoration: none; }
    &:hover { text-decoration: none; }
    &:active { text-decoration: none; }
  }

  img {
    height: 80px;
    margin-bottom: 0.75rem;
  }

  .counter {
    display: flex;
    align-items: center;
    flex-direction: column;
    .name {
      color: #555555;
      font-size: 0.9rem;
      font-weight: bold;
    }
    .count {
      color: #1c69d4;
      font-size: 3.2rem;
      font-weight: bold;
    }
  }

  .title {
    color: #222222;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
  }

  .button {
    color: #25294A;
    height: auto;
    line-height: 16px;
    margin: 10px 0 0 6px;
    padding: 0;
    font-size: 14px;
    box-shadow: none;
    border: none;
    display: flex;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #93A5AE;
    }
  }
}



</style>
