import { fetchPOSSourceItemById } from "@/helpers/api/posSource/fetch";
import { fetchPOSSourceItemById_dummy } from "@/helpers/api/posSource/dummy/fetch";
import { log } from "@/helpers/utils";
import { computed, nextTick, reactive, ref, watch } from "vue";
import { onBeforeRouteLeave } from 'vue-router';
import _pick from "lodash/pick";
import { useHasUnsavedChangesHelpers } from "@/composables/utils/hasUnsavedChangesHelpers";

// TODO: Re-enable this when we fix all the issues with cleaning the sent back to server data (to decrease payload size)
const data = reactive({
  id: null,
  items: [],   // POS items
  name: "",
  posSourceUrl: "",
  isActive: false
});

export default function usePOSSource({ id } = {}) {
  const {
    unsetHasUnsavedChanges,
    initializeHasUnsavedChangesWatcher,
    isLoading,
    hasUnsavedChanges,
    isDataInitialized
  } = useHasUnsavedChangesHelpers({
    statesToWatchForChanges: data
  });

  if (id !== null && id !== undefined) {
    data.id = id;
  }

  async function reloadPOSSource() {
    await loadPOSSource(data.id);
  }
  
  async function loadPOSSource(id) {
    data.id = id;
    isLoading.value = true;
    isDataInitialized.value = false;

    // const posSourceItemResponse = await fetchPOSSourceItemById(id) as any[]; // TODO: to enable when backend is ready
    const posSourceItemResponse = await fetchPOSSourceItemById_dummy(id); // TODO: to remove/disable this when backend is ready
    log(posSourceItemResponse, {color: 'red'});
      
    const posSourceResponseData = posSourceItemResponse[0];
    const pickedData = _pick(posSourceResponseData, Object.keys(data));
    Object.assign(data, pickedData);

    await nextTick();
    isDataInitialized.value = true;
    isLoading.value = false;  
  }
  
  return {
    loadPOSSource,
    reloadPOSSource,
    data,
    isLoading,
    hasUnsavedChanges,
    unsetHasUnsavedChanges,
    initializeHasUnsavedChangesWatcher
  }
}