<!-- Notes
      Toggle Collapse / Open Button
-->

<template>
  <div
    :class='["collapse-button", { "opened": isOpen }]'
    @click="$emit('toggleOpenClick')"
    ref="toggleButton_elementRef"
  >
    <slot />
  </div>

</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';


const { isStickyOnScrollDown } = defineProps({
  'isStickyOnScrollDown': {
    type: Boolean,
    default: false
  },
  'isOpen': {
    type: Boolean,
    default: false
  },
})

onMounted(() => {
  if(isStickyOnScrollDown) {
    // TODO: to continue adding this functionality later if needed
    window.addEventListener('scroll', () => {
      console.log({
        offsetTop: toggleButton_elementRef.value.offsetTop,
        'toggleButton_elementRef.value': toggleButton_elementRef.value,
        'toggleButton_elementRef.value.$el': toggleButton_elementRef.value.$el,
      })
      // if (window.scrollY > 0) {
      //   toggleButton_elementRef.value.classList.add('sticky');
      // } else {
      //   toggleButton_elementRef.value.classList.remove('sticky');
      // }
    });
  }
})

const toggleButton_elementRef = ref();
</script>

<style lang="scss" scoped>

.collapse-button {
  cursor: pointer;
}


</style>