import useApi from "../api";



export async function getRoleCreateModel ( ) {
    const { get } = useApi();
    
    const { responseBody } = await get(`roles/create`, {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
  }
  export async function createRole(roleModel: any ) {
    const { post } = useApi();
    
    const { responseBody } = await post('api/roles', [roleModel], {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
  }
  export async function getRoleById(itemId:any){
    const {get} = useApi();
    
    const { responseBody } = await get('api/roles/'+itemId, {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
    
  }
  export async function updateRole(itemId:string, model : any ){
    const {put} = useApi();
    model.id = Number(itemId) ; 
    const { responseBody } = await put('api/roles/'+itemId,[model], {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
    
  }