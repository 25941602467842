<template>
  <Dialog>
    <template #expander="{ openModal }">
      <div @click="openModal">
        <slot name="expander"></slot>
      </div>
      <ButtonCustomized label="Exit" @click="openModal" secondary/>
    </template>
    <template #dialog.title>Warning</template>
    <template #dialog.content="{ closeModal, closeModalAfter }">
      <div class="form">
        <div class="confirmation-text">
          You have unsaved changes. If you close without saving your changes will be lost.
        </div>
        <div class="form-buttons">
          <ButtonCustomized label="Cancel" @click="closeModal" secondary />
          <ButtonCustomized label="Exit without saving" @click="onExit" />
          <ButtonCustomized label="Save and Exit" @click="onSave" />
        </div>
      </div> 
    </template>
  </Dialog>
</template>
  
<script lang="ts" setup>
import Dialog from '../../../components/Dialog/Dialog.vue';
import ButtonCustomized from "../../../components/form-fields/ButtonCustomized.vue";

const emit = defineEmits(['cancel', 'save', 'exit', 'fail'])

function onExit() {
  emit ('exit')  
}
async function onSave() {
    emit('save');
}

</script>
  
<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}
.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>