<template>
    <Dialog ref="addOrg">
        <template #expander="{ openModal }">
            <a @click="openModal" class="cursor-pointer create-link"> {{ btnName }}</a>

        </template>
        <template #dialog.title>
            Add Organization
        </template>
        <template #dialog.subtitle>
            {{ title }}
        </template>
        <template #dialog.content="{ closeModal, closeModalAfter }">
            <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
            <div>
                <StatusBar :errorMessage="errorMessage" />
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm">Name &#9733;</label>
                    <TextField v-model="values.name" class="basis-1/2" width="100%" />
                </div>
               
                <div class="form-buttons">
                    <ButtonCustomized label="Cancel" @click="closeModal()" secondary />
                    <ButtonCustomized label="Save" @click="submitForm()" />
                </div>
            </div>

        </template>
    </Dialog>
</template>
      
<script lang="ts" setup>
import Dialog from '@/components/Dialog/Dialog.vue';
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import { computed, onMounted, ref, watch } from 'vue';
import TextField from "@/components/form-fields/TextField.vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
import { getRegion } from '@/helpers/countries/countries';

const emit = defineEmits(['cancel', 'save', 'exit', 'fail'])
const props = defineProps({
    'title': {
        type: String,
        default: true
    },
    'btnName':{
        type :String
    }


})
const validationSchema = yup.object({
    name: yup.string().label('name').required(),
 

});

const { errors, handleSubmit, values, setFieldValue, isSubmitting } = useForm({ validationSchema });
const errorMessage = ref()
const addOrg = ref()

const submitForm = handleSubmit(onSave, handleSubmitInvalid);

async function handleSubmitInvalid() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if (Object.keys(errors.value).length) {
        Object.keys(errors.value).forEach((key) => {
            errorsAugmentedInSingleMessage += errors.value[key] + ". ";
        });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
}
async function onSave() {
    emit('save', values);
    addOrg.value.closeModal()
}

</script>
      
<style lang="scss" scoped>
.create-link:hover{
    text-decoration: underline;
}
.select {
  border: 1px solid #abadb3;
  height: 35px;
  padding: 2px 2px 1px 2px;
  outline: none;
  background: transparent;
  left: 0;
  font-size: 14px;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 175px !important;

  &:disabled {
    margin-right: auto;
    background-color: #e6e6e6;
    color: #999999;
  }
}
.button {
    cursor: pointer;
}

.delete-item-form {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
}

.form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
}

.disabled {
    pointer-events: none;
    background-color: #E6E6E6;
    color: #999999
}
</style>