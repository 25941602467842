<template>
    <div class="content-container">
        <div class="form-box">
            <div class="form-container">
                <div class="form-title">
                    <h1>Change Password</h1>
                </div>
                <template v-if="resetCode">
                    <StatusBar :errorMessage="user.passwordResetErrorMessage" :isLoading="isLoading"
                        loadingMessage="Resetting Password..." />
                    <p>
                        Welcome, {{ username }}, please use this form to change your password. New passwords are
                        required
                        to
                        be a between 6 and 32 characters in length
                    </p>
                    <input class="text" data-val="true" data-val-required="The Password field is required."
                        id="password" name="New Password" placeholder="New Password" type="password"
                        v-model.lazy="password" />
                    <div v-if="passwordError" class="error">{{ passwordError }}</div>
                    <input class="text" data-val="true" data-val-required="The Password field is required."
                        id="confirmPassword" name="Confirm Password" placeholder="Confirm Password" type="password"
                        v-model.lazy="confirmPassword" />
                    <div v-if="confirmPasswordErrorMsg" class="error">{{ confirmPasswordErrorMsg }}</div>
                    <div class="submit-btn-container">
                        <a @click="handleSubmitNewPassword" class="submit-btn">Submit</a>
                    </div>
                </template>
                <template v-if="!isResendSuccess && isResetSuccess">
                    <div>
                        <p>
                            Your password has been changed successfully.
                        </p>
                        <div class="submit-btn-container">
                            <a @click="router.push('/')" class="submit-btn">Login</a>
                        </div>
                    </div>
                </template>
                <template v-if="isResendSuccess && !isResetSuccess">
                    <div>
                        <p>
                            An email has been sent to {{ userRequestingResetPassword }}. Please follow the instructions in the email
                            to reset your password.
                        </p>
                        <div class="submit-btn-container">
                            <a @click="router.push('/')" class="submit-btn">Back</a>
                        </div>
                    </div>
                </template>
                <template v-if="!isResendSuccess && !resetCode && !isResetSuccess">
                    <div>
                        <StatusBar :errorMessage="user.passwordResetErrorMessage" :isLoading="isLoading"
                            loadingMessage="Sending ResetPasswordEmail..." />
                        <p>Enter your username in the field below to submit a change password request.</p>
                        <input class="text" data-val="true" data-val-required="The Password field is required."
                            id="username" name="UserName" placeholder="UserName" type="email" v-model.lazy="username" />
                        <div v-if="usernameError" class="error">{{ usernameError }}</div>

                        <div class="submit-btn-container">
                            <a @click="router.back()" class="cancel-btn">Cancel</a>
                            <a @click="handleResendResetPasswordEmail" class="submit-btn">Submit</a>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import router from '@/router';
import StatusBar from '@/components/form-fields/StatusBar/StatusBar.vue';
import { useRoute } from 'vue-router';
import { sendPasswordResetEmail, submitNewPassword, getPasswordResetData } from '@/helpers/api/user';
import { onBeforeMount } from 'vue';
import useUserStore from '@/stores/user';
const user = useUserStore();

const route = useRoute();

const password = ref("");
const confirmPassword = ref("");

const isResendSuccess = computed(() => route.query.isResendSuccess === "true");
const isResetSuccess = computed(() => route.query.isResetSuccess === "true");
const userRequestingResetPassword = computed(() => route.query.userRequestingResetPassword);
const resetCode = computed(() => route.query.ResetCode);
const username = ref(userRequestingResetPassword.value ? String(userRequestingResetPassword.value) : "");
const userModel = ref({})
const isLoading = ref(false);

const usernameError = computed(() => {
    if (username.value === '') {
        return 'The Username field is required.';
    }
    // if not email 
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username.value)) {
        return 'The Username field must be a valid email address.';
    }
    return '';
});

const isStartedPasswordValidation = ref(false);
const passwordError = computed(() => {
    if (!isStartedPasswordValidation.value) {
        return '';
    }
    if (password.value === '') {
        return 'The Password field is required.';
    }
    if (password.value.length < 6) {
        return 'The Password field must be at least 6 characters.';
    }
    if (password.value.length > 32) {
        return 'The Password field must be at most 32 characters.';
    }
    return '';
});

const confirmPasswordErrorMsg = computed(() => {
    if (!isStartedPasswordValidation.value) {
        return '';
    }
    if (confirmPassword.value === '') {
        return 'The Confirm Password field is required.';
    }
    if (confirmPassword.value !== password.value) {
        return 'The Confirm Password field must match the Password field.';
    }
    return '';
});

async function handleSubmitNewPassword() {
    try {
        isStartedPasswordValidation.value = true;
        if (password.value && confirmPassword.value) {
            isLoading.value = true;
            await submitNewPassword({
                newPassword: password.value,
                confirmPassword: confirmPassword.value,
                resetCode: String(resetCode.value),
                user: userModel.value

            });
            router.push({ name: 'reset-password', query: { isResetSuccess: "true" } });
            isLoading.value = false;
            isStartedPasswordValidation.value = false;

        }
    } catch (error: any) {
        isLoading.value = false;
        console.error('handleResetPassword', error);
        user.passwordResetErrorMessage = error?.message || "";
    }

}

onBeforeMount(async () => {
    if (resetCode.value) {
        const res = await getPasswordResetData(String(resetCode.value))
        username.value = res?.user?.username;
        userModel.value = res?.user;
    }
})

async function handleResendResetPasswordEmail() {
    try {
        if (!usernameError.value) {
            isLoading.value = true;
            await sendPasswordResetEmail(username.value);
            router.push({ name: 'reset-password', query: { userRequestingResetPassword: username.value, isResendSuccess: "true" } });
            isLoading.value = false;
        }
    } catch (error: any) {
        isLoading.value = false;
        console.error('handleSendPasswordResetEmail', error);
        router.push({ name: 'reset-password', query: { userRequestingResetPassword: username.value, isResendSuccess: "false"} });
        user.passwordResetErrorMessage = error?.message || "";
    }
}

</script>

<style lang="scss" scoped>
.content-container {
    display: flex;
    justify-content: center;
}

.form-box {
    width: 460px;
    background-color: #ffffff;
    box-shadow: 0px 3px 17px rgb(0 0 0 / 16%);
    -webkit-box-shadow: 0px 3px 17px rgb(0 0 0 / 16%);
    list-style-type: none;
    border-radius: 6px;
}

.form-title h1 {
    font-size: 40px;
    font-weight: 400;
}

.form-container {
    padding: 30px;
}

input {
    width: 100%;
    padding: 3% 5%;
    font-size: 16px;
    margin-top: 15px;
    border: 1px solid #abadb3;

    &:focus,
    &:focus-within,
    &:focus-visible {
        outline: none !important;
        box-shadow: 0 0 10px #719ECE;
    }

    border-radius: 0.4em;
}


.submit-btn {
    border: 0;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    background-color: #25294A;
    margin-bottom: 3px;
    font-weight: normal;
    text-decoration: none;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    padding: 8px;
    height: 2.5em;
}

.cancel-btn {
    border: 0;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    background-color: #93A5AE;
    margin-bottom: 3px;
    font-weight: normal;
    text-decoration: none;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    padding: 8px;
    height: 2.5em;
    margin-right: 0.5rem;
}

.error {
    color: red;
}

.icon {
    width: 1.6rem;
    margin: 0.5rem;
}

.submit-btn-container {
    margin: 1rem;
    display: flex;
    justify-content: flex-end;

}
</style>