const baseApiPaths = {
  'initialize': 'auditing',
  'search': 'assetsearch'
}

const actionsApiPaths = {
  'export': 'media/export',
  'copy': 'media/copy',
  'delete': 'media',
  'edit': 'media/edit'

  // 'export': 'auditing/export',
  // 'copy': 'api/auditing',
  // 'delete': 'api/auditing',
  // 'edit': 'auditing/edit'
}

const apiPathsConfig = {
  ...baseApiPaths,
  ...actionsApiPaths
}

export default apiPathsConfig;