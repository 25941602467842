export const navItems = [
  {
    name: "Dashboard",
    path: "/",
    areaAccessName: "Common"
      // TODO (May-2023): make sure that this is the correct areaAccessName for Dashboard
  },
  {
    name: "Media",
    path: "/media",
    areaAccessName: "MediaLibrary"
  },
  {
    name: "Playlists",
    path: "/playlists",
    areaAccessName: "Playlists"
  },
  {
    name: "Campaigns",
    path: "/campaigns",
    areaAccessName: "Campaigns"
  },
  {
    name: "Players",
    path: "/players",
    areaAccessName: "Players"
  },
  // TODO (jul-2023): enable again when POS is ready
  // {
  //   name: "POS Sources",
  //   path: "/pos-sources",
  //   areaAccessName: "Playlists"
  //     // TODO (May-2023): to add areaAccessName: "POS Sources" in backend to support correct permissions
  // },
  {
    name: "Settings",
    areaAccessName: "Administration",
    subItems: [
      {
        label: "Organizations",
        path: "/organizations"
      },
      {
        label: "Locations",
        path: "/locations"
      },
      {
        label: "Users",
        path: "/users"
      },
      {
        label: "Roles",
        path: "/roles"
      },
      {
        label: "Account",
        path: "/account"
      },
      {
        label: "Auditing",
        path: "/auditing",
        checkIsVisible: (user: any) => user.isAuditor()
      }
    ]
  }
];

export default navItems;