import useApi from '../api';
import { getKeyByValue } from '@/helpers/utils';
import AssetTypesMapping from '@/data/mappings/AssetTypes.map';

interface MediaCreationOptions {
  name: string,
  width: number,
  height: number,
  backgroundColor: string
  assetType: "slide" | "image" | "video"
}

export async function submitCreateMediaItem(mediaCreationOptions: MediaCreationOptions) {
  const { post } = useApi();

  const { assetType, ...mediaCreationRestOfOptions } = mediaCreationOptions;
  const assetTypeKey = getKeyByValue(AssetTypesMapping, assetType);


  const mediaCreationOptionsPrepared = [{
    assetType: assetTypeKey,
    ...mediaCreationRestOfOptions
  }];
  
  const { responseBody } = await post('api/assets', mediaCreationOptionsPrepared, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}