const baseApiPaths = {
  'initialize': 'users',
  'search': 'usersearch'
}

const actionsApiPaths = {
  'export': 'users/export',
  'delete': 'api/users',
  'edit': 'users/edit'
}

const apiPathsConfig = {
  ...baseApiPaths,
  ...actionsApiPaths
}

export default apiPathsConfig;