import filtersDisplayConfig from "./filtersDisplay.config";
import apiPathsConfig from './apiPaths.config';
import MediaTableListItem from "@/views/MediaListView/MediaLister/MediaListItem/MediaTableListItem.vue";

import availableActionsConfig from "./availableActions.config";
import availableListViewsConfig from "./availableListViews.config";

import AssetTypesMapping from '@/data/mappings/AssetTypes.map';

const resultsManipulationCallback = (resultItem) => {
  // Prepare assetType
  resultItem.assetType = AssetTypesMapping[resultItem.assetType];
}

const itemsListerConfig = {
  TableViewItem: MediaTableListItem,
  apiPathsConfig,
  resultsManipulationCallback,
  filtersDisplayConfig,
  availableActionsConfig,
  availableListViewsConfig,
}

export default itemsListerConfig;