<template>
  <div :class='["Rtable-cell", (span ? "Rtable-cell--"+span : "")]'>
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
export interface Props {
  span?: string;   // cell's column spans
  breakpoint?: number | string   // responsive table collapse breakpoint
}

const props = withDefaults(defineProps<Props>(), {
  // span: "",
  breakpoint: "576px"
})

const { breakpoint } = props;
</script>

<style lang="scss" scoped>
$breakpoint: v-bind(breakpoint);
/* Alignment
==================================== */
.Rtable-cell--alignTop, 
.Rtable-cell--alignMiddle, 
.Rtable-cell--alignBottom { display: flex; }

.Rtable-cell--alignTop { align-items: flex-start; }
.Rtable-cell--alignMiddle { align-items: center; }
.Rtable-cell--alignBottom { align-items: flex-end; }

.Rtable-cell--alignLeft { text-align: left; }
.Rtable-cell--alignCenter { text-align: center; } 
.Rtable-cell--alignRight { text-align: right; }


// Individual cell sizing
.Rtable-cell.Rtable-cell--1of1  { width: 100%; }
.Rtable-cell.Rtable-cell--5of6  { width: 83.33; }
.Rtable-cell.Rtable-cell--4of5  { width: 80%; }
.Rtable-cell.Rtable-cell--3of4  { width: 75%; }
.Rtable-cell.Rtable-cell--2of3  { width: 66.66%; }
.Rtable-cell.Rtable-cell--3of5  { width: 60%; }
.Rtable-cell.Rtable-cell--1of2  { width: 50%; }
.Rtable-cell.Rtable-cell--2of4  { width: 40%; }
.Rtable-cell.Rtable-cell--1of3  { width: 33.33%; }
.Rtable-cell.Rtable-cell--1of4  { width: 25%; }
.Rtable-cell.Rtable-cell--1of5  { width: 20%; }
.Rtable-cell.Rtable-cell--1of6  { width: 16.66%; }


/* .Rtable-cell--rowEnd {
  @media all and (max-width: $breakpoint) { margin-bottom: 1em; }
} */

/* .hiddenLarge {
  @media all and (min-width: $breakpoint) { 
    display: none; 
  }
} */
</style>