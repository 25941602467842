<template>
    <TransitionRoot :show="isVisible" @close="close">
        <Dialog as="div" class="fixed inset-0 z-[11] overflow-y-auto" @close="close">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-40 transition-opacity"></div>
            <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0 mt-[20%]">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95">
                    <DialogPanel
                        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <DialogTitle class="text-lg leading-6 font-medium text-gray-900">
                            Error
                        </DialogTitle>
                        <div class="mt-2">
                            <p>{{ errorMessage }}</p>
                        </div>
                        <div class="mt-4 flex justify-end">
                            <ButtonCustomized label="Close" @click="close" />
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle
} from '@headlessui/vue';
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";

// Props to manage visibility and error message
const props = defineProps({
    isVisible: Boolean,
    errorMessage: String
});

// Emit close event
const emit = defineEmits(['close']);

// Close the dialog by emitting the event
const close = () => {
    emit('close');
};
</script>

<style scoped>
</style>