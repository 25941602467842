// Defines filters display order and labels in UI

const filtersDisplayConfig = [
  {
    "name": "FILTER_CREATED",
    "label": "Date Created",
    "type": "collapsibleSelect",
    "isSeparatedAbove": true
  },
  {
    "name": "FILTER_MODIFIED",
    "label": "Date Modified",
    "type": "collapsibleSelect"
  }
]

export default filtersDisplayConfig;