import filtersDisplayConfig from "./filtersDisplay.config";
import apiPathsConfig from './apiPaths.config';
import CampaignsTableListItem from "../CampaignsListItem/CampaignsTableListItem.vue";
import availableActionsConfig from "./availableActions.config";
import availableListViewsConfig from "./availableListViews.config";
import listNamesConfig from "./listNames.config";

const itemsListerConfig = {
  TableViewItem: CampaignsTableListItem,
  apiPathsConfig,
  filtersDisplayConfig,
  availableActionsConfig,
  availableListViewsConfig,
  ...listNamesConfig
}

export default itemsListerConfig;