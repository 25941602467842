<template>
<vue-progress-bar></vue-progress-bar>
</template>

<script lang="ts" setup>
import useProgressBar from "./composables/progressBar"

const progressBar = useProgressBar();
</script>

<style lang="scss" scoped>

</style>