<template>
  <v-layer name="development" v-if="isDevModeOn">
    <v-text ref="developmentStatsTextShape" :config="devStatsTextConfig"  />
  </v-layer>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';
import baseElementsRefs from './baseElementsRefs';
import { useStageEvents } from './stageEvents';
const { developmentStatsTextShape } = baseElementsRefs;
const {
  isDevModeOn
} = useStageEvents();

const devStatsTextConfig = reactive({
  x: 10,
  y: 10,
  fontFamily: 'Calibri',
  fontSize: 20,
  text: 'Development Stats:',
  fill: 'red',
  name: 'development-stats',
  opacity: 0.6,
  draggable: false,
  listening: false
});



</script>

<style lang="scss" scoped>

</style>