<template>
<div
  :class='["flex items-center gap-x-3"]'
  @click.stop=""
>
  <div v-if="availableListViewsConfig?.grid" :class='["icon-container", {"active": listViewType==="grid"}]'>
    <OldThemeIcon name="grid" class="icon" @click="setView('grid')" />
  </div>
  <div :class='["icon-container", {"active": listViewType==="table"}]'>
    <OldThemeIcon name="list" class="icon" @click="setView('table')" />
  </div>
  <div class="icon-container">
    <ExportListButton />
  </div>
  <div class="icon-container">
    <!-- TODO [DONE]: to think about adding here a generic component (will be helpful later in other project parts) that transition between the "LoadingSpinner" & "RefreshListButton" smoothly and easily reusable — N2939k0k -->
    <content-with-loading-spinner :isLoading="isValidating || isLoading" loadingIconWidth="16px">
      <RefreshListButton />
    </content-with-loading-spinner>
  </div>
</div>
</template>

<script lang="ts" setup>
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import RefreshListButton from './Buttons/RefreshListButton.vue';
import ExportListButton from './Buttons/ExportListButton.vue';
import { onMounted, ref } from 'vue';
import useIsMobile from '@/composables/utils/isMobile';
import { inject } from "vue";
import { log } from '@/helpers/utils';

const itemsListHelpers = inject('itemsListHelpers');

const {
  listViewType,
  isValidating,
  isLoading
} = itemsListHelpers;

const isMobile = useIsMobile();

const isViewTypeSetBefore = ref(false);

const listerConfig = inject('listerConfig', {});

const {
  itemPickDragMode,
  singleSelect,
  availableListViewsConfig
} = listerConfig;

// log({availableListViewsConfig}, {ns: 'ListTopRightBar', color: 'red'})


if(!isViewTypeSetBefore.value && isMobile.value && !itemPickDragMode && !singleSelect && availableListViewsConfig?.grid) {
  listViewType.value = "grid";
}

function setView(viewType: "grid" | "table") {
  if(!isViewTypeSetBefore.value) {
    isViewTypeSetBefore.value = true;
  }
  listViewType.value = viewType;
}
</script>

<style lang="scss" scoped>
.icon-container {
  padding: 0.375rem;
  border-radius: 0.375rem;
  cursor: pointer;
  &.active {
    background-color: #B1C4CF;
    cursor: default;
  }
}
</style>