<template>
  <Menu
    v-if="user.isLoggedIn"
    :items="items"
  >
    <template #expander>
      <div class="username"></div>{{user.fullName}}
      <CaretDownIcon class="down-icon" />
    </template>
  </Menu>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue';

import useUser from '@/composables/user';
import CaretDownIcon from '@/components/icons/CaretDownIcon.vue';
import Menu from '@/components/Menu.vue';

const user = useUser();
const items = reactive([
  {
    label: "My Account",
    path: computed(() =>`/users/edit/${user.userId}`)
  },
  {
    label: "Logout",
    event: {
      name: 'click',
      callback: user.logout
    }
  }
]);

</script>

<style scoped lang="scss">
.user-bar {
  /* @include xx-large-devices-down {
    margin-right: 1em;
  }
  
  @include medium-devices-down {
    margin-right: 2em;
  } */
}
</style>