
<template>
<div class="skeleton-container" ref="skeletonContainer">
  <div class="loading-message" v-if="loadingMessage || $slots.default">
    <template v-if="loadingMessage">
      {{ loadingMessage }}
    </template>
    <slot v-else>
    </slot>
  </div>
  <ContentLoadingSkeleton
    v-if="containerRef"
    :speed="2"
    :animate="true"
    ref="contentLoadingSkeleton"
    :viewBox="'0 0 '+(width || containerRef?.clientWidth || 100)+' '+(height || containerRef?.clientHeight || 30)"
    preserveAspectRatio=""
    primaryColor="#f3f3f3"
    secondaryColor="#e8f0fe"
    class="skeleton"
  >
  </ContentLoadingSkeleton>
  <slot name="loading" />
</div>

</template>

<script lang="ts" setup>

import { ref, computed, onMounted, watch, watchEffect, onBeforeUnmount, reactive } from 'vue';
import { ContentLoader as ContentLoadingSkeleton } from 'vue-content-loader';
// using the word "loading skeleton" is preferred over "loader" because loader
// may suggest from the first sight the component loads something while it's not true.

const contentLoadingSkeleton = ref<HTMLDivElement>();
const { containerRef } = defineProps(['containerRef', 'width', 'height', 'loadingMessage']);

const skeletonContainer = ref<HTMLDivElement>();

const containerDimensions = reactive({
  width: 0,
  height: 0
})

onMounted(() => {
  if(skeletonContainer?.value) {
    skeletonContainer.value.addEventListener('resize', updateContainerDimensions)
      // TODO: check if this event is actually working because it seems resize is not a valid event
  }
});

onBeforeUnmount(() => {
  if(skeletonContainer?.value) {
    skeletonContainer.value.removeEventListener('resize', updateContainerDimensions)
      // TODO: check if this event is actually working because it seems resize is not a valid event
  }
});

function updateContainerDimensions() {
  if(skeletonContainer?.value) {
    containerDimensions.height = skeletonContainer.value.clientHeight;
    containerDimensions.width = skeletonContainer.value.clientWidth;
  }
}

</script>

<style lang="scss" scoped>
.skeleton {
    width: 100%;
    height: 100%;
    border-radius: 0.375rem;
  }
.skeleton-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.loading-message {
  position: absolute;
  margin-left: 1em;
}
</style>