<template>
  <div class="format-pane-container">
    <template v-if="selectedSprite">
      <LockObjectForCopiesToggleField />
      <ImageSpriteFormatFields v-if="selectedSprite.type === 'image'" />
      <TextSpriteFormatFields v-if="selectedSprite.type === 'text'" />
    </template>
    <SlideBackgroundFormatFields v-else-if="isNoSpritesSelected" />
    
  </div>
</template>
  
<script lang="ts" setup>
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import ImageSpriteFormatFields from './ImageSpriteFormatFields/ImageSpriteFormatFields.vue';
import TextSpriteFormatFields from './TextSpriteFormatFields/TextSpriteFormatFields.vue';
import SlideBackgroundFormatFields from './SlideBackgroundFormatFields.vue';
import LockObjectForCopiesToggleField from './LockObjectForCopiesToggleField.vue';
const {isNoSpritesSelected, selectedSprite} = useSlide();
</script>

<style lang="scss" scoped>
.format-pane-container {
  padding: 1rem;
}
</style>