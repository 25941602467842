import useApi from "../api";

export async function submitDeleteLocationItem(itemId: string) {
    const { deleteRequest } = useApi();
    
    const { responseBody } = await deleteRequest('api/locations/'+itemId, {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
  }
  export async function updateLocation(itemId:string, model : any ){
    const {put} = useApi();
    model.id = Number(itemId) ; 
    const { responseBody } = await put('api/locations/'+itemId,[model], {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
    
  }
  export async function getUpdateLocationItem(itemId: string) {
    const { get } = useApi();
    const { responseBody } = await get('locations/edit/'+itemId, {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
  }
  
  export async function getLocationById(itemId:any){
    const {get} = useApi();
    
    const { responseBody } = await get('api/locations/'+itemId, {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
    
  }