import { reactive, ref, computed } from 'vue';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import handlers from './handlers';
import {isDevModeOn} from './handlers/devStageHandlers';
import { selectionRectangleProps } from './handlers/selectorRectangleStageHandlers';

// global (shared across components and composables) reactive states
// const slide = useSlide();


// states defined here are 
export function useStageEvents() {
  // Touch events are handled also handled along with Mouse events

  
  // [design decision note] related event handlers are collected together for easier reasoning

  return {
    selectionRectangleProps,
    isDevModeOn,
    ...handlers
  };
}

export default useStageEvents;
