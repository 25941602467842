<!-- TODO: think about naming "CopyItemConfirmationForm" "CopyItemOrItemsConfirmationForm" or a more briefer and still readable & descriptive name — N2FAnC89 -->
<template>
  <!-- TODO (cleaner code): think about removing redundancy between "CopyItemConfirmationForm" & other similar forms (e.g. DeleteItemConfirmationForm) [DSP-N26FRi1I] -->
  <div class="copy-items-form">
    <div class="confirmation-text">
      {{ isSingleItem ? 'Are you sure you want to copy this item?' : `Are you sure you want to copy these items (${lastSelectedItemsCount} selected)?` }}
    </div>
    <div class="status-bar-container">
      <StatusBar :errorMessage="errorMessage" :isLoading="/* true ||  */isLoading" loadingMessage="Copying..."
        :isSuccess="isSuccess" :successMessage="(isSingleItem ? '1 item copied!' : `${lastSelectedItemsCount} items copied!`)" />
    </div>
    <div class="form-buttons">
      <ButtonCustomized label="Cancel" @click="onCancel" secondary />
      <ButtonCustomized label="Copy" @click="onSubmit" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import useNotifications from "@/components/Notifications/composables/notifications";
import useCopyItemConfirmationFormHelpers from "./composables/copyItemConfirmationFormHelpers";
import router from '@/router';
import { computed, ref, watchEffect, inject } from "vue";
import { isDefined } from '@/helpers/utils';
  // TODO: think about if it's better to make this function global to be used without the need for import statement — N2JHw721
    // (also consider dependency tree-shaking optimization consequences)

const props = defineProps(['itemId', 'itemsIds'])
const emit = defineEmits(['submit', 'cancel', 'success', 'fail'])
const itemsListHelpers = inject('itemsListHelpers');

const notification = useNotifications();
const { submitCopyAction, isLoading, isSuccess, errorMessage, newSlideId, newSlidesIds } = useCopyItemConfirmationFormHelpers( itemsListHelpers );

const isSingleItem = computed(() => {     // false: means multiple items
  if(props.itemId || props.itemsIds.length === 1) {
    return true;
  } else if(props.itemsIds) {
    return false;
  }
});

const idOrIdsToSubmit = computed(() => {
  if(isSingleItem.value && !props.itemsIds) {
    return props.itemId;
  } else if (isSingleItem.value && props.itemsIds) {
    return props.itemsIds[0];
  } else {
    return props.itemsIds.join(',');
  }
});

// TODO: think about a briefer and still readable & descriptive name for "newSlideIdOrSlidesIds" — N2FAm98H
const newSlideIdOrSlidesIds = computed(() => {
  return (isSingleItem.value ? newSlideId.value : newSlidesIds.value);
});

// TODO: think about deduplicating "lastSelectedItemsCount" from here and in other similar places (e.g. DeleteItemConfirmationForm) — N2GKoFE6
const lastSelectedItemsCount = ref(0);
watchEffect(() => {
  if (isDefined(props.itemsIds) && props.itemsIds.length !== 0) {
    lastSelectedItemsCount.value = props.itemsIds.length;
  }
})

async function onSubmit() {
  await submitCopyAction(idOrIdsToSubmit.value);
  const successNotificationMessage = isSingleItem.value ? '1 item is copied successfuly!' : `${lastSelectedItemsCount.value} items are copied successfuly!`;
  if(isSuccess.value === true) {
    notification.success(successNotificationMessage);
    emit('success');
    router.push({name: 'media-editor', params: {id: newSlideIdOrSlidesIds.value}});
  } else {    // on failure
    emit('fail');
  }
}

function onCancel() {
  emit('cancel');
}

</script>

<style lang="scss" scoped>
.copy-items-form {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}
.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>