<template>
  <div class="header">
    <div class="header-content">
      <div class="top-bar">
        <RouterLink
          to="/"
          tag="div"
        >
          <img :src="assetsUrls.logo" class="logo" alt="bitSHUTTLE Logo">
        </RouterLink>
        <SideBars />
      </div>
      <Navbar />
    </div>
    
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useImportedPaths, useImportedPathsAsCssUrls } from '@/composables/utils/importedPaths';

import Navbar from './Navbar';
import SideBars from './SideBars';

// imported assets
import headerBackgroundImageUrl from "./assets/header-background.png";
import logoImageUrl from "@/assets/images/bitshuttle.png";

const assetsUrls = useImportedPaths({
    logo: logoImageUrl
});

const assetsCssUrls = useImportedPathsAsCssUrls({
    headerBackground: headerBackgroundImageUrl,
});

</script>

<style scoped lang="scss">
.header {
  box-shadow: 0px 3px 17px rgb(0 0 0 / 16%);
  display: flex;
  justify-content: center;
  margin-bottom: 29px;
  z-index: 5;
  @include medium-devices-down {
    position: fixed;
  }

  @include theme-old {
    background-image: v-bind("assetsCssUrls.headerBackground");
    background-repeat: repeat-x;
  }
}
.header-content {
  width: 100%;
  @include theme-old {
    width: 100vw;
    max-width: 1200px;
    /* @include xx-large-devices-down {
      width: 100vw;
    } */
  }
  
  @include xx-large-devices-down {
    width: 100vw;
  }
}
.logo {
  height: 16px;
  margin-top: 12px;
  
  @include xx-large-devices-down {
    @include theme-old {
      margin-left: 1em;
    }
  }

  @include medium-devices-down {
    position: absolute;
    top: 0;
    z-index: 998;
  }
}

@media only screen and (max-width: 1300px) {
  .logo {
    margin-right: 20px;
  }
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding: 0 1em;
  @include x-large-devices-up {
    padding: 0 5%;
  }

  height: 2.4em;
  background: linear-gradient(45deg, #1b4187, #05635e);

  @include theme-old {
    height: 2em;
    padding: initial;
    margin-bottom: 10px;
    background: none;
  }
}

.side-bars {
  display: flex;
  column-gap: 1em;
}
.nav-bar {
  
}
</style>
