import { slideMainLayerNode } from '@/views/MediaEditorView/SlideEditor/Stage/baseElementsRefs';

import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';

export function getSpriteStoreById(id) {    // quickly added to think if this is the best place for it
  const { sprites } = useSlide();
  
  const sprite = sprites.find((s) => s.id === id);
  return sprite;
}


export function isNodeASlideSprite(node) {
  const layerNameOfClickedShape = node.getLayer().name();
  return layerNameOfClickedShape == 'slide-main';
}


export function getSpriteIdByShapeNode(shapeNode) {    // quickly added to think if this is the best place for it
  return shapeNode.id();
}

function getSpriteStoreByShapeNode(shapeNode) {    // quickly added to think if this is the best place for it
  // description: helpful for setting shapes properties
  const spriteId = getSpriteIdByShapeNode(shapeNode);
  const spriteStore = getSpriteStoreById(spriteId);
  return spriteStore;
}

export function getSpriteShapeNodeById(id) {    // quickly added to think if this is the best place for it
  const shapeNode = slideMainLayerNode.value.findOne('#' + id);
  return shapeNode;
}

