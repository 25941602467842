<template>
<Teleport :to='to || "body"'>
  <div class="notifications-container">
    <TransitionGroup name="notification-box" appear>
      <!-- TODO (cleaner code, very low priority): think if there's a cleaner way to handle assigning classes based on "notification type" [DSP-N26EZW1F] -->
      <div
        :class='[
          "notification-box",
          {
            "normal": notification.type === "normal",
            "success": notification.type === "success",
            "failure": notification.type === "failure",
            "deletion": notification.type === "deletion"
          },
        ]'
        v-for="notification in notifications"
        :key="notification.id"
        @click="removeNotification(notification.id)"
      >
        <InfoIcon v-if='notification.type === "normal"' class="icon" />
        <SuccessIcon v-else-if='notification.type === "success"' class="icon" />
        <WarningIcon v-else-if='notification.type === "failure"' class="icon" />
        <TrashIcon v-else-if='notification.type === "deletion"' class="icon" />
        <div class="notification-message">{{ notification.message }}</div>
      </div>
    </TransitionGroup>
  </div>
</Teleport>
</template>

<script lang="ts" setup>
import { Teleport } from 'vue';
import useNotifications from './composables/notifications';
import SuccessIcon from "@/components/icons/SuccessIcon.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import WarningIcon from "@/components/icons/WarningIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";

defineProps(['to'])
const { notifications, removeNotification } = useNotifications();
</script>

<style>
.notification-box-enter-from,
.notification-box-leave-to {
  opacity: 0;
  transform: scale(0.8) translateX(10rem);
}
</style>

<style lang="scss" scoped>
.notifications-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;
}

.notification-box {
  &.normal {
    background-color: #dfe9f2;
    color: #28445d;
    @include theme-old {
      background-color: #dfe9f2;
      color: #28445d;
    }
  }
  &.success {
    background-color: #dfe9f2;
    color: #28445d;
    @include theme-old {
      background-color: #dfe9f2;
      color: #28445d;
    }
  }
  &.failure, &.deletion {
    background-color: #f2dfdf;
    color: #ce5a5a;
    @include theme-old {
      background-color: #f2dfdf;
      color: #ce5a5a;
    }
  }
  
  display: flex;
  align-items: center;
  gap: 0.375rem;
  /* width: 20rem;
  height: 6rem; */
  max-width: 22rem;
  border-radius: 0.850rem;
  padding: 0.850rem;
  margin-top: 0.850rem;
  transition: all 0.5 ease;
  opacity: 1;
  transform: scale(1) translateX(0);
}

.icon {
  min-width: 1rem;
  align-self: flex-start;
}
</style>