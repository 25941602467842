import { log } from '@/helpers/utils';
import useApi, { getModelFromViewCode } from '../api';


export async function fetchCampaignList(listRequestOptions: any ) {
  const { post } = useApi();
  
  const { responseBody } = await post('campaignsearch', listRequestOptions, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function submitCreateCampaignItem(options: any ) {
  const { post } = useApi();
  const { responseBody } = await post('api/campaigns', [options], {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}

export async function submitDeleteCampaignItem(itemId: string) {
  const { deleteRequest } = useApi();
  
  const { responseBody } = await deleteRequest('api/campaigns/'+itemId, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function getCampaignById(itemId:any){
  const {get} = useApi();
  
  const { responseBody } = await get('api/campaigns/'+itemId, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
  
}
export async function updateCampaign(itemId:string, model : any ){
  const {put} = useApi();
  model.id = Number(itemId) ; 
  const { responseBody } = await put('api/campaigns/'+itemId,[model], {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
  
}
export async function submitExportCampaignList(listRequestOptions: any ) {
  const { postUrlEncoded } = useApi();
  
  const { responseBody, response } = await postUrlEncoded(
    'campaigns/export', 
    { data: JSON.stringify(listRequestOptions) },
    {
      isLogoutOnInvalidAuth: true,
      config: {
        headers: {
          'Content-Type': 'Content-Type: text/csv',
        }
      }
    }
  );
  const responseData = responseBody;
  const contentDispositionHeader = response.headers.get("content-disposition");
  const fileName = contentDispositionHeader.match(/filename=(.+)/)[1];
  return { responseData, fileName, response };
}
