import { nextTick, reactive, computed, watch, ref } from 'vue';
import _pick from "lodash/pick";

import type { AssetEntityRetrieved } from '../../composables/mediaItem';
import type { Sprite, Sprite_ForAPISubmission } from "@/helpers/types/media/slide/Sprite";
import SpriteTypesMapping from '@/data/mappings/SpriteTypes.map';
import { 
  clearSpritesSelection,
  addSpriteToSelection,
  removeSpriteFromSelection,
  isSpriteSelected
} from '@/views/MediaEditorView/SlideEditor/utils/spritesSelection';

import { toggleSpriteVisibility } from '@/views/MediaEditorView/SlideEditor/utils/spritesVisibility';
import { removeSprite } from '@/views/MediaEditorView/SlideEditor/utils/spritesCRUD/removeSprite';
import { getSpriteStoreById } from '@/views/MediaEditorView/SlideEditor/utils';
import { log, getKeyByValue } from '@/helpers/utils';

import AssetTypesMapping from '@/data/mappings/AssetTypes.map';
import submitUpdateSlide from '@/helpers/api/media/updateSlide';
import { useHasUnsavedChangesHelpers } from "@/composables/utils/hasUnsavedChangesHelpers";
import { prepareSpriteFromRetrieved } from "./helpers/prepareSpriteFromRetrieved";
import { loadImages } from "./helpers/spritesImageLoading";

import {
  toggleActivateDrawNewTextSprite,
  toggleActivateDrawNewImageSprite,
  activeDrawType
} from "./helpers/drawNewSprite";

import {
  addNewImageSpriteFromMediaItem,
  changeImageOfImageSprite,
  removeImageFromImageSprite
} from "./helpers/imageSpriteOperations";

import {
  fontsFamilies,
  initializeFonts,
  updateSelectedSpriteFontFamilyWithStyleName,
  getFontId
} from "./helpers/fonts";

import {
  history,
  historyStep,
  isPauseHistoryWatcher,
  undo,
  redo,
  initializeHistoryWatcher,
  clearHistory,
  addHistorySnapshot,
  resumeHistory,
} from "./helpers/history";

export const sprites = reactive([] as Sprite[]);
export const spritesReversed = reactive([] as Sprite[]);
export const selectedSpritesIds = reactive([] as string[]);
const selectedSpritesStores = reactive([] as Sprite[]);
export const selectedTextSpriteField = ref<HTMLInputElement | null>(null);

export const basicInfo = reactive({
  id: null,
  name: "",
  width: 0,
  height: 0,
  backgroundColor: "",
  approvalState: null,
  sharingLevel: null,
  tags :[ ]
});

// TODO [Canceled]: to check later why computed isn't reactive "selectedSpritesStores" — N2K75q7V
  // (canceled because this task referred to code in previous commit that's updated in this commit)
  // (to be removed in next commit) while keeping some notes for future
  // In the previous commit, the problem seems in an issue with Vue's reactivity system itself (more details: https://stackoverflow.com/questions/75347512/vue-computed-not-triggered-on-reactive-map, https://stackoverflow.com/a/75347976/3503851)

const isShowEditableObjects = ref(false);

// single Selected Sprite related - - - - - - - 
const isSingleSpriteSelected = computed(() => (selectedSpritesIds.length === 1));
const singleSelectedSprite = computed(() => {
  if (isSingleSpriteSelected.value) {
    const foundSingleSelectedSprite = sprites.find((sprite) => (sprite.id === selectedSpritesIds[0]));
    return foundSingleSelectedSprite;
  } else {
    return null;
  }
});
export const selectedSprite = singleSelectedSprite;
const isNoSpritesSelected = computed(() => (selectedSpritesIds.length === 0));
// - - - - - - - 

export function clearSprites() {
  sprites.splice(0,sprites.length);
  spritesReversed.splice(0, spritesReversed.length);
}

export default function useSlide() {
  const {
    unsetHasUnsavedChanges,
    initializeHasUnsavedChangesWatcher,
    isLoading,
    hasUnsavedChanges,
    isDataInitialized
  } = useHasUnsavedChangesHelpers({
    statesToWatchForChanges: [basicInfo, spritesReversed]
  });

  function updateSpritesReversed() {
    spritesReversed.splice(0, spritesReversed.length);
    spritesReversed.push(...sprites.slice().reverse());
  }

  function initializeSlideEditorHelperWatchers() {
    // Helper experiment to reach a solution for "reverse order (DSP-587--or--N2GBBs33)":
      // https://stackblitz.com/edit/vitejs-vite-u9lfvh?file=src%2FApp.vue,src%2Fcomponents%2FSomeComponentCaseA5.vue
    watch(spritesReversed, () => {
      sprites.splice(0, sprites.length);
      sprites.push(...spritesReversed.slice().reverse());
    });
    
    
    watch(selectedSpritesIds, () => {
      const mapped = selectedSpritesIds.map( (selectedSpriteId) => {
        return reactive(getSpriteStoreById(selectedSpriteId) as Sprite);
      });
      selectedSpritesStores.splice(0, selectedSpritesStores.length);
      selectedSpritesStores.push(...mapped);
    });

    initializeHasUnsavedChangesWatcher();
    initializeHistoryWatcher(isDataInitialized);
  }

  async function initializeRetrievedData(assetEntityRetrieved: AssetEntityRetrieved) {
    isLoading.value = true;
    isDataInitialized.value = false;
    clearSprites();

    const pickedBasicInfo = _pick(assetEntityRetrieved, Object.keys(basicInfo));
    Object.assign(basicInfo, pickedBasicInfo);
    log({basicInfo});   // to delete after refactoring — N3MH2YF2
    
    const spritesRetrieved = assetEntityRetrieved.sprites;
    // console.log({spritesRetrieved: AssetEntityRetrieved.sprites})
    spritesRetrieved.forEach(spriteRetrieved => {
      // debug/dev temporary
        // if(spriteRetrieved.spriteType === 1) {return};     // temporary development line to be removed
      const spritePrepared = prepareSpriteFromRetrieved(spriteRetrieved);
      
      sprites.push(spritePrepared);
      // debug/dev temporary
        // push mock sprites to current     // dev temporary to remove later
          // sprites.push(...sprites_mockData)
      loadImages()
      // sprites.push(spriteRetrieved);
      updateSpritesReversed();
    });
    
    clearHistory();
    addHistorySnapshot();
    resumeHistory();
    await nextTick();
    
    // TODO: check why "setTimeout" is needed to make "isLoading" and "isDataInitialized" work well with "hasUnsavedChanges"
    setTimeout(() => {
      isLoading.value = false;
      isDataInitialized.value = true;
    }, 1000);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  // saving slide related
  async function save() {
    const assetTypeKey = getKeyByValue(AssetTypesMapping, 'slide');

    // TODO: think if it's better to separate the sprites preparation for api submission into a separate function — N2LAsHEe
    const spritesPrepared = sprites.map((sprite) => {
      let spritePreparedForSubmission: Sprite_ForAPISubmission;
      if ( sprite.type == 'text' ) {
        // TODO: think if there's a cleaner way to write this (may be making use of some destructuring / assigning features) — N2LAf77s
        // prepare text sprite for submission
        spritePreparedForSubmission = {
          x: sprite.x,
          y: sprite.y,
          height: sprite.height,
          width: sprite.width,
          text: sprite.text,
          horizontalAlign: sprite.align,
          fontSize: sprite.fontSize,
          fontColor: /* sprite.fill */ parseInt(sprite.fill.replace("#",""), 16),
          font: {
            id: getFontId({
              name: sprite.fontFamilyWithStyleName,
              isBold: sprite.fontStyle.includes('bold'),
              isItalic: sprite.fontStyle.includes('italic'),
            }),
            name: sprite.fontFamily
          },
          fontFamily: sprite.fontFamilyWithStyleName,
          isItalic: sprite.fontStyle.includes('italic'),
          isBold: sprite.fontStyle.includes('bold'),
          spriteType: parseInt(getKeyByValue(SpriteTypesMapping, 'text')),
          isLockedByOriginalOwner: sprite.isLockedByOriginalOwner,
        }
        log({spritePreparedForSubmission});
      } else if (sprite.type == 'image') {
        // prepare image sprite for submission
        spritePreparedForSubmission = {
          x: sprite.x,
          y: sprite.y,
          height: sprite.height,
          width: sprite.width,
          imageX: sprite.imageX,
          imageY: sprite.imageY,
          imageWidth: sprite.imageWidth,
          imageHeight: sprite.imageHeight,
          ...(
            sprite.src ?
              {
                asset: {
                  id: sprite.sourceAssetId
                }
              } :
              null
          ),
          spriteType: parseInt(getKeyByValue(SpriteTypesMapping, 'image')),
          isLockedByOriginalOwner: sprite.isLockedByOriginalOwner,
        }
      }
      return spritePreparedForSubmission;
    });
    

    const slidePreparedForSubmission = {
      assetType: assetTypeKey,
      sprites: spritesPrepared,
      ...basicInfo
      // TODO (important): make sure that "slide approval" & other slide fields are properly handled / submitted — N2LDxmF6
    }
    
    await submitUpdateSlide(slidePreparedForSubmission);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 

  return {
    basicInfo,
    sprites,
    spritesReversed,
    
    initializeRetrievedData,
    
    // sprites operations related
    toggleSpriteVisibility,
    removeSprite,

    // sprites selection related
    clearSpritesSelection,
    addSpriteToSelection,
    removeSpriteFromSelection,
    selectedSpritesIds,
    isSpriteSelected,
    selectedSpritesStores,

    // single Selected sprite related
    selectedSprite,
    isNoSpritesSelected,
    selectedTextSpriteField,

    // draw new sprite related
    toggleActivateDrawNewTextSprite,
    toggleActivateDrawNewImageSprite,
    activeDrawType,

    // image sprite operations related
    addNewImageSpriteFromMediaItem,
    changeImageOfImageSprite,
    removeImageFromImageSprite,
    
    // Fonts related
    fontsFamilies,
    initializeFonts,
    updateSelectedSpriteFontFamilyWithStyleName,
    
    // Slide saving related
    save,
    hasUnsavedChanges,
    unsetHasUnsavedChanges,
    initializeSlideEditorHelperWatchers,

    // "Show editable object" & "Is locked For copies" related
    isShowEditableObjects,

    // History related
    history,
    historyStep,
    isPauseHistoryWatcher,
    undo,
    redo,
    addHistorySnapshot
  }
}