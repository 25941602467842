import { log } from '@/helpers/utils';
import useApi, { getModelFromViewCode } from '../api';

export async function fetchMediaItemById(id: number|string) {
  const {get} = useApi();
  try {
    // TODO: - think about migrating media item fetching to ‘api/assets/:itemID [DSP-N1PEDT3k]
      // - It’s working on the POS api shared by Rami
    
    const { responseBody } = await get(`media/edit/${id}`, {isLogoutOnInvalidAuth: true});
    const responseData = getModelFromViewCode(responseBody);
    log(responseData, {ns: 'fetchMediaItemById', color: 'brown'});
    return responseData;
  } catch(err) {
    throw err;
  }
}