<template>
  <div class="create-slide-form">
    <h1 class="title separetor">CREATE USER </h1>
    <div class="status-bar-container">
      <StatusBar :errorMessage="errorMessage" :isLoading="/* true ||  */isLoading" loadingMessage="Saving..."
        :isSuccess="isSuccess" successMessage="Saved!" />
    </div>
    <div class="create-user">
      <div class="form-fields md:basis-1/2">
        <!-- TODO: think about adding below-field error message & error state indication (e.g. red border) [DSP-N26CxI4P] -->
        <div class="formGroup">
          <label for="name">First Name &#9733;</label>
          <TextField v-model="values.firstName" width="100%" />
        </div>
        <div class="formGroup">
          <label for="name">Last Name &#9733;</label>
          <TextField v-model="values.lastName" width="100%" />
        </div>
        <div class="formGroup">
          <label for="name">Email Address &#9733;</label>
          <TextField v-model="values.username" width="100%" />
        </div>
        <div class="formGroup">
          <label for="name">Phone </label>
          <TextField v-model="values.phone" width="100%" />
        </div>
        <div class="formGroup">
          <label for="name">Address</label>
          <TextField v-model="values.address1" width="100%" />
        </div>
        <div class="formGroup">
          <label for="name"></label>
          <TextField v-model="values.address2" width="100%" />
        </div>
        <div class="formGroup">
          <label for="name">Region </label>
          <div class="styled-select select">
          <select name="cars" id="cars" v-model="values.region">
            <option :value="region" v-for="region of  regions">
              {{ region.name }}
            </option>
          </select>
          <OldThemeIcon name="bullet-down" class="icon" />

        </div>
        </div>
        <div class="formGroup">
          <label for="name">Country</label>
          <div class="styled-select select">

          <select name="cars" id="cars" v-model="values.country" :disabled="values?.region?.countries?.length>0? false : true">
            <option :value="country" v-for="country of  values.region.countries ">
              {{ country.name }}
            </option>
          </select>
          <OldThemeIcon name="bullet-down" class="icon" />
</div>
        </div>
        <div class="formGroup">
          <label for="name">State</label>
          <div class="styled-select select">

          <select name="cars" id="cars"  v-model="values.state" :disabled="values?.country?.states?.length>0? false : true">
             <option :value="state" v-for="state of  values.country.states">
              {{ state.name }}
            </option> 
          </select>
          <OldThemeIcon name="bullet-down" class="icon" />
</div>
        </div>
        <div class="formGroup">
          <label for="name">City</label>
          <TextField v-model="values.city" width="100%" />
        </div>
        <div class="formGroup">
          <label for="name">Postal Code</label>
          <TextField v-model="values.zip" width="100%" />
        </div>
      </div>
      <div class="form-fields md:basis-1/2">
        <!-- TODO: think about adding below-field error message & error state indication (e.g. red border) [DSP-N26CxI4P] -->
        <div class="formGroup">
          <label for="name">Organization &#9733;</label>
        
          <SelectOrganization :btnTitle="values.group?.name ? values.group?.name :'Select Group'" :showBtn=false @save="addOrgainzation($event)"></SelectOrganization>
        </div>
        <div class="formGroup">
          <label for="name">Roles </label>
          <select name="cars" id="cars" class="select roles-select" v-model="values.roles" multiple="true">
            <option :value="role" v-for="role of  roles">
              {{ role.name }}
            </option>
          </select>
        </div>
        <div class="formGroup items-start">
          <label for="name">Flags </label>
          <div>
            <input type="checkbox" class="mx-1" id="Auditor" v-model="values.auditorFlag" >
          <label for="Auditor">Asset Auditor</label><br>
          <input type="checkbox"  class="mx-1" id="Admin" v-model="values.accountFlag" >
          <label for="Admin">Account Admin</label><br>
          <input type="checkbox"  class="mx-1" id="User" v-model="values.superUserFlag" >
          <label for="User"> Super User</label><br>
          <input type="checkbox"  class="mx-1" id="Analytics" v-model="values.analyticsFlag">
          <label for="Analytics"> Analytics</label><br><br>
          </div>
      
        </div>
      </div>
    </div>

    <div class="form-buttons">
      <ButtonCustomized label="Exit" v-if="!hasChanges" @click="onExit" secondary />
      <BeforeExitDialogue v-if="hasChanges" @save="submitAndExit" @exit="onExit"> </BeforeExitDialogue>
      <ButtonCustomized label="Save" @click="onSubmit" :disabled="!hasChanges" />
      <Saving ref="savingDialog"></Saving>
    </div>
  </div>
</template>
  
<script lang="ts" setup>
import TextField from "@/components/form-fields/TextField.vue";
import useCreateUserForm from "./CreateUserHelper";
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
import useNotifications from "@/components/Notifications/composables/notifications";
import router from '@/router';
import SelectOrganization from "./SelectOrganizationDialog.vue";
import { getRegion } from "@/helpers/countries/countries";
import { onMounted, ref } from "vue";
 import BeforeExitDialogue from '@/components/Dialog/BeforeExitDialog/BeforeExitDialogue.vue';
 import Saving from '@/components/Dialog/SavingDialog/Saving.vue'
 import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';

const notification = useNotifications();
const savingDialog = ref ()
const regions = getRegion();
const { values, submitForm, isLoading, isSuccess, errorMessage, 
  newUserId ,getRolesList,selectedGroup,roles ,hasChanges,removeBeforeLoad} = useCreateUserForm();

onMounted(() => {
  getRolesList();
});

async function onSubmit() {
  await submitForm();
  if (isSuccess.value === true) {
    notification.success('User created successfully!');
    router.push({ name: 'user-editor', params: { id: newUserId.value } });
  } 
}
  function  addOrgainzation(group :any) {
    values.group= group
  }
  async function submitAndExit() {
  savingDialog.value.open()
  await submitForm();
  savingDialog.value.close()
  if (isSuccess.value === true) {
    notification.success('user created successfully!');
    removeBeforeLoad();
    router.push({ name: 'user-editor', params: { id: newUserId.value } });
  }
}

function onExit() {
  removeBeforeLoad()
  router.push({ name: 'users'});
}
</script>
  
<style lang="scss" scoped>
.roles-select {
  height: 70px !important;
}
.select {
  border: 1px solid #abadb3;
  height: 35px;
  padding: 2px 2px 1px 2px;
  outline: none;
  background: transparent;
  left: 0;
  font-size: 14px;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 180px !important;

  &:disabled {
    margin-right: auto;
    background-color: #e6e6e6;
    color: #999999;
  }
}

.separetor {
  border-bottom: 1px solid #e6e6e6;
}

.status-bar-container {
  margin-bottom: 1rem;
}

.create-user {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;

  @include medium-devices-down {
    flex-direction: column;
  }
}

.create-slide-form {
  display: flex;
  flex-direction: column;
}

.form-fields {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.formGroup {
  display: flex;
  align-items: center;

  .select {
    width: 60%;
    margin: 0;
  }

  label {
    display: inline-block;
    width: 200px;
    color: #262626;
    font-weight: bold;
  }
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
.icon {
  position: absolute;
  top: 8px;
  right: 2px;
  z-index: 1;
  pointer-events: none;
}

  .styled-select {
  /* TODO: to separate templates related from generic later as it was quickly copied  */
  /* TODO: to think about the className it was quickly copied  */

  border: 1px solid #abadb3;
  /* Old disabled styling */
  /* line-height: 0; */
  /* NEW STYLING */
  line-height: 1em;
  display: flex;
  justify-content: center;
  height: 34px;
  overflow: hidden;
  padding: 0;
  position: relative;

  /* width: 46px; */
  width: 100%;

  :deep(select) {
    background: transparent;
    border: 0;
    left: 0;
    font-size: 14px;
    top: 0;
    appearance: none;
    z-index: 1;
    cursor: pointer;
    padding: 9px;
    margin: 0;

    width: 100%;
    padding: 0 1.2em 0 0.6em;
    outline: none;
    color: #666666;
  }
}
</style>
  