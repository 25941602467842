<template>
  <Dialog v-if="user.hasCreateAccessToArea(areaAccessName)">
    <template #expander="{openModal}">
      <ButtonCustomized
        :label="`${hasUnsavedChanges ? 'Save & ' : ''} Create Another Campaign`"
        @click="openCreateAnotherCampaignModal(openModal)"
      />
    </template>
    <template #dialog.title >Create Another Campaign</template>

    <template #dialog.content="{closeModal, closeModalAfter}">
      <CreateCampaignForm
        @cancel="closeModal"
        @success="closeModalAfter(1000)"
      />
    </template>
  </Dialog>
</template>


<script lang="ts" setup>
import Dialog from '@/components/Dialog/Dialog.vue';
import ButtonCustomized from '@/components/form-fields/ButtonCustomized.vue';
import CreateCampaignForm from '@/views/CampaignsListView/CreateCampaignForm/CreateCampaignForm.vue';
import useUser from '@/composables/user';
import listNamesConfig from '@/views/CampaignsListView/CampaignsLister/config/listNames.config';
import useCampaign from '../composables/campaign';
import sharedRefs from '../sharedRefs';

const user = useUser();
const { areaAccessName } = listNamesConfig;
const { hasUnsavedChanges } = useCampaign();
const { saveCampaignButtonRef } = sharedRefs;

async function openCreateAnotherCampaignModal( openModalCallback: Function ) {
  if(hasUnsavedChanges.value) {
    await saveCampaignButtonRef.value.saveCampaign();
  }
  setTimeout(openModalCallback, hasUnsavedChanges.value ? 1000 : 0);
}
</script>

<style lang="scss" scoped>

</style>