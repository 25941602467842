import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import { createUser, fetchRolesList, getUserById, updateUser } from "@/helpers/api/users/users";
import { getRegion } from "@/helpers/countries/countries";
import { getOrganizationById, getUpdateOrganizationItem, updateOrganization } from "@/helpers/api/organization/organization";
import { getModelFromViewCode } from "@/helpers/api/api";
import { getLocationById, getUpdateLocationItem, updateLocation } from "@/helpers/api/location/location";
export default function useLocationEditor() {
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]

  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const locationId = ref("");
  const locationModel  = ref ()
  const hasChanges = ref(false)
  const regions = getRegion()
  const organizationDetails = ref () 
  const validationSchema = yup.object({
    name: yup.string().label('name').required(),
    region: yup.object().label('region').required(),
    country: yup.object().label('country').required(),
    city: yup.string().label('city').required(),
    timezone: yup.string().label('timezone').required(),

});

const { errors, handleSubmit, values, setFieldValue, isSubmitting } = useForm({ validationSchema });
const addTag = ref()
const form = ref({
    city: "",
    contactName: "",
    country: "" as any ,
    hours: "",
    name: "",
    phoneNumber: "",
    region: "" as any ,
    state: "" as any ,
    timezone: "",
    zip: "",
    address1: "",
    address2: "",
     businessUnitNumber: ""
})
watch([values,form.value],([newFields,newForm],[oldFields,oldForm]) => {

    hasChanges.value = true 
   
  if (hasChanges.value)
  {
    onBeforeLoad() ; 
  }else {
    removeBeforeLoad();
  }
})

watch(hasChanges, (newValue,oldValue,) => {
  if (newValue)
  {
    onBeforeLoad() ; 
  }else {
    removeBeforeLoad();
  }
})
function onBeforeLoad () {
  window.addEventListener('hashchange', showAlert);
  window.addEventListener('beforeunload',showAlert 
  );
}
function removeBeforeLoad () {
  window.removeEventListener('hashchange', showAlert);

  window.removeEventListener('beforeunload',showAlert)

  
}
function showAlert (event :any ) {
  event.returnValue = 'You have unfinished changes!';
}
  const submitForm = handleSubmit(handleSubmitValid, handleSubmitInvalid);
 async function loadLocation (){
        const result = await getUpdateLocationItem(locationId.value) ;
        locationModel.value = getModelFromViewCode(result) ;
        console.log('ss');
        console.log(locationModel.value)
        getLocationById(locationId.value).then(res => {
            bindModel(res[0])
            setTimeout(() => {
                hasChanges.value = false 
            }, 0);
        })
       
}
function bindModel(location :any){
  values.name = location.name 
  values.region = regions.filter(item => item.name==location.region )[0]
  values.country = values?.region?.countries.filter((item:any) => item.name == location.country)[0]
  form.value.state = values?.country?.states.filter((item:any) => item.name == location.state)[0]
  values.city = location.city 
  values.timezone = location.timezone 
  form.value.contactName = location.contactName ;
  form.value.hours = location.hours ;
  form.value.phoneNumber = location.phoneNumber ;
  form.value.zip = location.zip ;
  form.value.businessUnitNumber = location.businessUnitNumber ;
  form.value.address1 = location.address1 ;
  form.value.address2 = location.address2 ;
  organizationDetails.value ={name : location.group.name , number:location.group.businessUnitNumber}
}
function fillModel() {
let locationModel = {...form.value}
locationModel.name = values.name 
locationModel.country = values.country.name 
locationModel.state = form.value.state?.name
locationModel.city = values.city ; 
locationModel.timezone = values.timezone ; 
locationModel.region = values.region.name
return locationModel
}
  async function handleSubmitValid() {
    try {
      let newModel = fillModel() 
      errorMessage.value = "";
      isLoading.value = true;
      isSuccess.value = false;
      const submissionResponse = await updateLocation( locationId.value, newModel);
      isLoading.value = false;
      if ( typeof submissionResponse[0]?.name === "string") {
        isSuccess.value = true;
       hasChanges.value = false 
      }
     
      if (submissionResponse.errors && submissionResponse.errors[0]?.message){
        errorMessage.value = submissionResponse.errors[0]?.message
      }
    } catch (error :any ) {
      console.log(error)
      isError.value = true;
      isLoading.value = false;
      errorMessage.value ='Something went wrong';
    }
  }
  async function handleSubmitInvalid() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }


  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    locationId,
    hasChanges,
    removeBeforeLoad,
    loadLocation,
    locationModel,
    regions,
    form,
    organizationDetails
  };
}
