import { log } from "@/helpers/utils";

export default function prepareFieldsFilters(filters: object) {
  // log({filters});
  const filterProperties = Object.getOwnPropertyNames(filters);
  const filtersPrepared = filterProperties.map((property) => {
    const filterItemValue = filters[property];
    const filterItemPrepared = {
      name: property,
      selectedOptions: [filterItemValue]
    }
    return filterItemPrepared;
  })
  return filtersPrepared;
}
