<template>
<SaveSlideButton ref="saveSlideButtonRef" />
<ExitButton
  v-bind="{hasUnsavedChanges, unsetHasUnsavedChanges}"
  @exit="onExit"
  @saveAndExit="onSaveAndExit"
/>
</template>

<script lang="ts" setup>
import SaveSlideButton from './SaveSlideButton/SaveSlideButton.vue';
import ExitButton from '@/components/Dialog/ButtonsWithDialog/ExitButton/ExitButton.vue';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import router from '@/router';
import { ref } from 'vue';

const { hasUnsavedChanges, unsetHasUnsavedChanges } = useSlide();
const saveSlideButtonRef = ref();

function onExit() {
  router.push({ path: '/media' })
}

async function onSaveAndExit() {
  await saveSlideButtonRef.value.saveSlide();
  router.push({ path: '/media' })
}
</script>

<style lang="scss" scoped>

</style>