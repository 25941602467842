<template>
  <!-- TODO: think about un-duplicating similarities between some other component     [DSP-N1QAEm1F]
        {repo}\src\components\form-fields\NumberField.vue
        {repo}\src\components\form-fields\SeachBar\SeachBar.vue
  -->
<TextFieldWarpper>
  <label
    v-if="label"
    :for="name"
    :class="labelClassName"
  >
    {{ label }}
    <template v-if="required"> &#9733;</template>
  </label>
  <input
    type="text"
    :name="name"
    :id="id || name"
    :maxlength="maxlength"
    :value="modelValue"
    @input="emitValue"
    @focusin="$emit('focusin')"
    @focusout="$emit('focusout')"
    :style='{
      width: (width || "24ch")
    }'
    :class='[fieldClassName]'
  />
</TextFieldWarpper>
</template>

<script lang="ts" setup>
import parseForModelModifiers from './helpers/utils/parseForModelModifiers';
import TextFieldWarpper from '@/components/form-fields/TextFieldWrapper.vue';

const props = defineProps({
  'modelValue': {
  },
  'label': {
    type: String,
    default: ""
  },
  'name': {
    type: String,
    default: ""
  },
  'id': {
    type: String
  },
  'maxlength': {
    default: "60"
  },
  'required': {
    type: Boolean,
    default: false
  },
  'modelModifiers': { default: () => ({}) },
  'width': {
    type: String
  },
  labelClassName: {
    type: String,
  },
  fieldClassName: {
    type: String,
  }
})

const emit = defineEmits(['update:modelValue','focus'])

function emitValue(event: Event) {
  const value = parseForModelModifiers(props.modelModifiers, event.target.value);
  emit('update:modelValue', value)
}
</script>

<style lang="scss" scoped>
</style>