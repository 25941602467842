import { log } from '@/helpers/utils';
import useApi, { getModelFromViewCode } from '../api';


export async function submitCreatePlayerItem (locationUid: any , registrationCode : any  ) {
  const { post } = useApi();
  
  const { responseBody } = await post(`api/players/${registrationCode}/register?locationUid=${locationUid}`,null, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function reassignPlayer (playerId: any , registrationCode : any  ) {
  const { post } = useApi();
  
  const { responseBody } = await post(`api/players/${playerId}/reassign?newPlayerRegCode=${registrationCode}`,null, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function getPlayerById (playerId: string ) {
  const { get } = useApi();
  
  const { responseBody } = await get(`api/players/${playerId}`, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}

export async function updatePlayer (playerId: string , options : any  ) {
  const { put } = useApi();
  
  const { responseBody } = await put(`api/players/${playerId}`,[options], {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function addPlayerMetadata (playerId: string , options : any  ) {
  const { post } = useApi();
  
  const { responseBody } = await post(`api/Players/${playerId}/metadatas`,[options], {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function deleteMetadata (tagId: number   ) {
  const { deleteRequest } = useApi();
  
  const { responseBody } = await deleteRequest(`api/metadatas/${tagId}`, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}

export async function deleteMetadataForAllPlayers(category:string,value:string) {
  const { post } = useApi();
  
  const { responseBody } = await post(`api/metadatas/DeleteForAllPlayers`,{
    category,
    value
  }, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}

export async function previewPlayer (playerId : number ) {
  const { post } = useApi();
  
  const { responseBody } = await post(`api/players/${playerId}/preview`,null, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function previewPlayerByDate (playerId :string, date :any ){
  const { post } = useApi();
  
  const { responseBody } = await post(`api/players/${playerId}/preview?month=${date.month}&day=${date.day}&year=${date.year}&hours=${date.hour}&minutes=${date.minute}`,null, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function searchUptime (playerId :string, days :any ){

  const { post } = useApi();
  
  const { responseBody } = await post(`api/players/${playerId}/uptime?daysAgo=${days}`,null, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function searchMetaData (playerId :string){

  const { post } = useApi();
  
  const { responseBody } = await post(`api/players/AutoComplete/${playerId}`,null, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
// http://htopology-002-site2.etempurl.com/api/players/AutoComplete/755989



