import useApi from '../api';

export async function fetchPlaylistItemById(
  itemId: number|string
) {
  const {get} = useApi();
  try {
    const { responseBody } = await get('api/playlists/'+itemId, {isLogoutOnInvalidAuth: true})
    return responseBody;
  } catch(err) {
    throw err;
  }
}

