<template>
  <ItemsLister v-bind="itemsListerConfig" :itemsListHelpers="playlistsListHelpers" />
</template>

<script lang="ts" setup>
import ItemsLister from '@/components/ListingPages/ItemsLister/ItemsLister.vue';
import itemsListerConfig from './config/itemsLister.config';
import usePlaylistsListHelpers from './playlistsListHelpers';
const playlistsListHelpers = usePlaylistsListHelpers();
</script>

<style lang="scss" scoped>
</style>