import { stageNode } from '@/views/MediaEditorView/SlideEditor/Stage/baseElementsRefs';
import { stagePosition, stageScale } from '@/views/MediaEditorView/SlideEditor/Stage/baseConfigsRefs';
import { repositionStage } from '../../stageHelpers/panStageHelpers_tempTest';

export function handleZoomOnWheel(e: any) {
  
  // stop default scrolling
  e.evt.preventDefault();

  const scaleBy = 1.10;

  const oldScale = stageNode.value.scaleX();
  const pointer = stageNode.value.getPointerPosition();

  const mousePointTo = {
    x: (pointer.x - stageNode.value.x()) / oldScale,
    y: (pointer.y - stageNode.value.y()) / oldScale,
  };

  // how to scale? Zoom in? Or zoom out?
  let direction = e.evt.deltaY > 0 ? 1 : -1;

  // when we zoom on trackpad, e.evt.ctrlKey is true
  // in that case lets revert direction
  if (e.evt.ctrlKey) {
    direction = -direction;
  }

  const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

  stageScale.value = newScale;

  const newPos = {
    x: pointer.x - mousePointTo.x * newScale,
    y: pointer.y - mousePointTo.y * newScale,
  };

  stagePosition.value = newPos;
  repositionStage();
}


export const zoomStageHandlers = {
  onMouseWheel(e: any) {
    handleZoomOnWheel(e);
  }
}

export default zoomStageHandlers;