<template>
  <!-- TODO (cleaner code / faster development experience / code reusability) think how we can remove redundancy for "ItemActionsButtons" for this list "MediaList" and other lists (e.g. "Playlists", "Campaigns", etc.) so we can easily add new lists with items with actions and other list features ready [DSP-N26EdxAT]  -->
  <!-- TODO (cleaner code): think how we can remove possible redundancy between "DeleteItemButtonWithDialog" & "CopyItemButtonWithDialog" [DSP-N26F2a5C] -->
  <Dialog>
    <template #expander="{openModal}">
      <a href="#" title="Delete">
        <OldThemeIcon
          name='delete'
          @click.stop="openModalHandler(openModal)"
          :class="['button', {disabled: disabled}]"
        />
      </a>
    </template>
    <template #dialog.title>Warning</template>
    <template #dialog.content="{closeModal, closeModalAfter}">
      <DeleteItemConfirmationForm
        @cancel="closeModal"
        @success="closeModalAfter(1000)"
        v-bind="{itemId}"
      />
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import Dialog from '@/components/Dialog/Dialog.vue';
import DeleteItemConfirmationForm from './DeleteItemConfirmationForm/DeleteItemConfirmationForm.vue';
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';

const props = defineProps(['itemId', 'disabled'])

function openModalHandler(openModalCallback: Function) {
  if(props.disabled) return;
  openModalCallback();
}
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
  &.disabled {
    cursor: default;
    opacity: 0.3;
  }
}
</style>