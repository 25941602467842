<template>
  <div class="tools-container">
    
    <v-tooltip
      v-for="tool in tools"
      :text="tool.label"
      :key="tool.name"
    >
      <template v-slot:activator="{ props }">
        <div :class="['tool-icon-container', {'disabled': isDisabled(tool.name)}]">
          <div
            class="tool-icon"
            :style="{'background-image': backgroundsUrls[tool.name]}"
            v-bind="props"
            @click="tool.clickEvent"
          >
          </div>
        </div>
      </template>
    </v-tooltip>
  </div>
  <!-- For Debugging START -->
  <!-- {{ historyStep }} {{ isPauseHistoryWatcher }} -->
  <!-- For Debugging END -->
  </template>
  
  <script lang="ts" setup>
  import { reactive } from 'vue';
  import { useImportedPathsAsCssUrls } from '@/composables/utils/importedPaths';
  import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';

  import undoImageUrl from './images/undo.png';
  import redoImageUrl from './images/redo.png';
  
  const {undo, redo, historyStep, history, isPauseHistoryWatcher} = useSlide();
  

  const backgroundsUrls = useImportedPathsAsCssUrls({
    "undo": undoImageUrl,
    "redo": redoImageUrl
  });
  
  function testOnClickHandler() {
    alert('test');
  
  }
  const tools = reactive([
    {
      "label": "Undo",
      "clickEvent": undo,
      "name": "undo"
    },
    {
      "label": "Redo",
      "clickEvent": redo,
      "component": "LayersPane",
      "name": "redo"
    },
  ]);
  
  function isDisabled(toolName: string) {
    if(toolName === 'undo') {
      return historyStep.value === 0;
    }
    if(toolName === 'redo') {
      return historyStep.value === history.value.length - 1;
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .tools-container {
    display: flex;
    flex-grow: 0.1;
    justify-content: space-between;
  }
  .tool-icon {
    height: 26px;
    width: 26px;
    &:hover {
      cursor: pointer;
    }
  }

  .tool-icon-container {
    padding: 0.375rem;
    border-radius: 0.375rem;
    &.active, &:hover {
      background-color: rgb(209 213 219);
    }

    &.disabled {
      opacity: 0.4;
    }
  }
  </style>