<template>
<div :class='[`Rtable Rtable--${columns}cols`,{"Rtable--collapse": collapsible}]'>
  <slot></slot>
</div>
</template>

<script lang="ts" setup>

export interface Props {
  columns?: number,
  collapsible?: boolean, // collapse row vertically when screen width is too small
  borderWidth?: number | string,
  breakpoint?: number | string
}

const props = withDefaults(defineProps<Props>(), {
  columns: 2,
  collapsible: true,
  borderWidth: "0.375rem",
  breakpoint: "576px"
})

const { borderWidth, breakpoint } = props;
</script>


<style lang="scss" scoped>
$border-width: v-bind(borderWidth);  // border width
$breakpoint: v-bind(breakpoint);   // responsive table collapse breakpoint

/* Tables
================================== */
.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
}
:deep(.Rtable-cell) {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;  // Default to full width
  /* padding: 0.8em 1.2em; */
  overflow: hidden; // Or flex might break
  list-style: none;
  border: solid $border-width white;
  /* background: fade(slategrey,20%); */
  > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0; }
}

/* Table column sizing
================================== */
.Rtable--2cols > :deep(.Rtable-cell)  { width: 50%; }
.Rtable--3cols > :deep(.Rtable-cell)  { width: 33.33%; }
.Rtable--4cols > :deep(.Rtable-cell)  { width: 25%; }
.Rtable--5cols > :deep(.Rtable-cell)  { width: 20%; }
.Rtable--6cols > :deep(.Rtable-cell)  { width: 16.6%; }

/* Responsive
==================================== */
@media all and (max-width: 576px) {
  .Rtable--collapse  {
    display: block;
    > :deep(.Rtable-cell) { width: 100% !important; }
    > :deep(.Rtable-cell--foot) { margin-bottom: 1em; }
  }
}

// Non-Flex modernizer fallback
.no-flexbox .Rtable {
  display: block;
  > :deep(.Rtable-cell) { width: 100%; }
  > :deep(.Rtable-cell--foot) { margin-bottom: 1em; }
}
</style>
















