<template>
<OldThemeIcon name="refresh" class="icon button" @click="handleClick" />
</template>

<script lang="ts" setup>
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import { inject } from "vue";

const itemsListHelpers = inject('itemsListHelpers');
const { reloadList } = itemsListHelpers;

function handleClick() {
  reloadList();
}
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
}
</style>