<template>
<component :is="bulkSelectBoxComponentToShow"
  :class='["bulk-selection-box", {"partial-selected": (!isAllItemsSelected && !isNoItemSelected), "all-selected": isAllItemsSelected}]'
  @click.stop="bulkSelectionClickHandler"
  v-if="!listerConfig?.singleSelect"
/>
</template>

<script lang="ts" setup>
import { computed, inject } from "vue";
import CheckMarkIcon from "@/components/icons/CheckMarkIcon.vue";
import EmptyBoxIcon from "@/components/icons/EmptyBoxIcon.vue";
import PartialSelectionIcon from "@/components/icons/PartialSelectionIcon.vue";
import useTableBulkSelectBoxHelpers from "./tableBulkSelectBoxHelpers_forMediaList";

const itemsListHelpers = inject('itemsListHelpers');

const {
  clearMediaItemsSelection,
  addAllItemsToSelection,
  isAllItemsSelected,
  isNoItemSelected
} = useTableBulkSelectBoxHelpers( itemsListHelpers );

const listerConfig = inject('listerConfig', {});

const bulkSelectBoxComponentToShow = computed(() => {
  if (isAllItemsSelected.value) {
    return CheckMarkIcon;
  } else if (isNoItemSelected.value) {
    return EmptyBoxIcon;
  } else {
    return PartialSelectionIcon;
  }
});

const bulkSelectionClickHandler = (event: MouseEvent) => {
  if (isAllItemsSelected.value) {
    clearMediaItemsSelection();
  } else {
    addAllItemsToSelection();
  }
};

</script>

<style lang="scss" scoped>
.bulk-selection-box {
  transition: opacity, color 0.2s ease-out;
  color: rgb(28, 105, 212);
  opacity: 0.2;
  &.all-selected {
    opacity: 1;
  }
  &.partial-selected {
    opacity: 1;
  }
  
  &:hover {
    opacity: 1;
  }
}


/* .item-selection-checkmark {
  color: #B1C4CF;
  transition: opacity, color 0.2s ease-out;
  opacity: 0;
  &.item-hovered {
    opacity: 0.8;
  }
  &.selected, &:hover {
    color: #1c69d4;
    opacity: 1;
  }
} */
</style>