import { reactive, nextTick, type ComputedRef } from 'vue';
import type { TextSprite } from "@/helpers/types/media/slide/Sprite";
import { stageNode } from '../../Stage/baseElementsRefs';
import { selectedSprite } from '../slide';
import { log } from '@/helpers/utils';
import {
  onMultiStepChangeStartHistoryHandler,
  onMultiStepChangeEndHistoryHandler
} from './history';

export interface Font {
  name: string;
  id: number;
  isBold: boolean;
  isItalic: boolean;
}
export type FontFamily = {
  name: string;
  fonts: Font[];
}

export const fontsFamilies = reactive([] as FontFamily[]);    // available fonts families for the current slide

export function initializeFonts(fontsFamiliesToBeInitialized: FontFamily[]) {
  log({fontsFamiliesToBeInitialized}, {color: "purple"});
  fontsFamilies.splice(0, fontsFamilies.length);
  fontsFamiliesToBeInitialized.forEach(fontFamily => {
    const fontFamilyPrepared = {
      name: fontFamily.fonts[0].name,
      // id: fontFamily.id,
      // uid: fontFamily.uid
      fonts: []
    } as FontFamily;

    // load font family fonts
    fontFamily.fonts.forEach(font => {
      const fontPrepared = {
        name: font.name,
        id: font.id,
        isBold: font.isBold,
        isItalic: font.isItalic,
      } as Font;
      fontFamilyPrepared.fonts.push(fontPrepared);

      const fontFace = new FontFace(fontFamilyPrepared.name, `url(${font.sourceUri})`, {
        style: (font.isItalic ? "italic" : "normal"),
        weight: (font.isBold ? "700" : (font.weight ? font.weight : "normal")),
      });
      fontFace.load().then((loaded_fontFace) => {
        document.fonts.add(loaded_fontFace)
        if(stageNode.value) {
          stageNode.value.batchDraw();
        }
        console.log("font loaded")
      })
    });

    fontsFamilies.push(fontFamilyPrepared);
  });
}

export async function updateSelectedSpriteFontFamilyWithStyleName() {
  onMultiStepChangeStartHistoryHandler();
  await nextTick();
  selectedSprite as ComputedRef<TextSprite>;

  const fontFamily = fontsFamilies.find(fontFamilyItem => fontFamilyItem.name === selectedSprite.value.fontFamily);
  if(!fontFamily) throw new Error("Font family not found");
  if(!selectedSprite.value) throw new Error("No sprite selected");

  if(selectedSprite.value.isBold && !fontFamily.fonts.some(font => font.isBold === true)) {
    // disable isBold
    selectedSprite.value.isBold = false;
    let newFontStyle = selectedSprite.value.fontStyle.replace(/\s?bold/g, "")
    if(!newFontStyle) {
      newFontStyle = "normal";
    }
    selectedSprite.value.fontStyle = newFontStyle;
  }

  if(selectedSprite.value.isItalic && !fontFamily.fonts.some(font => font.isItalic === true)) {
    // disable isItalic
    selectedSprite.value.isItalic = false;
    let newFontStyle = selectedSprite.value.fontStyle.replace(/italic\s?/g, "")
    if(!newFontStyle) {
      newFontStyle = "normal";
    }
    selectedSprite.value.fontStyle = newFontStyle;
  }
  
  const font = fontFamily.fonts.find(font => font.isBold === selectedSprite.value.isBold && font.isItalic === selectedSprite.value.isItalic);

  selectedSprite.value.fontFamilyWithStyleName = font.name;
  onMultiStepChangeEndHistoryHandler();
}

export function getFontId({name="", isBold=false, isItalic=false}) {
  let fontFamily = fontsFamilies.find(fontFamily => fontFamily.name === name);
  let fontMatch;
  if(!fontFamily) {
    fontFamily = fontsFamilies.find(fontFamily => {
      fontMatch = fontFamily.fonts.find(font => font.name === name);
      if(fontMatch) return true;
    });
  }

  if(fontMatch) {
    return fontMatch.id;
  }
  // log({fontsFamilies, name});
  // log({fontFamily});
  let font = fontFamily.fonts.find(font => font.isBold === isBold && font.isItalic === isItalic);
  if(!font) {
    font = fontFamily.fonts[0];
  } 
  return font.id;
}