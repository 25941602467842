<template>
  <div ref="sideCollapsibleContainer_elementRef">
    <ToggleCollapseButton
      @toggle-open-click="toggleOpen"
      :isStickyOnScrollDown="isStickyOnScrollDown"
      :isOpen="isOpen"
    >
      <slot name="button" :isOpen="isOpen" />
    </ToggleCollapseButton>

    <slot name="content"  :isOpen="isOpen"></slot>
  </div>
</template>


<script lang="ts" setup>
import { reactive, ref, watch } from 'vue';
import ToggleCollapseButton from './ToggleCollapseButton.vue';

defineProps({
  'isStickyOnScrollDown': {
    type: Boolean,
    default: false
  },
})

const isOpen = ref(false);
const sideCollapsibleContainer_elementRef = ref()

function toggleOpen() {
  isOpen.value = ! isOpen.value
}


// Watch for click outside
const listener = (event: Event) => {
  const isClickedOnCollapsibleContainer = !sideCollapsibleContainer_elementRef.value || sideCollapsibleContainer_elementRef.value.contains(event.target)
  const isNotLink = event.target.tagName.toLowerCase() !== 'a'
  if (isClickedOnCollapsibleContainer && isNotLink) {
    return;
  }
  toggleOpen()
};

watch(isOpen, (isOpenNew) => {
  if (isOpenNew === true) {
    document.addEventListener('mousedown', listener);
  }

  if (isOpenNew === false) {
    document.removeEventListener('mousedown', listener);
  }
})
</script>


<style lang="scss" scoped>

</style>