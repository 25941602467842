<template>
<!-- Text Underline Toggle -->
<!-- Text Bold Toggle -->
<!-- Text Italic Toggle -->
<div class="flex flex-row items-center gap-1">
  <div
    v-for="option in stylesOptions"
    :key="option.name"
    :style="`background-image: ${backgroundsUrls[option.name]};`"
    :class='[
      "text-style-icon",
      {
        "active": option.isActive,
        "disabled": !isToggleEnabled(option.name, selectedSprite.fontFamily)
      }
    ]'
    v-on="{click: isToggleEnabled(option.name, selectedSprite.fontFamily) ? option.toggleHandler : null}"
  >
  </div>
</div>
</template>

<script lang="ts" setup>
import type { TextSprite } from "@/helpers/types/media/slide/Sprite";

import { useImportedPathsAsCssUrls } from '@/composables/utils/importedPaths';
import textItalicImgUrl from '../images/font-style.png';
import textBoldImgUrl from '../images/font-weight.png';
import textStrikethroughImgUrl from '../images/font-strikethrough.png';
import textUnderlineImgUrl from '../images/font-underline.png';
import { computed, type ComputedRef } from "vue";

import useSlide, {type FontFamily} from '@/views/MediaEditorView/SlideEditor/composables/slide';

const props = defineProps<{
  fontStyle: string;
  textDecoration: string;
}>()

const emit = defineEmits(['update:fontStyle', 'update:textDecoration']);

const backgroundsUrls = useImportedPathsAsCssUrls({
  "italic": textItalicImgUrl,
  "bold": textBoldImgUrl,
  "strikethrough": textStrikethroughImgUrl,
  "underline": textUnderlineImgUrl
});

const stylesOptions = computed(() =>[
  {
    name: "italic",
    toggleHandler: toggleItalic,
    isActive: props.fontStyle.includes("italic")
  },
  {
    name: "bold",
    toggleHandler: toggleBold,
    isActive: props.fontStyle.includes("bold")
  },
  {
    name: "strikethrough",
    toggleHandler: toggleLineThrough,
    isActive: props.textDecoration.includes("line-through")
  },
  {
    name: "underline",
    toggleHandler: toggleUnderline,
    isActive: props.textDecoration.includes("underline")
  }
]);

const { selectedSprite, fontsFamilies, updateSelectedSpriteFontFamilyWithStyleName } = useSlide() as {
  selectedSprite: ComputedRef<TextSprite>;
  fontsFamilies: FontFamily[];
  updateSelectedSpriteFontFamilyWithStyleName: () => void;
};

function toggleBold() {
  selectedSprite.value.isBold = !selectedSprite.value.isBold;

  let newFontStyle = 
      props.fontStyle.includes("bold")
    ? props.fontStyle.replace(/\s?bold/g, "")
    : props.fontStyle.replace(/(^.*$)/g, "$1 bold");
  if(!newFontStyle) {
    newFontStyle = "normal";
  }

  updateSelectedSpriteFontFamilyWithStyleName();

  emit('update:fontStyle', newFontStyle);
}

function toggleItalic() {
  selectedSprite.value.isItalic = !selectedSprite.value.isItalic;

  let newFontStyle = 
      props.fontStyle.includes("italic")
    ? props.fontStyle.replace(/italic\s?/g, "")
    : props.fontStyle.replace(/(^.*$)/g, "italic $1");
  if(!newFontStyle) {
    newFontStyle = "normal";
  }

  updateSelectedSpriteFontFamilyWithStyleName();
  emit('update:fontStyle', newFontStyle);
}

function toggleLineThrough() {
  let newTextDecoration = 
      props.textDecoration.includes("line-through")
    ? ""
    : "line-through";
  emit('update:textDecoration', newTextDecoration);
}

function toggleUnderline() {
  let newTextDecoration = 
      props.textDecoration.includes("underline")
    ? ""
    : "underline";
  emit('update:textDecoration', newTextDecoration);
}

function isToggleEnabled(optionName: string, selectedFontFamily: string) {
  const selectedSpriteFontFamily = fontsFamilies.find(fontFamily => fontFamily.name === selectedFontFamily);
  if(optionName == "bold") {
    return selectedSpriteFontFamily.fonts.some(font => font.isBold);
  } else if(optionName == "italic") {
    return selectedSpriteFontFamily.fonts.some(font => font.isItalic);
  } else {
    return true;
  }
}

</script>

<style lang="scss" scoped>
.text-style-icon {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 0.375rem;
  background-position: center;
  cursor: pointer;
  &.active, &:hover {
    background-color: rgb(209 213 219);
  }

  &.disabled {
    opacity: 0.4;
  }
}
</style>