import {computed, ref, watch, type ComputedRef, type Ref} from "vue";
import { onBeforeRouteLeave } from 'vue-router';
// import { log } from "@/helpers/utils";

interface useHasUnsavedChangeHelpersInput {
  statesToWatchForChanges: any;
}

const isLoading = ref(false);
const hasUnsavedChanges = ref(false);
const isDataInitialized = ref(false);
const isLoadingOrDataNotInitialized = computed(()=> {
  if (!isDataInitialized.value || isLoading.value) {
    return true;
  } else {
    return false;
  }
});

function showAlert(event: any) {   // needs to be outside "useHasUnsavedChangesHelpers" in order to keep reference to it while removing event later
  event.returnValue = 'You have unsaved changes!';
}

export function useHasUnsavedChangesHelpers({
  statesToWatchForChanges,
}: useHasUnsavedChangeHelpersInput) {
  function initializeHasUnsavedChangesWatcher() {
    watch(statesToWatchForChanges, () => {
      if(isLoadingOrDataNotInitialized.value) return;
      
      if(!hasUnsavedChanges.value) {
        hasUnsavedChanges.value = true;
        addUnsavedChangesAlertListener();
      }
    });
    useOnBeforeRouteLeaveUnsavedChangesWarning();
  }
  
  function useOnBeforeRouteLeaveUnsavedChangesWarning() {
    onBeforeRouteLeave((to, from) => {
      if (hasUnsavedChanges.value) {
        const answer = window.confirm(
          'Changes you made may not be saved.'
        )
        if (!answer) return false;
        unsetHasUnsavedChanges();
        // log({hasUnsavedChangesState: hasUnsavedChangesState.value}, {color: "brown", ns: "HIIIII!"})
      }
    })
  }
  
  function addUnsavedChangesAlertListener() {
    window.addEventListener('beforeunload',showAlert)
  }
  
  function removeUnsavedChangesAlertListener() {
    window.removeEventListener('beforeunload',showAlert)
  }

  function unsetHasUnsavedChanges({resetDataInitializationState = true} = {}) {
    removeUnsavedChangesAlertListener();
    hasUnsavedChanges.value = false;
    if(resetDataInitializationState) {
      isDataInitialized.value = false;
      isLoading.value = false;
    }
    // log({hasUnsavedChangesState: hasUnsavedChangesState.value}, {color: "pink", ns: "HIIIII!"})
  }

  return {
    unsetHasUnsavedChanges,
    initializeHasUnsavedChangesWatcher,
    isLoading,
    hasUnsavedChanges,
    isDataInitialized
  }
}