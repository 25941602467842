const baseApiPaths = {
  'initialize': 'playlists',
  'search': 'playlistsearch'
}

const actionsApiPaths = {
  'export': 'playlists/export',
  'delete': 'api/playlists',
  'edit': 'playlists/edit'
}

const apiPathsConfig = {
  ...baseApiPaths,
  ...actionsApiPaths
}

export default apiPathsConfig;