import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import { createUser, fetchRolesList } from "@/helpers/api/users/users";
import { createRole, getRoleCreateModel } from "@/helpers/api/roles/roles";
import { getModelFromViewCode } from "@/helpers/api/api";
export default function useCreateRoleForm() {
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]

  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const newRoleId = ref("");
  const hasChanges = ref(false)
  const createModel = ref () ;
  const assetTypes = ref ([{ id: 0, name: "Image" },
        { id: 1, name: "Video" },
        { id: 2, name: "Slide" },
      { id: 3, name: "Font" },]
    )
  const accessLevels =
    [
      {
        name: "No Access",
        value: 0
      },
      {
        name: "View",
        value: 1
      },
      {
        name: "Edit",
        value: 3
      },
      {
        name: "Create",
        value: 7
      },
      {
        name: "Delete",
        value: 15
      }
    ];

  const validationSchema = yup.object({
    name: yup.string().label('Name').required(),

  });

  const initialValues = {
    name: "",


  };
  const { errors, handleSubmit, values, setFieldValue, isSubmitting } = useForm({ validationSchema, initialValues });
  watch(values ,(newFields,oldFields) => {
    hasChanges.value = true
    if (hasChanges.value) {
      onBeforeLoad();
    } else {
      removeBeforeLoad();
    }
  })

  watch(hasChanges, (newValue, oldValue,) => {
    if (newValue) {
      onBeforeLoad();
    } else {
      removeBeforeLoad();
    }
  })
  function onBeforeLoad() {
    window.addEventListener('hashchange', showAlert);
    window.addEventListener('beforeunload', showAlert
    );
  }
  function removeBeforeLoad() {
    window.removeEventListener('hashchange', showAlert);

    window.removeEventListener('beforeunload', showAlert)


  }
  function showAlert(event: any) {
    event.returnValue = 'You have unfinished changes!';
  }
  const submitForm = handleSubmit(handleSubmitValid, handleSubmitInvalid);

  async function getRoleModel (){
    const result = await getRoleCreateModel() ;
    createModel.value = getModelFromViewCode(result) ;
  }
  function findAssetById (id:any ){
    return assetTypes.value.filter(item => item.id == id)[0].name
  }
  function fillRoleModel() {
    let roleModel = {
     name :  values.name ,
     roleAreaAccesses : createModel.value.commonEntity.roleAreaAccesses,
     roleAssetTypeAccesses : createModel.value.commonEntity.roleAssetTypeAccesses,
    }
    return roleModel
  }
  async function handleSubmitValid() {
    try {
      let model = fillRoleModel();
      errorMessage.value = "";
      isLoading.value = true;
      isSuccess.value = false;

      const submissionResponse = await createRole(model);
      isLoading.value = false;
      if (typeof submissionResponse[0]?.name === "string") {
        isSuccess.value = true;
        newRoleId.value = submissionResponse[0].id;
      }
      if (submissionResponse.errors && submissionResponse.errors[0]?.message) {
        errorMessage.value = submissionResponse.errors[0]?.message
      }
    } catch (error: any) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = 'Something went wrong';
    }
  }
  async function handleSubmitInvalid() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if (Object.keys(errors.value).length) {
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key] + ". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }

  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    newRoleId,
    createModel,
    hasChanges,
    removeBeforeLoad,
    accessLevels,
    getRoleModel,
    findAssetById
    
  };
}
