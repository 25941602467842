<template>
  <Dialog>
    <template #expander="{ openModal }">
      <a title="Edit" class="cursor-pointer">
        <OldThemeIcon :name="'edit'" @click="openModal"> </OldThemeIcon>
      </a>
    </template>
    <template #dialog.title>Warning</template>
    <template #dialog.content="{ closeModal, closeModalAfter }">

    
        <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
        <div class="delete-item-form">
          <div class="confirmation-text">
            Are you sure you wish to leave this page and edit the {{ label }}? 
          </div>
          <div class="form-buttons">
            <ButtonCustomized label="Cancel" @click="closeModal" secondary />
            <ButtonCustomized label="Edit item" @click="onSave" />
          </div>
        </div>
 
    </template>
  </Dialog>
</template>
  
<script lang="ts" setup>
import Dialog from '../Dialog.vue';
import ButtonCustomized from "../../form-fields/ButtonCustomized.vue";
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';

defineProps(['label'])
const emit = defineEmits(['cancel', 'save'])

async function onSave() {
    emit('save');

}

</script>
  
<style lang="scss" scoped>
.button {
  cursor: pointer;
}
.delete-item-form {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
  }
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
  }
</style>