import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import { getAccountDetails, updateAccount } from "@/helpers/api/user";
export default function useAccountEditor() {
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]

  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const locationId = ref("");
  const locationModel = ref()
  const hasChanges = ref(false)
  const accountDetails = ref()
  const validationSchema = yup.object({
  //  email: yup.string().label('email').required(),

  });

  const { errors, handleSubmit, values, setFieldValue, isSubmitting } = useForm({ validationSchema });
  const form = ref({
    supportEmailAddress: "",
    name: "",
    bannerImage: null as any,
    defaultPlaylist: null as any ,
    id: null

  })
  watch([values], (newFields,oldFields) => {

    hasChanges.value = true

    if (hasChanges.value) {
      onBeforeLoad();
    } else {
      removeBeforeLoad();
    }
  })

  watch(hasChanges, (newValue, oldValue,) => {
    if (newValue) {
      onBeforeLoad();
    } else {
      removeBeforeLoad();
    }
  })
  function onBeforeLoad() {
    window.addEventListener('hashchange', showAlert);
    window.addEventListener('beforeunload', showAlert
    );
  }
  function removeBeforeLoad() {
    window.removeEventListener('hashchange', showAlert);

    window.removeEventListener('beforeunload', showAlert)


  }
  function showAlert(event: any) {
    event.returnValue = 'You have unfinished changes!';
  }
  const submitForm = handleSubmit(handleSubmitValid, handleSubmitInvalid);
  async function loadAccount() {
    let user = JSON.parse(localStorage.getItem('user') as any)
    let userId = user.userId;
    getAccountDetails(userId).then(res => {
      bindModel(res[0])
      setTimeout(() => {
        hasChanges.value = false
      }, 0);
    })

  }

  function fillModel() {

  }
  function bindModel(model: any) {
    form.value.name = model.name;
    values.email = model.supportEmailAddress;
    form.value.id = model.id ;
    form.value.bannerImage = model.bannerImage ; 
    form.value.defaultPlaylist = model.defaultPlaylist;
  }
  async function handleSubmitValid() {
    try {
      form.value.supportEmailAddress = values.email
      errorMessage.value = "";
      isLoading.value = true;
      isSuccess.value = false;
      const submissionResponse = await updateAccount(form.value.id,form.value);
      isLoading.value = false;
      if (typeof submissionResponse[0]?.name === "string") {
        isSuccess.value = true;
        hasChanges.value = false
      }

      if (submissionResponse.errors && submissionResponse.errors[0]?.message) {
        errorMessage.value = submissionResponse.errors[0]?.message
      }
    } catch (error: any) {
      console.log(error)
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = 'Something went wrong';
    }
  }
  async function handleSubmitInvalid() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if (Object.keys(errors.value).length) {
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key] + ". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }


  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    locationId,
    hasChanges,
    removeBeforeLoad,
    loadAccount,
    locationModel,
    form,
    accountDetails
  };
}
