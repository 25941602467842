import useListSelection from '../listSelection';
import useIsAllSelectedItemsAreSlidesHelpers from './isAllSelectedItemsAreSlidesHelpers';

export default function useMediaListItemSelectionHelpers( itemsListHelpers ) {
  const {
    afterAddToSelectionCallback,
    afterRemoveFromSelectionCallback,
    afterClearSelectionCallback,
    isAllSelectedItemsAreSlides
  } = useIsAllSelectedItemsAreSlidesHelpers( itemsListHelpers );
  
  const {
    selectListItemClickHandler,
    isItemSelected
  } = useListSelection({
    afterAddToSelectionCallback,
    afterRemoveFromSelectionCallback,
    afterClearSelectionCallback
  });
  
  const { filteredListItems } = itemsListHelpers;

  function mediaListItemSelectHandler(event, item, itemIndex, config = {}) {
    selectListItemClickHandler(
      event,
      item.id,
      filteredListItems.value,
      itemIndex,
      {
        afterSelectionActionArguments: { type: item.assetType, id: item.id, item},
        ...config
      });
  }

  return {
    mediaListItemSelectHandler,
    isItemSelected,
    isAllSelectedItemsAreSlides
  }
}