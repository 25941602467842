<template>
{{ formattedTime }}
</template>

<script lang="ts" setup>
const { epochTime } = defineProps<{
  epochTime: number;
}>()

const date = new Date(epochTime);

const dateString = date.toLocaleDateString('en-US', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  timeZone: 'UTC'
});

const timeString = date.toLocaleTimeString('en-US')
const formattedTime = dateString+', '+timeString;

</script>

<style lang="scss" scoped>

</style>