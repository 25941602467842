
<template>
<MediaLister v-bind="config" />
</template>

<script lang="ts" setup>
import MediaLister from '@/views/MediaListView/MediaLister/MediaLister.vue';

import filtersDisplayConfig from "./filtersDisplay.config";

const config = {
  singleSelect: true,
  filtersDisplayConfig
}
</script>

<style lang="scss" scoped>

</style>