const posItems = [    // POS items for a single "POS source"
  {
    // posSourceId: 782737,
    id: "1",    // POS item ID
    propertiesItems: [
      {
        id: 782749,    // pos property id
        propName: "name",
        propValue: "Interactive AR Screen Gen 1",
        isOverrideCurrent: false,
        overrideValue: null
      },
      {
        id: 782750,
        propName: "price",
        propValue: "600",
        isOverrideCurrent: false,
        overrideValue: null
      },
      {
        id: 782751,
        propName: "stock quantity",
        propValue: "96",
        isOverrideCurrent: true,
        overrideValue: 70
      },
    ],
  },
  {
    // posSourceId: 782745,
    id: "2",
    propertiesItems: [
      {
        id: 782749,
        propName: "name",
        propValue: "Interactive AR Screen Gen 2",
        isOverrideCurrent: true,
        overrideValue: "Interactive AR Screen Gen 2 (Newest 2023)"
      },
      {
        id: 782750,
        propName: "price",
        propValue: "750",
        isOverrideCurrent: false,
        overrideValue: null
      },
      {
        id: 782751,
        propName: "stock quantity",
        propValue: "80",
        isOverrideCurrent: false,
        overrideValue: null
      },
    ],
  },
  {
    // posSourceId: 782745,
    id: "3",
    propertiesItems: [
      {
        id: 782749,
        propName: "name",
        propValue: "AR Glasses Gen 1",
        isOverrideCurrent: false,
        overrideValue: null
      },
      {
        id: 782750,
        propName: "price",
        propValue: "300",
        isOverrideCurrent: true,
        overrideValue: 280
      },
      {
        id: 782751,
        propName: "stock quantity",
        propValue: "20",
        isOverrideCurrent: false,
        overrideValue: null
      },
    ],
  },
  {
    // posSourceId: 782745,
    id: "4",
    propertiesItems: [
      {
        id: 782749,
        propName: "name",
        propValue: "AR Glasses Gen 2",
        isOverrideCurrent: false,
        overrideValue: null
      },
      {
        id: 782750,
        propName: "price",
        propValue: "450",
        isOverrideCurrent: true,
        overrideValue: 410
      },
      {
        id: 782751,
        propName: "stock quantity",
        propValue: "6",
        isOverrideCurrent: false,
        overrideValue: null
      },
    ],
  },
]

export default posItems;