import { transformer } from '@/views/MediaEditorView/SlideEditor/Stage/baseElementsRefs';
import { getSpriteShapeNodeById, getSpriteStoreById, getSpriteIdByShapeNode } from './spriteGetters';
import { createLogger } from '@/helpers/utils';

const logCustom = createLogger('debug:spritesVisibility');

export function toggleSpriteVisibility(shapeNodeOrId) {    // quickly added to think if this is the best place for it
  toggleSpriteStageVisibility(shapeNodeOrId);
  toggleSpriteStoreVisibility(shapeNodeOrId)
}

function toggleSpriteStageVisibility(shapeNodeOrId) {    // quickly added to think if this is the best place for it
  let spriteShapeNode;
  if (typeof shapeNodeOrId === 'string') {
    const spriteId = shapeNodeOrId;
    spriteShapeNode = getSpriteShapeNodeById(spriteId);
  } else {
    spriteShapeNode = shapeNodeOrId;
  }
  logCustom({spriteShapeNode, isVisible: spriteShapeNode.isVisible()})
  spriteShapeNode.isVisible(!spriteShapeNode.isVisible());
}

function toggleSpriteStoreVisibility(shapeIdOrNode) {     // quickly added to think if this is the best place for it
  let spriteId;
  if (typeof shapeIdOrNode === 'string') {
    spriteId = shapeIdOrNode;
  } else {
    spriteId = getSpriteIdByShapeNode(shapeIdOrNode);
  }
  const sprite = getSpriteStoreById(spriteId);
  if (sprite) {
    sprite.hidden = !sprite.hidden;
  }
}
