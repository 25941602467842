<template>
<div class="search-bar">
  <SearchIcon class="icon" />
  <input type="text" placeholder="Search" @input="$emit('textChange',searchInput)" v-model="searchInput"/>
</div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import SearchIcon from './icons/SearchIcon/SearchIcon.vue';

const searchInput = ref(null);
</script>

<style lang="scss" scoped>
.search-bar {
  border: 1px solid #abadb3;
  display: flex;
  align-items: center;
  justify-content: space-around;
  input {
    color: #666666;
    flex-grow: 1;
    outline: none;
    height: 100%;
  }
}

.icon {
  margin: 0.7rem 0.45rem;
}
</style>