import selectorRectangleStageHandlers from './selectorRectangleStageHandlers';
import zoomStageHandlers from './zoomStageHandlers';
import devStageHandlers from './devStageHandlers';

export function handleStageMouseDown(e: any) {
  selectorRectangleStageHandlers.onMouseDown(e);
  devStageHandlers.onMouseDown(e);
  
}
export function handleStageMouseMove(e: any) {
  selectorRectangleStageHandlers.onMouseMove(e);
  devStageHandlers.onMouseMove(e);
  
}
export function handleStageMouseUp(e: any) {
  selectorRectangleStageHandlers.onMouseUp(e);
  devStageHandlers.onMouseUp(e);
  
}
export function handleStageMouseClick(e: any) {
  selectorRectangleStageHandlers.onMouseClick(e);
  devStageHandlers.onMouseClick(e);
}
export function handleStageMouseWheel(e: any) {
  zoomStageHandlers.onMouseWheel(e);
}

export default {
  handleStageMouseDown,
  handleStageMouseMove,
  handleStageMouseUp,
  handleStageMouseClick,
  handleStageMouseWheel
}