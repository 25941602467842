<!--
  TODO: think about naming this "EntintyListingPage" or "EntityListingView" to refer to a "view" instead of "page" — N2M5qW8u
    Seems that "EntityListingPage" is better
  TODO: think about naming this "SimpleEntityListingPage" or "PrimitiveEntityListingPage" or just "EntityListingPage" — N2M64T8K
    Seems that "SimpleEntityListingPage" is better because it's determinate that it's for only "simple" entities not "all entities".
-->
<template>
  <div>
    <PageSection separator="none" spacing="bottom">
      <TopBar>
        <template #title>
          {{ entityItemsNamePlural }}
        </template>
        <template #main-buttons>
          <CreateEntityItemButtonWithDialog
            v-if="CreateItemFormComponent && user.hasCreateAccessToArea(areaAccessName)"
            v-bind="{entityItemName ,hideDefaultTitle ,customTitle }"
          >
            <template #create-form="{closeModal, closeModalAfter}">
              <component
                v-if="CreateItemFormComponent"
                :is="CreateItemFormComponent"
                @cancel="closeModal"
                @success="closeModalAfter(1000)"
              />
            </template>
          </CreateEntityItemButtonWithDialog>
          <ButtonCustomized :label="hideDefaultTitle ? customTitle: 'Create New'"
          v-if="createItemPage" @click="emit('create')"
          />

          <slot name="top-bar.main-buttons"></slot>
        </template>
      </TopBar>
    </PageSection>
    <component
      v-if="ItemsLister"
      :is="ItemsLister"
      :singleSelect ="singleSelect"
      :selectMode="selectMode"
      v-bind="{
        ...(areaAccessName ? {areaAccessName} : null)
      }"
      ref="itemsListerRef"
      :MetaDataOptionsComponent="MetaDataOptionsComponent"

    />
  </div>
</template>

<script lang="ts" setup>
import TopBar from '../PageTopBar/PageTopBar.vue';
import PageSection from '@/components/layouts/PageSection.vue';
import CreateEntityItemButtonWithDialog from './CreateEntityItemButtonWithDialog/CreateEntityItemButtonWithDialog.vue';
import { provide, ref, type Ref } from 'vue';
import ButtonCustomized from '@/components/form-fields/ButtonCustomized.vue';
import useUser from '@/composables/user';

const user = useUser();

const emit = defineEmits(['create'])
const props = defineProps<{
  entityItemsNamePlural: string;
  entityItemName?: string;
  CreateItemFormComponent?: any;
  ItemsLister: any;
 hideDefaultTitle? : boolean 
 customTitle?: string 
 singleSelect?: boolean ,
 selectMode?:boolean
 createItemPage? : boolean 
 areaAccessName?: string,
  MetaDataOptionsComponent?: any
}>();

const {
  entityItemsNamePlural,
  entityItemName,
} = props;

const simpleEntityListingPageConfig = {
  entityItemsNamePlural,
  entityItemName 
};

provide('simpleEntityListingPageConfig', simpleEntityListingPageConfig);
</script>

<style lang="scss" scoped>

</style>