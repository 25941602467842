<!--
  TODO (cleaning): Somehow similar components ...1 to refine / clean potential redundancy — N3EIg6Cq
    ...1:
      AddPlaylistDialog.vue: @\views\CampaignEditorView\AddPlaylist\AddPlaylistDialog.vue
-->

<template>
  <Dialog
    panel-class="px-4 py-4"
    size="fullscreen"
    :disable-close-button="true"
  >
    <template #expander="{ openModal }">
      <div @click="openModal">
        <slot name="expander"></slot>
      </div>
    </template>
    <!-- TODO: think about refactoring "Dialog" to rename "dialog.title" to just "title" and so on with "content, title-after, ..." — N3EHu373 -->
    <template #dialog.top-right="{closeModal}">
      <div class="flex flex-row-reverse gap-2 items-center">
        <SaveAndExitSlideButtons />
        <a
          class="mr-2 uppercase font-bold hover:underline cursor-pointer"
          @click="closeModal"
        >
          Exit Full Screen
        </a>
      </div>
    </template>
    <template #dialog.content="{ closeModal, closeModalAfter }">
      <div class="h-[93%]">
        <div
          class="slide-editor-container h-full"
          style="display: flex;"
          tabindex="0"
          @keydown.prevent="keyboardKeysStageHandlers.onKeyDown"
          @keyup.prevent="keyboardKeysStageHandlers.onKeyUp"
        >
          <Stage :full-screen-mode="true" />
          <SidePanes :full-screen-mode="true" />
        </div>
      </div>
    </template>
    <template #dialog.subtitle>
      <!-- {{ slide.basicInfo.name }} TODO: remove the unnecessary double quotes arount slide name -->
    </template>
  </Dialog>
</template>
  
<script lang="ts" setup>
import Dialog from '@/components/Dialog/Dialog.vue';

import Stage from '../Stage/Stage.vue';
import SidePanes from '../PropertiesPanesRight/PropertiesPanesRight.vue';
import useKeyboardKeysStageHandlers from '../Stage/stageEvents/handlers/keyboardKeysStageHandlers';
import SaveAndExitSlideButtons from './SaveAndExitSlideButtons.vue';

const keyboardKeysStageHandlers = useKeyboardKeysStageHandlers();
</script>
  
<style lang="scss" scoped>
.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}

.button :deep(.button) {
  font-size: 1rem;
}

.slide-editor-container {
  flex-wrap: nowrap;
  padding: 0;
  justify-content: space-between;

  &:focus {
    outline: none;
  }
}
</style>