import { ref, nextTick } from 'vue';
import {
  isDrawMode,
  toggleDrawMode,
  drawModeCallback
} from '../../Stage/stageEvents/handlers/selectorRectangleStageHandlers';

import {
  sprites,
  spritesReversed,
  selectedTextSpriteField
} from '../slide';

import { fontsFamilies } from './fonts';


import { addSpriteToSelection } from '@/views/MediaEditorView/SlideEditor/utils/spritesSelection';

export const activeDrawType = ref<'text' | 'image' | null>();

export function toggleActivateDrawNewTextSprite() {
  toggleDrawMode();
  if(!isDrawMode.value) {
    activeDrawType.value = null;
    return;
  }
  activeDrawType.value = 'text';
  const addNewTextSprite = async (drawPositions) => {
    const newTextSprite = {
      type: "text",
      fill: "#000000",
      // stroke: "#000000",
      fontSize: 100,
      align: "left",
      isBold: false,
      isItalic: false,
      name: "",
      height: Math.abs(drawPositions.y2 - drawPositions.y1),
      width: Math.abs(drawPositions.x2 - drawPositions.x1),
      text: "",
      x: Math.min(drawPositions.x1, drawPositions.x2),
      y: Math.min(drawPositions.y1, drawPositions.y2),
      hidden: false,
      id: (spritesReversed.length + 1).toString(),    // this is a temporary generated sprite id for newly added sprites
      fontFamilyWithStyleName: fontsFamilies[0].fonts[0].name,
      fontFamily: fontsFamilies[0].name,
      fontStyle: "normal",
      textDecoration: "",
    };
    spritesReversed.unshift(newTextSprite);
    await nextTick();
    addSpriteToSelection(newTextSprite.id, {clearPrevious: true});
    isDrawMode.value = false;
    activeDrawType.value = null;
    await nextTick();
    selectedTextSpriteField.value.focus();
    selectedTextSpriteField.value.select();
    // setTimeout(()=> {
  };
  drawModeCallback.value = addNewTextSprite;
}

export function toggleActivateDrawNewImageSprite() {
  toggleDrawMode();
  if(!isDrawMode.value) {
    activeDrawType.value = null;
    return;
  }
  activeDrawType.value = 'image';
  const addNewImageSprite = async (drawPositions) => {
    const newImageSprite = {
      type: "image",
      image: null,
      src: null,
      x: Math.min(drawPositions.x1, drawPositions.x2),
      y: Math.min(drawPositions.y1, drawPositions.y2),
      width: Math.abs(drawPositions.x2 - drawPositions.x1),
      height: Math.abs(drawPositions.y2 - drawPositions.y1),
      rotation: 0,
      imageWidth: 0,
      imageHeight: 0,
      imageX: 0,
      imageY: 0,
      name: "",
      sourceAssetId: null,
      id: (sprites.length + 1).toString(),    // this is a temporary generated sprite id for newly added sprites
      fitment: "fill"
    };
    spritesReversed.unshift(newImageSprite);    // TODO: make sure that unshift usage here doesn't cause performance degredation in common usage scenarios.
    await nextTick();
    addSpriteToSelection(newImageSprite.id, {clearPrevious: true});
    isDrawMode.value = false;
    activeDrawType.value = null;
  };
  drawModeCallback.value = addNewImageSprite;
}