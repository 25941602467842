<!-- 
  TODO: think about resolving redundancies if needed between this & @\components\SlideEditor\TopHigherHorizontalBar — N36G3WBi
-->

<template>
<div class="top-bars">
  <div class="top-higher-horizontal-bar justify-between flex-wrap">
    <div class="flex flex-row items-center gap-2">
      <div class="component-title">
        Playlist Editor
      </div>
      <ReloadButton 
        tooltipText="Reload Playlist"
        :isLoading="isLoading"
        :reloadHandler="reloadPlaylist"
      />
    </div>
    <div class="flex items-center mb-2">
      <SelectField
        label="Sharing"
        :options="sharingLevelOptions"
        v-model="data.sharingLevel"
      />
    </div>
  </div>
  <PageSection>
    <div class="top-lower-horizontal-bar">
      <div class="main-fields">
        <TextField label="Name" v-model="data.name" :required="true" />
        <div class="flex items-center">
          <LabelCustomized label="Campaigns" />
          <UsedBy :items="data.campaigns" label="Campaigns"/>
        </div>
        <NumberField label="Default Duration" v-model="data.defaultItemDuration" :required="true" unit="Seconds" />
        <div class="flex items-center">
          <LabelCustomized label="Size" />
          <Size :size="data.size" />
        </div>
      </div>
      <!-- <component :is="renderers.UsedBy" :items="item.campaigns" label="Campaigns" /> -->
      <!-- <component :is="renderers.Size" :size="item.size" /> -->
      <!-- <SaveSlideButton /> -->
      <CreateAnotherPlaylistButton />
    </div>
  </PageSection>
  </div>
</template>

<script lang="ts" setup>
import PageSection from '@/components/layouts/PageSection.vue';
import ReloadButton from '@/components/ReloadButton/ReloadButton.vue';
import TextField from '@/components/form-fields/TextField.vue';
import NumberField from '@/components/form-fields/NumberField.vue';
import usePlaylist from '../composables/playlist';
import UsedBy from "@/components/ListingPages/ItemsLister/ItemsList/FieldsRenderers/UsedBy.vue"
import Size from "@/components/ListingPages/ItemsLister/ItemsList/FieldsRenderers/Size.vue"
import SelectField from '@/components/form-fields/SelectField/SelectField.vue';
import { sharingLevelOptions } from '@/data/options/sharingLevelOptions';
import LabelCustomized from '@/components/form-fields/LabelCustomized/LabelCustomized.vue';
import CreateAnotherPlaylistButton from '../CreateAnotherPlaylistButton/CreateAnotherPlaylistButton.vue';

const {
  data,
  reloadPlaylist,
  isLoading
} = usePlaylist();

</script>

<style lang="scss" scoped>
.component-title {
  color: #25294A;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.top-higher-horizontal-bar {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.top-lower-horizontal-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  .main-fields {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
  }
}

</style>