import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import { createUser, fetchRolesList, getUserById, updateUser } from "@/helpers/api/users/users";
import { getRegion } from "@/helpers/countries/countries";
import { getOrganizationById, getUpdateOrganizationItem, updateOrganization } from "@/helpers/api/organization/organization";
import { getModelFromViewCode } from "@/helpers/api/api";
export default function useOrganizationEditor() {
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]

  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const organizationId = ref("");
  const organizationModel  = ref ()
  const hasChanges = ref(false)
  
  const validationSchema = yup.object({
    name: yup.string().label('First name').required(),
  });

  const initialValues = {
    name: '',
    businessUnitNumber:'',
  
    
  };
  const { errors, handleSubmit, values, setFieldValue , isSubmitting } = useForm({ validationSchema, initialValues });

watch(values, (newFields,oldFields) => {

    hasChanges.value = true 
   
  if (hasChanges.value)
  {
    onBeforeLoad() ; 
  }else {
    removeBeforeLoad();
  }
})

watch(hasChanges, (newValue,oldValue,) => {
  if (newValue)
  {
    onBeforeLoad() ; 
  }else {
    removeBeforeLoad();
  }
})
function onBeforeLoad () {
  window.addEventListener('hashchange', showAlert);
  window.addEventListener('beforeunload',showAlert 
  );
}
function removeBeforeLoad () {
  window.removeEventListener('hashchange', showAlert);

  window.removeEventListener('beforeunload',showAlert)

  
}
function showAlert (event :any ) {
  event.returnValue = 'You have unfinished changes!';
}
  const submitForm = handleSubmit(handleSubmitValid, handleSubmitInvalid);
 async function loadOrganization (){
        const result = await getUpdateOrganizationItem(organizationId.value) ;
        organizationModel.value = getModelFromViewCode(result) ;
        console.log('ss');
        console.log(organizationModel.value)
        getOrganizationById(organizationId.value).then(res => {
            values.name = res[0].name
            values.businessUnitNumber = res[0].businessUnitNumber
            setTimeout(() => {
                hasChanges.value = false 
            }, 0);
        })
       
}

  async function handleSubmitValid() {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      isSuccess.value = false;
      const submissionResponse = await updateOrganization( organizationId.value, values);
      isLoading.value = false;
      if ( typeof submissionResponse[0]?.name === "string") {
        isSuccess.value = true;
       hasChanges.value = false 
      }
     
      if (submissionResponse.errors && submissionResponse.errors[0]?.message){
        errorMessage.value = submissionResponse.errors[0]?.message
      }
    } catch (error :any ) {
      console.log(error)
      isError.value = true;
      isLoading.value = false;
      errorMessage.value ='Something went wrong';
    }
  }
  async function handleSubmitInvalid() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }


  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    organizationId,
    hasChanges,
    removeBeforeLoad,
    loadOrganization,
    organizationModel
  };
}
