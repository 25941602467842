<template>
  <div class="preview-content">
    <div class="asset-name">{{ currentItem.name }} </div>
    <div :class='["preview-area", {video: currentItem.assetType == "video"}]'>
      <video
        :key="currentItem.id"
        v-if="currentItem.assetType == 'video' || AssetTypesMapping[currentItem.assetType] == 'video'"
        autoplay
        controls
        class="video-preview"
      >
        <source
          v-for="version in currentItem.versions"
          :src="version.resource.publicUrl"
          :type="version.resource.mimeType"
        >
      </video>
      <ImageWithLoadingAndErrorIndicators
        v-else
        :src="`${currentItem.sourceUri}?lastModified=${currentItem.dateLastModified}`"
        :key="currentItem.id"
        :revalidateIfLessThanAge=60000
        :dateLastModified="currentItem.dateLastModified"
        :refreshAfterIfRevalidate=30000
      />
    </div>
    <div class="d_flex" v-if="!item">
      {{ parseInt(currentItemIndex) + 1 }} of {{ filteredListItems.length }}
      <div class="button">
        <button
          :class='["btn previous", {inactive: currentItemIndex == 0}]'
          @click="stepPreviousItem"
          :disabled="currentItemIndex == 0"
        ></button>
        <button
          :class='["btn next", {inactive: currentItemIndex == filteredListItems.length-1}]'
          @click="stepNextItem"
          :disabled="currentItemIndex == filteredListItems.length-1"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import AssetTypesMapping from '@/data/mappings/AssetTypes.map';
import ImageWithLoadingAndErrorIndicators from '@/components/ImageWithLoadingAndErrorIndicators/ImageWithLoadingAndErrorIndicators.vue';
import { log } from "@/helpers/utils";

const props = defineProps(["filteredListItems", "startIndex", "item"]);
// TODO: think about creating a separate folder called "mappings" to include all mappings like this one in a common place [DSP-N1VCNo0A]
const currentItem = computed(() => {
  if(!props.item && props.filteredListItems && !isNaN(props.startIndex)) {
    return props.filteredListItems[currentItemIndex.value]
  } else {
    return props.item
  }
});
const currentItemIndex = ref(props.startIndex);
const stepNextItem = () => {
  const nextStepIndex = Math.min(currentItemIndex.value + 1, props.filteredListItems.length-1);
  log({nextStepIndex}, {ns: 'nextStepIndex'});
  currentItemIndex.value = nextStepIndex;
};
const stepPreviousItem = () => {
  const previousStepIndex = Math.max(currentItemIndex.value - 1, 0)
  log({previousStepIndex}, {ns: 'previousStepIndex'});
  currentItemIndex.value = previousStepIndex;
};
</script>

<style lang="scss" scoped>

.preview-content {
  @include small-devices-h-down {
    height: 75%;
  }

  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  :deep(.image-preview) {
    /* height: 70%; */
    /* max-width: 90%;
    max-height: 70%; */
    /* border: 1px solid rgb(102, 102, 102); */
    /* inset: 0px; */
    /* margin: auto; */
    /* position: absolute; */
  }

  .preview-area {
    height: 86%;

    /* TODO: think about a better approach to handle responsive height [DSP-N22Dkm5m] */
    @include screen-height-down(620px) {
      height: 75%;
    }

    @include screen-height-down(440px) {
      height: 60%;
    }

    width: 100%;
    align-self: center;
    display: flex;
    justify-content: center;
    border: 1px solid rgb(102, 102, 102);

    :deep(.image-loaded), :deep(.image-error), :deep(.image-loading) {
      height: 100%;
      max-width: 100%;
      object-fit: contain;
    }

    :deep(.image-loading), :deep(.image-error) {
      width: 42px;
    }
    &.video {
      background-color: rgb(192, 192, 192);
    }
    .video-preview {
      align-self: center;
    }
  }
}

.asset-name {
  font-weight: bold;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 0;
  font-size: 20px;
  color: #25294A;
}
.video-preview {
  width: 100%;
  height: 60%;
}

.d_flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btn {
  width: 20px;
  height: 20px;
  margin: 4px;
  &:focus {
    outline: 0;
  }
  &.next {
    background: url(@/assets/images/next-button.png);
    &.inactive {
      filter: grayscale(1);
      opacity: 0.4;
    }
    &:hover {
      background: url(@/assets/images/next-button-hover.png);
    }
  }
  &.previous {
    background: url(@/assets/images/previous-button.png);
    &.inactive {
      filter: grayscale(1);
      opacity: 0.4;
    }
    &:hover {
      background: url(@/assets/images/previous-button-hover.png);
    }
  }
}
</style>
