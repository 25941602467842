import useCampaignsListHelpers from '../CampaignsLister/campaignsListHelpers';
import { computed, ref } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import { submitCreateCampaignItem } from "@/helpers/api/campaign/campaign";
import { getKeyByValue } from '@/helpers/utils';
import ApprovalStatesMapping from '@/data/mappings/ApprovalStates.map';

// import useCampaignList from "@/views/CampaignView/CampaignLister/composables/campaignList";
export default function useCreateCampaignForm() {
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]
  const { mutateList } = useCampaignsListHelpers();
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const newCampaignId = ref("");

  const validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    startDate: yup.date().label('Start Date').required(),
    endDate: yup.date().label('End Date').required(),
    isEnabled:  yup.boolean().label('Active')
  });

  const initialValues = {
    name: "",
    startDate: new Date().toISOString().slice(0,10),
    endDate: new Date(new Date().setMonth(new Date().getMonth()+1)).toISOString().slice(0,10),
    isEnabled: true,
    approvalState: getKeyByValue(ApprovalStatesMapping, "Approved"),
    sharingLevel: 1,
  };

  const { errors, handleSubmit, values, setFieldValue , isSubmitting } = useForm({ validationSchema, initialValues });
  const fieldsToSubmit = computed(() => {
    const { name, startDate, endDate, isEnabled, approvalState, sharingLevel} = values;
    return {
      name,
      startDate,
      endDate,
      isEnabled,
      approvalState,
      sharingLevel
    };
  });

  const submitForm = handleSubmit(handleSubmitValid, handleSubmitInvalid);

  async function handleSubmitValid() {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      fieldsToSubmit.value.endDate = new Date (fieldsToSubmit.value.endDate).getTime() as any 
      fieldsToSubmit.value.startDate = new Date (fieldsToSubmit.value.startDate).getTime() as any 

      const submissionResponse = await submitCreateCampaignItem(
        fieldsToSubmit.value
      );
      isLoading.value = false;
      if (typeof submissionResponse[0].name === "string") {
        // if is successful
        isSuccess.value = true;
        newCampaignId.value = submissionResponse[0].id;
        mutateList();
      }
    } catch (error) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
  }

  async function handleSubmitInvalid() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }
  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    newCampaignId
  };
}
