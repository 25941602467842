<template>
<table class="layers-table">
  <tbody>
    <draggable 
      :list="spritesReversed"
      item-key="id"
      group="sprites" 
      @start="drag=true" 
      @end="drag=false" 
    >
      <template #item="{ element }">
        <tr
          @click="selectSpriteFromLayersPaneClickHandler($event, element.id)"
          :class="['sprite-item-row', {
            'selected': isSpriteSelected(element.id),
            'hidden': element.isLockedByOriginalOwner && isShowEditableObjects
          }]"
        >
          <td class="options-cell">
            <OldThemeIcon
              name='eye'
              :class="{ 'hidden-sprite': element.hidden}"
              @click="toggleSpriteVisibility(element.id)"
            />
            <OldThemeIcon
              name='lock'
              :class="[{'hidden': !element.isLockedByOriginalOwner}, 'mt-0.5']"
            />
          </td>
          <td class="preview-cell">
            <img
              v-if="element.type == 'image'"
              :src="element.src"
              style="width: 20px;"
            />
            <div
              v-else-if="element.type == 'text'"
              class="text-preview"
            >
              T
            </div>
          </td>
          <td class="label-cell">
            <div class="label">
              <template
                v-if="element.type == 'image'"
              >
                {{element.name || "Image (Empty)" }}
              </template>
              <template
                v-if="element.type == 'text'"
              >
                {{element.text || "Text (Empty)" }}
              </template>
            </div>
          </td>
          <td class="remove-cell">
            <OldThemeIcon
              name='delete'
              @click.stop="removeSprite(element.id)"
              class="cursor-pointer"
            />
          </td>
        </tr>
      </template>
    </draggable>
  </tbody>
  
</table>
</template>

<script lang="ts" setup>
import draggable from 'vuedraggable';

import { ref, computed, reactive, toRefs, watch } from 'vue';

import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';

import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';

const {
  sprites,
  spritesReversed,
  clearSpritesSelection,
  addSpriteToSelection,
  removeSpriteFromSelection,
  toggleSpriteVisibility,
  isSpriteSelected,
  removeSprite,
  isShowEditableObjects
} = useSlide();


function selectSpriteFromLayersPaneClickHandler(e: any, id: string) {
  const modifierPressed = e.shiftKey || e.ctrlKey || e.metaKey;
  const isAlreadySelected = isSpriteSelected(id);
  if (!modifierPressed) {
    // if meta key is not pressed and whether sprite is selected or not
    addSpriteToSelection(id, {clearPrevious: true})
  } else if (modifierPressed && isAlreadySelected) {
    removeSpriteFromSelection(id);
  } else if (modifierPressed && !isAlreadySelected) {
    addSpriteToSelection(id);
  }
}

const drag = ref(true);

</script>

<style lang="scss" scoped>
.layers-table {
  border-collapse: collapse;
  width: 100%;
}

.sprite-item-row {
  background-color: #e6e6e6;
  border-bottom: 1px solid #ffffff;
  cursor: grabbing;
  height: 50.5px;
  vertical-align: middle;
  &.selected {
    background-color: #00ccff;
  }
  cursor: default;
}

.options-cell {
  border-right: 1px solid #ffffff;
  vertical-align: top;
  padding: 8px 11px;
  cursor: pointer;
}

.icon .hidden-sprite {
  opacity: 0.4;
}

.preview-cell {
  padding: 0 0.675rem;
  height: 50.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.text-preview {
  background-color: #ffffff;
  border: 1px solid #262626;
  font-family: Times, Times New Roman;
  font-size: 24px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
}

.label-cell {
  width: 100%;
}
.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include theme-old {
    width: 100px;
  }
  @include theme-new {
    width: 152.5px;
  }
}

.remove-cell {
  text-align: left;
  padding: 0 8px;
  vertical-align: middle;
}
</style>



