import SpriteTypesMapping from '@/data/mappings/SpriteTypes.map';
import { fontsFamilies } from './fonts';
import { log } from '@/helpers/utils';

export function prepareSpriteFromRetrieved(spriteRetrieved) {
  const spritePrepared = {} as any;
  
  // TODO: import & use "SpriteTypesMapping" instead — N2LCjq1j
  const spriteTypeIndex = spriteRetrieved.spriteType;
  const spriteTypeName = SpriteTypesMapping[spriteTypeIndex];
  spritePrepared['type'] = spriteTypeName;
  
  if (spritePrepared.type == 'text') {
    const {
      fontColor,
      fontSize,
      horizontalAlign,
      isBold,
      isItalic,
      name,
      height,
      width,
      text,
      x,
      y,
      id,
      isLockedByOriginalOwner
    } = spriteRetrieved;
    log({spriteRetrieved}, {color: "purple"});
    // TODO think if there's a cleaner way to write this — N2LDII54
    const spriteFontRetrieved = {};
    if (spriteRetrieved.font !== null) {
      const {
        font: { name: fontName }
      } = spriteRetrieved;
      // find font family name
      const fontFamilyMatched = fontsFamilies.find(fontFamily => fontFamily.fonts.find(font => font.name === fontName));
      Object.assign(spriteFontRetrieved, {
        fontFamilyWithStyleName: fontName,   // the actual "font name" being set and applied by Konva
        fontFamily: fontFamilyMatched.name  // the parent font family for the currently applied font
      });
    } else {
      Object.assign(spriteFontRetrieved, {
        fontFamilyWithStyleName: fontsFamilies[0].name,
        fontFamily: fontsFamilies[0].name
      });
    }
    log({spriteFontRetrieved})
    // prepare fontStyle & textDecoration
      // TODO: textDecoration is not yet supported by backend (for underline & ling-through support), to add support for it later if needed
    const preparedFontStyle = (isBold ? "bold" : "normal") + ((isBold && isItalic) ? " " : "") + (isItalic ? "italic" : "");

    log({preparedFontStyle});
    
    Object.assign(spritePrepared, {
      fill: (fontColor !== 0 ? "#"+fontColor.toString(16) : "#000000"),
      // stroke: "#"+fontColor.toString(16),    // TODO: think about if we need stroke for text
      fontSize,
      ...spriteFontRetrieved,
      align: horizontalAlign,
      fontStyle: preparedFontStyle,
      textDecoration: "",
      isBold,
      isItalic,
      name,
      height,
      width,
      text,
      x,
      y,
      hidden: false,
      id: id.toString(),
      isLockedByOriginalOwner
    });
  }

  else if (spritePrepared.type == 'image') {
    const {
      height,
      width,
      imageX,
      imageY,
      imageWidth,
      imageHeight,
      x,
      y,
      id,
      isLockedByOriginalOwner
    } = spriteRetrieved;
    // prepare fitment
    let fitment: "best-fit" | "fill" | "original";
    if (imageX !== 0 || imageY !== 0) {
      fitment = "best-fit";
    }
    else if (imageWidth === width && imageHeight === height) {
      fitment = "fill"
    }
    else {
      fitment = "original"
    }


    // TODO think if there's a cleaner way to write this — N2LDII54
    const spriteAssetRetrieved = {};
    if (spriteRetrieved.asset !== null) {
      const {
        asset: {
          sourceUri: src,
          name,
          id: sourceAssetId,
          sourceWidth: sourceAssetWidth,
          sourceHeight: sourceAssetHeight
        }
      } = spriteRetrieved;

      Object.assign(spriteAssetRetrieved, {
        src,
        name,
        sourceAssetId,
        sourceAssetWidth,
        sourceAssetHeight
      });
    } else {
      Object.assign(spriteAssetRetrieved, {
        src: null,
        name: null,
        sourceAssetId: null,
        sourceAssetWidth: null,
        sourceAssetHeight: null
      });
    }

    Object.assign(spritePrepared, {
      type: 'image',
      image: null,
      x,
      y,
      // debug/dev temporary
        // scaleX: 0.6,
        // scaleY: 0.6,
      imageWidth,
      imageHeight,
      imageX,
      imageY,
      height,
      width,
      rotation: 0,
      hidden: false,
      ...spriteAssetRetrieved,
      id: id.toString(),
      crop: (
        fitment === 'original' ?
          {
            x: 0,
            y: 0,
            width: imageWidth,
            height: imageHeight
          } :
          null
      ),
      fitment,
      isLockedByOriginalOwner
    });
  }

  // debug/dev temporary to remove later
    // spritePrepared.x = 30;
    // spritePrepared.y = 30;
    // spritePrepared.width = 100;
    // spritePrepared.height = 100;
  
  return spritePrepared;
}