<template>
  <!-- {{ columnsConfig }} -->
  <ItemsTableList v-if="listViewType === 'table'" :singleSelect="singleSelect" :selectMode="selectMode">
    <template #item="props">
      <TableViewItem v-bind="props"  />
    </template>
  </ItemsTableList>
  <ItemsGridList v-else-if="listViewType === 'grid'" />
</template>

<script lang="ts" setup>
import ItemsTableList from './TableView/ItemsTableList.vue';
import ItemsGridList from './GridView/ItemsGridList.vue';

import { inject } from 'vue';
defineProps<{
  singleSelect?: boolean;
  selectMode?:boolean
}>();

const { listViewType, columnsConfig } = inject('itemsListHelpers');;
const TableViewItem = inject('TableViewItem');

const ListWrapper = "To contain the component that will wrap the list";
const ItemWrapper = "To contain the component that will wrap the item";


</script>

<style lang="scss" scoped>

</style>