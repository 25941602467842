import useApi from '../../api';

import store1PosSource_mockData from '@/data/mock-data/pos/store1/posSource';

export async function fetchPOSSourceItemById_dummy(     // used for offline testing only
  itemId: number|string
) {
  // const {get} = useApi();
  try {
    // const { responseBody } = await get('api/posSources/'+itemId, {isLogoutOnInvalidAuth: true})
    const responseBody = getDymmyPOSSourceItemById(itemId);
    return responseBody;
  } catch(err) {
    throw err;
  }
}



async function getDymmyPOSSourceItemById(itemId: number|string) {
  return [ store1PosSource_mockData ];
}