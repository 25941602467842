<template>
<div class="theme-provider" :theme="theme">
  <slot></slot>
</div>
</template>

<script lang="ts" setup>
import useTheme from './theme';
const { theme } = useTheme();
</script>

<style lang="scss" scoped>
.theme-provider {
  height: 100%;
  width: 100%;
}
</style>