<template>
  <TopBar />
  <PageSection>
    <POSItemsList />
  </PageSection>
  <FloatingFooterButtons>
    <div class="flex flex-row-reverse gap-2">
      <SavePOSSourceButton ref="savePOSSourceButtonRef" />
      <ExitButton
        v-bind="{hasUnsavedChanges, unsetHasUnsavedChanges}"
        @exit="onExit"
        @saveAndExit="onSaveAndExit"
      />
    </div>
  </FloatingFooterButtons>
</template>


<script setup lang="ts">
import PageSection from '@/components/layouts/PageSection.vue';
import TopBar from './TopBar/TopBar.vue';
import {onBeforeMount, onMounted, reactive, ref, watchEffect}  from 'vue'
import { useRoute } from 'vue-router'
import usePOSSource from './composables/posSource';
import FloatingFooterButtons from '@/components/layouts/FloatingFooterButtons.vue';
import SavePOSSourceButton from './SavePOSSourceButton/SavePOSSourceButton.vue';
import POSItemsList from './POSItemsList/POSItemsList.vue';
import ExitButton from '@/components/Dialog/ButtonsWithDialog/ExitButton/ExitButton.vue';
import router from '@/router';

const { loadPOSSource, initializeHasUnsavedChangesWatcher, hasUnsavedChanges, unsetHasUnsavedChanges } = usePOSSource();
const route = useRoute()

const currentPOSSourceId = route.params.id;
const savePOSSourceButtonRef = ref();

function onExit() {
  router.push({ path: '/pos-sources' })
}

async function onSaveAndExit() {
  await savePOSSourceButtonRef.value.savePOSSource();
  router.push({ path: '/pos-sources' })
}


onBeforeMount(async () => {
  await loadPOSSource(currentPOSSourceId);
  initializeHasUnsavedChangesWatcher();
});

</script>



<style lang="scss" scoped>
</style>
