import useApi from '../api';
import { 
  getImageSize,
  getVideoSizeAndDuration,
  isImage,
  isVideo
} from '@/helpers/utils';
// import { log } from '@/helpers/utils';

export async function requestAddUploadedMedia(fileData: File, s3ObjectKey: string) {
  const { post } = useApi();

  let mediaInfo;
  if (isImage(fileData)) {
    const imageSize = await getImageSize(fileData);
    mediaInfo = imageSize;
  }

  if (isVideo(fileData)) {
    const videoSizeAndDuration = await getVideoSizeAndDuration(fileData);
    mediaInfo = videoSizeAndDuration;
  }
  
  const fileInfoData = {
    ...mediaInfo,
    s3ObjectKey: s3ObjectKey,
    mimeType: fileData.type,
    fileName: fileData.name,
    fileSize: fileData.size,
  }

  // log({fileInfoData});

  const { responseBody } = await post(
    'assets?data='+encodeURIComponent('{}')+'&fileData='+encodeURIComponent(fileData.name),
    fileInfoData,
    {
      isLogoutOnInvalidAuth: true,
      config: {
        headers: {
          'Content-Type': 'application/octet-stream',
          'X-Create-From-Previously-Uploaded-S3-Info': 'true',
        },
      }
    }
  );
  const responseData = responseBody;
  return responseData;
}

export async function requestGetS3UploadUrl(fileExtension: string) {
  const { get } = useApi();
  const { responseBody } = await get('Assets/GenerateS3DirectUploadLink/'+fileExtension, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}


export async function requestUploadMediaFileToS3(fileData: File, onUploadProgress: Function, abortSingal: AbortSignal, s3UploadUrl: string) {
  const { put } = useApi();
  const fileBinaryData = await fileData.arrayBuffer();
  
  const { response } = await put(
    '',
    fileBinaryData,
    {
      baseUrl: s3UploadUrl,
      isLogoutOnInvalidAuth: true,
      config: {
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          'X-Mime-Type': fileData.type,
          'X-File-Name': encodeURIComponent(fileData.name)
        },
        onUploadProgress,
        signal: abortSingal
      }
    }
  );
  const responseData = {
    success: response.status == 200
  }
  return responseData;
}