<template>
  <SimpleEntityListingPage
    v-bind="listNamesConfig"
    :ItemsLister="MediaLister"
  >
  
    <template #top-bar.main-buttons v-if="user.hasCreateAccessToArea('MediaLibrary')">
      <UploadMediaButtonWithDialog
        v-if="
          user.hasCreateAccessToAssetType('Image') ||
          user.hasCreateAccessToAssetType('Video')
        "
      />
      <CreateSlideButtonWithDialog
        v-if="user.hasCreateAccessToAssetType('Slide')"
      />
    </template>
  </SimpleEntityListingPage>
</template>

<script lang="ts" setup>
import MediaLister from './MediaLister/MediaLister.vue';
import listNamesConfig from "./MediaLister/config/listNames.config";
import SimpleEntityListingPage from '@/components/ListingPages/SimpleEntityListingPage/SimpleEntityListingPage.vue';
import UploadMediaButtonWithDialog from './TopBarMainButtons/UploadMedia/UploadMediaButtonWithDialog.vue';
import CreateSlideButtonWithDialog from './TopBarMainButtons/CreateSlide/CreateSlideButtonWithDialog.vue';
import useUser from '@/composables/user';

const user = useUser();
</script>

<style lang="scss" scoped>
</style>