<template>
  <ul :class="['options',{'vertical': vertical}]">
    <li
      v-for="option in options"
      @click="$emit('update:modelValue', option[valueKeyPrepared])"
      :key="option[valueKeyPrepared]"
      :class="['option', { selected: option[valueKeyPrepared] == modelValue }, {'vertical': vertical},{
        'with-extra-buttons': OptionsExtraButtons
      }]">
      {{ option[labelKeyPrepared] }}
      <component v-if="OptionsExtraButtons" :is="OptionsExtraButtons" :value="option[valueKeyPrepared]" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { log } from "@/helpers/utils";
import { computed, ref } from "vue";

const props = defineProps({
  'modelValue': {
  },
  'options': {
  },
  'vertical': {
    type: Boolean,
  },
  'labelKey': {
    type: String
  },
  'valueKey': {
    type: String
  },
  'OptionsExtraButtons': {}
})

// log(props.labelKey, {ns: "hey"})
const labelKeyPrepared = props.labelKey ? props.labelKey : 'label';
const valueKeyPrepared = props.valueKey ? props.valueKey : 'value';

</script>

<style scoped lang="scss">
.options {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 15px;
  &.vertical {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
  .option {
    cursor: pointer;
    text-transform: capitalize;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
    &:not(:last-of-type) {
      position: relative;
      &:not(.vertical)::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: #e6e6e6;
        height: 10px;
        width: 2px;
        right: -8px;
      }
    }
  }
  .selected {
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: #DCDCDC !important;
    text-underline-position: under;
  }
  .with-extra-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
</style>