<template>
<div
  class="
    font-bold neutral-800
    mb-3 pb-3
    border-b-gray-200 border-b-solid border-b
    flex items-center
    select-none
  "
>
  <input
    type="checkbox"
    name="isLockedByOriginalOwner-checkbox"
    id="isLockedByOriginalOwner-checkbox"
    v-model="selectedSprite.isLockedByOriginalOwner"
    class="mr-2"
  />
  <label for="isLockedByOriginalOwner-checkbox">
    Lock object for copies
  </label>
</div>
</template>

<script lang="ts" setup>
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import type { Sprite } from '@/helpers/types/media/slide/Sprite';
import type { ComputedRef } from 'vue';
const {selectedSprite} = useSlide() as {selectedSprite: ComputedRef<Sprite>};
</script>

<style lang="scss" scoped>

</style>