<template>
  <div class="top-bars">
    <TopHigherHorizontalBar />
    <PageSection>
      <TopLowerHorizontalBar />
    </PageSection>
    
  </div>
  <PageSection>

    <div
      class="slide-editor-container"
      style="display: flex;"
      tabindex="0"
      @keydown.prevent="keyboardKeysStageHandlers.onKeyDown"
      @keyup.prevent="keyboardKeysStageHandlers.onKeyUp"
    >
      <Stage />
      <SidePanes/>
    </div>
  </PageSection>
</template>

<script lang="ts" setup>
import Stage from './Stage/Stage.vue';
import SidePanes from './PropertiesPanesRight/PropertiesPanesRight.vue';
  // to rename /think-name later ASAP: PropertiesPanesRight
import TopHigherHorizontalBar from './TopHigherHorizontalBar/TopHigherHorizontalBar.vue';
import TopLowerHorizontalBar from './TopLowerHorizontalBar/TopLowerHorizontalBar.vue';
import PageSection from '@/components/layouts/PageSection.vue';
import useKeyboardKeysStageHandlers from './Stage/stageEvents/handlers/keyboardKeysStageHandlers';
import useSlide from './composables/slide';
import TagsField from '@/components/form-fields/TagsField/TagsField.vue'
const { initializeSlideEditorHelperWatchers } = useSlide();
initializeSlideEditorHelperWatchers();

const keyboardKeysStageHandlers = useKeyboardKeysStageHandlers();

defineProps(["data"]);

</script>

<style lang="scss" scoped>
.slide-editor-container {
  flex-wrap: nowrap;
  padding: 0;
  justify-content: space-between;

  &:focus {
    outline: none;
  }
}
</style>