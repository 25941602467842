import ApprovalStatesMapping from '@/data/mappings/ApprovalStates.map';

export const approvalStateOptions = [
  // TODO: think about a cleaner way to write this. (may be separate this options config if it's reusable)
  {
    label: ApprovalStatesMapping[2],  // Pending
    value: 2
  },
  {
    label: ApprovalStatesMapping[0],  // Not Approved
    value: 0
  },
  {
    label: ApprovalStatesMapping[1],  // Approved
    value: 1
  },
];