<template>
  <!-- TODO: think about if there's a redundancy between ~ similar components (e.g. CreateSlideForm.vue) — N279CX6T  -->
  <div class="upload-media-files-form">
    <h2 class="upload-media-files-form-subtitle">Add images and videos</h2>
    <div class="description">
      <p>
        To upload files, click on the button below and select them from the window.
        <br />
        <br />
      </p>
      <p>
        Acceptable file formats: JPEG, JPG, PNG, MP4, AVI, MKV, WEBM
        <p v-if="!user.isAuditor()">
          <b>Note: </b>Uploaded images and videos are subject to approval before being available for use.
        </p>
        <br />
        File size limit: 500Mb
      </p>
    </div>
    <div class="form-middle-button">
      <FileSelectButton
        label="Upload New"
        multiple
        @change="handleFilesSelectionChange"
        :accept="acceptableFileTypes.map(i => '.'+i).join(', ')"
      />
    </div>
    <div
      v-if="selectedFiles.length"
      class="selected-files"
    >
      <FileUploadSatusItem
        v-for="(selectedFile) in selectedFiles"
        :file="selectedFile"
        :key="selectedFile.id"
        @cancel="handleFileCancel(selectedFile.id)"
        class="file-upload-item"
      />
    </div>
    
    <div class="form-bottom-buttons">
      <ButtonCustomized label="Cancel" @click="$emit('cancel');" secondary />
      <ButtonCustomized label="Done" @click="$emit('done');" />
    </div>
  </div>
  
</template>

<script lang="ts" setup>
import { inject, reactive } from "vue";
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import FileSelectButton from "@/components/form-fields/FileSelectButton/FileSelectButton.vue";
import FileUploadSatusItem from "./FileUploadSatusItem/FileUploadSatusItem.vue";
import useUploadMediaFormHelpers from "./composables/uploadMediaFormHelpers";
import useUser from '@/composables/user';
const user = useUser();
defineEmits(['done', 'cancel']);

const { submitDirectUploadFileToS3 } = useUploadMediaFormHelpers();

const selectedFiles = reactive<any[]>([]);
const acceptableFileTypes = ['jpeg', 'jpg', 'png', 'mp4', 'avi', 'mkv', 'webm'];
const fileSizeLimit = 500 * 1024 * 1024; // 500 MB
function handleFilesSelectionChange(files: []) {
  // check if all selected files are valid
  const isAllSelectedFilesValid = [...files].every((file: any) => {
    const fileExt = file.name.match(/[^\.]*$/)?.[0]?.toLowerCase();
    const isAcceptableFileType = acceptableFileTypes.includes(fileExt)
    const isAcceptableFileSize = file.size <= fileSizeLimit;
    return isAcceptableFileType && isAcceptableFileSize;
  })
  if(!isAllSelectedFilesValid) {
    alert("Sorry, but we cannot upload one or more of these files.\nThe maximum file size is 500Mb\nAcceptable file types are:\nJPEG, JPG, PNG, MP4, AVI, MKV, WEBM");
    return;
  }

  // add & upload selected files
  files.forEach(async (file: any) => {
    const fileReactiveState = reactive({
      id: file.name+'-'+Date.now()
    });
    selectedFiles.push(fileReactiveState)
    await submitDirectUploadFileToS3(file, fileReactiveState);
  });
}

function handleFileCancel(fileId: string) {
  const fileToCancelIndex = selectedFiles.findIndex((file) => file.id === fileId);
  selectedFiles.splice(fileToCancelIndex, 1);
}

</script>

<style lang="scss" scoped>
.upload-media-files-form-subtitle {
  font-weight: bold;
  max-width: 400px;
  white-space: nowrap;
  padding: 5px 0;
  font-size: 20px;
  color: #25294A;
}

.upload-media-files-form {
  display: flex;
  flex-direction: column;
  max-width: 28rem;
}

.form-middle-button {
  display: flex;
  justify-content: center;
}
.form-bottom-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}

.file-upload-item {
  margin-top: 1rem;
}
</style>




