import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import { createUser, fetchRolesList, getUserById, updateUser } from "@/helpers/api/users/users";
import { getRegion } from "@/helpers/countries/countries";
import { getKeyByValue } from "@/helpers/utils";
import GroupAccessTypesMapping from "@/data/mappings/GroupAccessTypes.map";
export default function useUserEditorForm() {
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]

  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const userId = ref("");
  const selectedGroup  = ref ()
  const roles = ref () 
  const hasChanges = ref (false)
  const userLoaded = ref (false)
  const showPasswordField = ref(false)
  const userName = ref ()
  const userFlags = ref (
    {
        None: 0,
    
        Auditor: 1,
        AccountAdmin: 2,
        SuperUser:4,
        Analytics: 8
    })
    const regions = getRegion();

  const validationSchema = yup.object({
    firstName: yup.string().label('First name').required(),
    lastName : yup.string().label('Last name').required(),
    username : yup.string().label('Email address').required(),
    group : yup.object().label('Organization').required()

  });

  const initialValues = {
    firstName: null,
    lastName:null,
    username :null,
    phone:"",
    address1:"",
    address2 :"",
    region:"" as any ,
    country:"" as any ,
    state :""as any,
    city:"",
    zip:"",
    roles:[] as any[],
    group : null as any ,
    flags:0,
    auditorFlag : false , 
    superUserFlag : false ,
    accountFlag : false , 
    analyticsFlag : false ,
    newPassword1:'',
    newPassword2: '',
    currentPassword:'',
    groupAccess: getKeyByValue(GroupAccessTypesMapping, "LocalUpDown")
  };
  const { errors, handleSubmit, values, setFieldValue , isSubmitting } = useForm({ validationSchema, initialValues });

watch(values, (newFields,oldFields) => {
   if (userLoaded.value)
   {
    hasChanges.value = true 
   }else {
    hasChanges.value = false 
   }
  if (hasChanges.value)
  {
    onBeforeLoad() ; 
  }else {
    removeBeforeLoad();
  }
})

watch(hasChanges, (newValue,oldValue,) => {
  if (newValue)
  {
    onBeforeLoad() ; 
  }else {
    removeBeforeLoad();
  }
})
function onBeforeLoad () {
  window.addEventListener('hashchange', showAlert);
  window.addEventListener('beforeunload',showAlert 
  );
}
function removeBeforeLoad () {
  window.removeEventListener('hashchange', showAlert);

  window.removeEventListener('beforeunload',showAlert)

  
}
function showAlert (event :any ) {
  event.returnValue = 'You have unfinished changes!';
}
  const submitForm = handleSubmit(handleSubmitValid, handleSubmitInvalid);
 
  function fillUserModel () {
    if (values.accountFlag)
    {
      values.flags += userFlags.value.AccountAdmin
    }
    if (values.superUserFlag)
    {
      values.flags += userFlags.value.SuperUser
    }
    if (values.analyticsFlag)
    {
      values.flags += userFlags.value.Analytics
    }
    if (values.auditorFlag)
    {
      values.flags += userFlags.value.Auditor
    }
  }
  async function getUserData(){
   const result = await getUserById(userId.value);
   roles.value = result.availableRoles;
   bindUserModel(result.entities[0])
  }
  function bindUserModel(user:any){
    values.address1 = user.address1
    values.address2 = user.address2
    values.city = user.city

    user.roles.forEach((item:any ) => {
      let userRole = roles.value.filter((role:any) => role.id == item.id)[0];
      values.roles.push(userRole)
    })
    values.region = regions.filter(item => item.name==user.region )[0]
    values.country = values?.region?.countries.filter((item:any) => item.name == user.country)[0]
    values.state = values?.country?.states.filter((item:any) => item.name == user.state)[0]
    values.zip = user.zip
    values.group = user.group
    values.phone = user.phone
    values.accountFlag = (user.flags & userFlags.value.AccountAdmin) >0 ? true : false 
    values.analyticsFlag = (user.flags & userFlags.value.Analytics) >0 ? true : false 
    values.auditorFlag = (user.flags & userFlags.value.Auditor) >0 ? true : false 
    values.superUserFlag = (user.flags & userFlags.value.SuperUser) >0 ? true : false 
    values.firstName = user.firstName
    values.lastName = user.lastName
    values.username = user.username
    userName.value = user.username
    setTimeout(() => {
      setLoaded();

    }, 0);
  }
  function setLoaded (){
    userLoaded.value = true 
  }
  async function handleSubmitValid() {
    try {
      fillUserModel();
      let userModel = {...values}
      userModel.country = values.country?.name
      userModel.state = values.state?.name
      userModel.region = values.region?.name 
      errorMessage.value = "";
      isLoading.value = true;
      isSuccess.value = false;
      const submissionResponse = await updateUser( userId.value, userModel);
      isLoading.value = false;
      if ( typeof submissionResponse[0]?.username === "string") {
        isSuccess.value = true;
        userName.value =submissionResponse[0]?.username
       hasChanges.value = false 
      }
     
      if (submissionResponse.errors && submissionResponse.errors[0]?.message){
        errorMessage.value = submissionResponse.errors[0]?.message
      }
    } catch (error :any ) {
      console.log(error)
      isError.value = true;
      isLoading.value = false;
      errorMessage.value ='Something went wrong';
    }
  }
  async function handleSubmitInvalid() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }
  async function getRolesList (){
    const result = await fetchRolesList ({limit:100, offset:0})
    roles.value= result.results
  }

  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    userId,
    getRolesList,
    selectedGroup,
    roles,
    hasChanges,
    removeBeforeLoad,
    getUserData,
    regions,
    showPasswordField,
    userName
  };
}
