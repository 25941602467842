<template>
    <Dialog ref="deleteDialog">
      <template #expander="{ openModal }">
        <OldThemeIcon
        name='delete'
        @click.stop="openModal"
        :class="['button']"
      />
      </template>
      <template #dialog.title>Warning</template>
      <template #dialog.content="{ closeModal, closeModalAfter }">
  
      
          <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
          <div class="delete-item-form">
            <div class="confirmation-text" v-if="!showError">
                Are you sure you want to delete this item?
            </div>
            <div class="confirmation-text" v-if="showError">
               {{ errorMsg }}
            </div>
            <div class="form-buttons"  v-if="!showError">
              <ButtonCustomized label="Cancel" @click="closeModal" secondary />
              <ButtonCustomized label="Delete" @click="onSave" />
            </div>
            <div class="form-buttons"  v-if="showError">
              <ButtonCustomized label="Ok" @click="close" secondary />
            </div>
          </div>
   
      </template>
    </Dialog>
  </template>
    
  <script lang="ts" setup>
  import Dialog from '../Dialog.vue';
  import ButtonCustomized from "../../form-fields/ButtonCustomized.vue";
  import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import { ref } from 'vue';

  const emit = defineEmits(['cancel', 'save', 'exit', 'close'])
   defineProps(['errorMsg','showError'])
   const deleteDialog = ref ()

  async function onSave() {
      emit('save');
  }
  function close (){
    emit('close');
    deleteDialog.value.closeModal();
  }
 
  </script>
    
  <style lang="scss" scoped>
  .button {
    cursor: pointer;
  }
  .delete-item-form {
      display: flex;
      flex-direction: column;
      max-width: 30rem;
    }
    .form-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 0.75rem;
      margin-top: 1rem;
    }
  </style>