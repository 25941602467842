<template>
<div class="tags-container">
  <TagsField :tags="slideTags" :width="'30'" @keyup.stop @keydown.stop />
</div>

</template>

<script lang="ts" setup>
import TagsField from '@/components/form-fields/TagsField/TagsField.vue';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import { ref } from 'vue';
const { basicInfo } = useSlide();
const slideTags = ref(basicInfo.tags);
</script>
 
<style lang="scss" scoped>
.tags-container {
  padding: 1em;
  height: 100%;
  border: 0;
}
</style>