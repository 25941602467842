<template>
  <TabsPane :tabs="tabs" @keydown.stop @keyup.stop class="bottom-tabs-pane"/>
</template>
  
<script lang="ts" setup>
import { markRaw } from 'vue';
import TabsPane from '@/views/MediaEditorView/SlideEditor/PropertiesPanesRight/TabsPane.vue';
import FormatPane from './FormatPane/FormatPane.vue';

const tabs = markRaw([
  {
    "label": "Format",
    "component": FormatPane,
  },
])
</script>

<style lang="scss" scoped>
.bottom-tabs-pane {
  height: auto;
}
</style>