import { ref, onMounted } from 'vue';

let resizeHandler = null;

export default function useScreenSize() {
  const width = ref(window.innerWidth);
  const height = ref(window.innerHeight);

  onMounted(() => {
    if (!resizeHandler) {
      resizeHandler = () => {
        width.value = window.innerWidth;
        height.value = window.innerHeight;
      };
      window.addEventListener('resize', resizeHandler);
    }
  });

  return { width, height };
};


// TODO: think about having something like …1 which are …2 — N295UR7l
  // …1: smallDevicesDown, xSmallDevices, mediumDevicesDown, smallDevices, largeDevicesDown, xLargeDevicesDown, … (similar to what in "styles\mixins\screen\_width-based.scss")
  // …2: booleans telling if the current screen size in the range of the name