<!-- 
  TODO: think about resolving redundancies if needed between this & @\components\SlideEditor\TopHigherHorizontalBar — N36G3WBi
-->

<template>
<div class="top-bars">
  <div class="top-higher-horizontal-bar justify-between flex-wrap">
    <div class="flex flex-row items-center gap-2">
      <div class="component-title">
        POS Source Editor
      </div>
      <ReloadButton 
        tooltipText="Reload POS Source"
        :isLoading="isLoading"
        :reloadHandler="reloadPOSSource"
      />
    </div>
    <div class="flex items-center mb-2">
      <!-- <SelectField
        label="Sharing"
        :options="sharingLevelOptions"
        v-model="data.sharingLevel"
      /> -->
    </div>
  </div>
  <PageSection>
    <div class="top-lower-horizontal-bar">
      <TextField label="Name" v-model="data.name" :required="true" />
      <!-- <TextField
        label="Name"
        name="name"
        v-model="values.name"
        required
        class="field"
      /> -->
      <TextField
        label="POS Source Url"
        name="posSourceUrl"
        v-model="data.posSourceUrl"
        required
        class="field"
      />
      <CheckBoxField
        label="Active"
        name="active"
        v-model="data.isActive"
        required
        class="field"
      />
    </div>
  </PageSection>
  </div>
</template>

<script lang="ts" setup>
import PageSection from '@/components/layouts/PageSection.vue';
import ReloadButton from '@/components/ReloadButton/ReloadButton.vue';
import TextField from '@/components/form-fields/TextField.vue';
import CheckBoxField from "@/components/form-fields/CheckboxField.vue";
// import NumberField from '@/components/form-fields/NumberField.vue';
import usePOSSource from '../composables/posSource';

const {
  data,
  reloadPOSSource,
  isLoading
} = usePOSSource();

</script>

<style lang="scss" scoped>
.component-title {
  color: #25294A;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.top-higher-horizontal-bar {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.top-lower-horizontal-bar {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
</style>